var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { attrs: { id: "coding" } },
    [
      _c(
        "v-alert",
        {
          staticClass: "alert-component white success--text",
          attrs: {
            value: _vm.displayAlert,
            type: "success",
            outline: "",
            transition: "fade-transition",
          },
        },
        [_vm._v(_vm._s(_vm.successMessage))]
      ),
      _c(
        "v-alert",
        {
          staticClass: "alert-component white error--text",
          attrs: {
            value: _vm.displayErrorAlert,
            type: "error",
            outline: "",
            transition: "fade-transition",
          },
        },
        [_vm._v(_vm._s(_vm.errorMessage))]
      ),
      _c(
        "v-toolbar",
        {
          staticClass: "coding-toolbar elevation-0 relative",
          attrs: {
            color: "white",
            app: "",
            dark: "",
            fixed: "",
            "clipped-left": "",
            "clipped-right": "",
          },
        },
        [
          _c("v-toolbar-side-icon", {
            staticClass: "white--text",
            on: {
              click: function ($event) {
                _vm.leftDrawer = !_vm.leftDrawer
              },
            },
          }),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "", color: "red darken-1" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _vm.stageArray.length > 0
                        ? _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: { color: "error", fab: "", small: "" },
                                on: { click: _vm.showCustomModal },
                              },
                              on
                            ),
                            [
                              _c("v-icon", { staticClass: "font-icon" }, [
                                _vm._v("add"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v("Add Input")])]
          ),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "", color: "red darken-1" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _c(
                        "span",
                        _vm._g({}, on),
                        [
                          _c("FontSelector", {
                            attrs: {
                              options: _vm.fontSizeValues,
                              selected: _vm.settings.fontSize,
                            },
                            on: { fontSelected: _vm.changeFontSelected },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v("Font size")])]
          ),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "", color: "red darken-1" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            attrs: {
                              color: "warning",
                              dark: "",
                              fab: "",
                              small: "",
                              disabled: _vm.actionsStack.length == 0,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.undoAction()
                              },
                            },
                          },
                          on
                        ),
                        [
                          _c("v-icon", { staticClass: "font-icon" }, [
                            _vm._v("settings_backup_restore"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v("Undo")])]
          ),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "", color: "red darken-1" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _vm.stageArray.length > 0
                        ? _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: {
                                  color: "teal",
                                  dark: "",
                                  fab: "",
                                  small: "",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.displayResetDialog = true
                                  },
                                },
                              },
                              on
                            ),
                            [
                              _c("v-icon", { staticClass: "font-icon" }, [
                                _vm._v("autorenew"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v("Reset document")])]
          ),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "", color: "red darken-1" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.stageArray.length > 0,
                                expression: "stageArray.length > 0",
                              },
                            ],
                            attrs: { color: "primary", fab: "", small: "" },
                            on: {
                              click: function ($event) {
                                return _vm.saveDocument()
                              },
                            },
                          },
                          on
                        ),
                        [
                          _c("v-icon", { staticClass: "font-icon" }, [
                            _vm._v("save"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v("Save document")])]
          ),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "", color: "red darken-1" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.stageArray.length > 0,
                                expression: "stageArray.length > 0",
                              },
                            ],
                            attrs: { color: "error", fab: "", small: "" },
                            on: {
                              click: function ($event) {
                                _vm.removeFormPdfDialog = true
                              },
                            },
                          },
                          on
                        ),
                        [
                          _c("v-icon", { staticClass: "font-icon" }, [
                            _vm._v("delete"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v("Delete forms")])]
          ),
          _vm.stageArray.length > 0
            ? _c(
                "div",
                { staticClass: "page-numeration" },
                [
                  _vm.selectedPropertyId && _vm.selectedPropertyName
                    ? _c("h2", [
                        _vm._v(
                          _vm._s(_vm.selectedPropertyId) +
                            " - " +
                            _vm._s(_vm.selectedPropertyName) +
                            " - " +
                            _vm._s(_vm.selectedType)
                        ),
                      ])
                    : _vm._e(),
                  _c("v-chip", { staticClass: "orange darken-3" }, [
                    _vm._v(
                      "Page " + _vm._s(_vm.page) + " / " + _vm._s(_vm.numPages)
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c("v-spacer"),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "", color: "red darken-1" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      !_vm.isLoading && _vm.stageArray.length > 0
                        ? _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: { color: "primary", fab: "", small: "" },
                                on: { click: _vm.openClosePdfWarning },
                              },
                              on
                            ),
                            [
                              _c("v-icon", { staticClass: "font-icon" }, [
                                _vm._v("close"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v("Close document")])]
          ),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "", color: "red darken-1" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.stageArray.length == 0,
                                expression: "stageArray.length == 0",
                              },
                            ],
                            attrs: {
                              color: "indigo lighten-1",
                              fab: "",
                              small: "",
                            },
                            on: {
                              click: function ($event) {
                                _vm.showSearchDocumentsModule = true
                              },
                            },
                          },
                          on
                        ),
                        [
                          _c("v-icon", { staticClass: "font-icon" }, [
                            _vm._v("search"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v("Pending documents")])]
          ),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "", color: "red darken-1" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.stageArray.length == 0,
                                expression: "stageArray.length == 0",
                              },
                            ],
                            attrs: { color: "primary", fab: "", small: "" },
                            on: {
                              click: function ($event) {
                                _vm.showStartModule = true
                              },
                            },
                          },
                          on
                        ),
                        [
                          _c("v-icon", { staticClass: "font-icon" }, [
                            _vm._v("backup"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v("Upload/Import document")])]
          ),
          _c("input", {
            ref: "file",
            staticStyle: { display: "none" },
            attrs: { type: "file" },
            on: { change: _vm.onFilePicked },
          }),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "", color: "red darken-1" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _vm.stageArray.length > 0
                        ? _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: { color: "success", fab: "", small: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.showDownloadDialog = true
                                  },
                                },
                              },
                              on
                            ),
                            [
                              _c("v-icon", { staticClass: "font-icon" }, [
                                _vm._v("download"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v("Download document")])]
          ),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "", color: "red darken-1" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _vm.stageArray.length > 0
                        ? _c(
                            "span",
                            _vm._g({}, on),
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "success",
                                    fab: "",
                                    small: "",
                                    disabled: _vm.lockSubmit,
                                  },
                                  on: { click: _vm.confirmSubmitDialog },
                                },
                                [
                                  _c("v-icon", { staticClass: "font-icon" }, [
                                    _vm._v("ios_share"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.lockSubmit
                      ? "Save document before submit"
                      : "Submit document"
                  )
                ),
              ]),
            ]
          ),
          _vm.currentUser
            ? _c("LogoutMenu", { attrs: { currentUser: _vm.currentUser } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "coding-left-drawer white",
          attrs: { fixed: "", clipped: "", app: "" },
          model: {
            value: _vm.leftDrawer,
            callback: function ($$v) {
              _vm.leftDrawer = $$v
            },
            expression: "leftDrawer",
          },
        },
        [
          _c(
            "v-tabs",
            {
              model: {
                value: _vm.menuTabs,
                callback: function ($$v) {
                  _vm.menuTabs = $$v
                },
                expression: "menuTabs",
              },
            },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "", color: "red darken-1" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-tab",
                            _vm._g(
                              _vm._b(
                                { attrs: { href: "#menu-fields" } },
                                "v-tab",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("pan_tool")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Fields")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "", color: "red darken-1" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-tab",
                            _vm._g(
                              _vm._b(
                                { attrs: { href: "#menu-preview" } },
                                "v-tab",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("grid_view")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Thumbnails")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "", color: "red darken-1" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-tab",
                            _vm._g(
                              _vm._b(
                                { attrs: { href: "#menu-layers" } },
                                "v-tab",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("layers")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Layers")])]
              ),
              _vm.stageArray.length > 0
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "", color: "red darken-1" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-tab",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { href: "#menu-upload" } },
                                      "v-tab",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("file_upload")])],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1161492766
                      ),
                    },
                    [_c("span", [_vm._v("Upload")])]
                  )
                : _vm._e(),
              _vm.stageArray.length > 0
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "", color: "red darken-1" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-tab",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { href: "#menu-clipboard" } },
                                      "v-tab",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", { staticClass: "rotate-90" }, [
                                      _vm._v("attachment"),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3779494697
                      ),
                    },
                    [_c("span", [_vm._v("Clipboard")])]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.menuTabs == "menu-fields",
                  expression: "menuTabs == 'menu-fields'",
                },
              ],
            },
            [
              _vm.stageArray.length == 0
                ? _c(
                    "v-flex",
                    { attrs: { "text-xs-center": "", "pa-4": "" } },
                    [_vm._v("Document not loaded yet.")]
                  )
                : _vm._e(),
              _vm.stageArray.length > 0
                ? _c(
                    "v-flex",
                    {
                      staticClass: "grey lighten-2",
                      attrs: { x12: "", "pa-2": "" },
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Search for input fields",
                          flat: "",
                          solo: "",
                          "hide-details": "",
                          clearable: "",
                          "clear-icon": "mdi-close-circle-outline",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.releaseSelected()
                          },
                        },
                        model: {
                          value: _vm.searchProperty,
                          callback: function ($$v) {
                            _vm.searchProperty = $$v
                          },
                          expression: "searchProperty",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.stageArray.length > 0
                ? _c(
                    "v-list",
                    [
                      _vm._l(
                        _vm.filterStandardProperties,
                        function (item, index) {
                          return _c(
                            "v-list-group",
                            {
                              key: "sp" + index,
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function () {
                                      return [
                                        _c(
                                          "v-list-tile",
                                          [
                                            _c("v-list-tile-title", [
                                              _vm._v(_vm._s(item.name)),
                                            ]),
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass:
                                                  "grey--text text--lighten-1 rotate-y-180",
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.addFullCategoryToClipboard(
                                                      item.children
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("reply_all")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            _vm._l(item.children, function (child, i) {
                              return _c(
                                "v-list-tile",
                                {
                                  key: "c" + i,
                                  staticClass: "grey lighten-3 grabbable",
                                  class: [
                                    "px-1",
                                    _vm.selectedProperty != null &&
                                    _vm.selectedProperty.name == item.name
                                      ? "selected"
                                      : "",
                                  ],
                                  attrs: { draggable: "true" },
                                  on: {
                                    drag: function ($event) {
                                      return _vm.drag(child, $event)
                                    },
                                    dragstart: function ($event) {
                                      return _vm.dragstart(child, $event)
                                    },
                                    dragend: function ($event) {
                                      return _vm.dragend(child, $event)
                                    },
                                    dragenter: function ($event) {
                                      return _vm.dragenter(child, $event)
                                    },
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.addToClipboardStr(child)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-list-tile-action",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "grey--text text--lighten-1",
                                        },
                                        [_vm._v("drag_indicator")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "v-list-tile-title",
                                                  _vm._g({}, on),
                                                  [_vm._v(_vm._s(child.name))]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(child.comments)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          )
                        }
                      ),
                      _vm._l(
                        _vm.filterMultiTabProperties,
                        function (item, index) {
                          return _c(
                            "v-list-group",
                            {
                              key: "mtp" + index,
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function () {
                                      return [
                                        _c(
                                          "v-list-tile",
                                          { attrs: { draggable: "true" } },
                                          [
                                            _c("v-list-tile-title", [
                                              _vm._v(_vm._s(item.name)),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "v-expansion-panel",
                                _vm._l(item.options, function (tabEl, idx) {
                                  return _c(
                                    "v-expansion-panel-content",
                                    {
                                      key: idx,
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "header",
                                            fn: function () {
                                              return [
                                                _c("div", [
                                                  _vm._v(_vm._s(idx)),
                                                ]),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass:
                                                      "grey--text text--lighten-1 rotate-y-180",
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.addFullCategoryToClipboard(
                                                          tabEl
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("reply_all")]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c(
                                        "div",
                                        _vm._l(
                                          tabEl,
                                          function (subchild, iiee) {
                                            return _c(
                                              "v-list-tile",
                                              {
                                                key: "sc" + iiee,
                                                staticClass:
                                                  "grey lighten-3 grabbable",
                                                class: [
                                                  "px-1",
                                                  _vm.selectedProperty !=
                                                    null &&
                                                  _vm.selectedProperty.name ==
                                                    item.name
                                                    ? "selected"
                                                    : "",
                                                ],
                                                attrs: { draggable: "true" },
                                                on: {
                                                  drag: function ($event) {
                                                    return _vm.drag(
                                                      subchild,
                                                      $event
                                                    )
                                                  },
                                                  dragstart: function ($event) {
                                                    return _vm.dragstart(
                                                      subchild,
                                                      $event
                                                    )
                                                  },
                                                  dragend: function ($event) {
                                                    return _vm.dragend(
                                                      subchild,
                                                      $event
                                                    )
                                                  },
                                                  dragenter: function ($event) {
                                                    return _vm.dragenter(
                                                      subchild,
                                                      $event
                                                    )
                                                  },
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.addToClipboardStr(
                                                      subchild
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-list-tile-action",
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "grey--text text--lighten-1",
                                                      },
                                                      [_vm._v("drag_indicator")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "v-list-tile-title",
                                                                _vm._g({}, on),
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      subchild.name
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          subchild.comments
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.menuTabs == "menu-preview",
                  expression: "menuTabs == 'menu-preview'",
                },
              ],
            },
            [
              _vm.stageArray.length == 0
                ? _c(
                    "v-flex",
                    { attrs: { "text-xs-center": "", "pa-4": "" } },
                    [
                      _vm._v(
                        "\n              Document not loaded yet.\n          "
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "preview-bg" },
                [
                  _c("PreviewMenu", {
                    key: _vm.documentKey,
                    ref: "leftmenucmp",
                    attrs: {
                      selectedPage: _vm.page,
                      sortableKeys: _vm.leftMenuKeys,
                      pagesMap: _vm.orderPagesArray,
                    },
                    on: {
                      pageClicked: _vm.goToPageNumber,
                      pageNumberUpdated: _vm.orderPages,
                      pageDeleted: _vm.removeSelectedPage,
                      pageSelected: _vm.addMarkedPage,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.menuTabs == "menu-layers",
                  expression: "menuTabs == 'menu-layers'",
                },
              ],
            },
            [
              !_vm.inputsPerPage.filter((x) => x.children.length > 0).length
                ? _c(
                    "v-flex",
                    { attrs: { "text-xs-center": "", "pa-4": "" } },
                    [_vm._v("You have no input in the document.")]
                  )
                : _vm._e(),
              _vm.inputsPerPage.filter((x) => x.children.length > 0).length
                ? _c("v-treeview", {
                    staticClass: "mb-2",
                    attrs: {
                      "open-on-click": "",
                      "item-key": "id",
                      "item-text": "name",
                      items: _vm.inputsPerPage,
                      transition: "",
                      flat: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "prepend",
                          fn: function ({ item }) {
                            return [
                              _c(
                                "div",
                                {
                                  class: [
                                    _vm.selectedIndex >= 0 &&
                                    _vm.layer.children[
                                      _vm.selectedIndex
                                    ].getAttrs().identifier == item.identifier
                                      ? "selected"
                                      : "",
                                  ],
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        color: _vm.getHistoryInputsColor(item),
                                      },
                                    },
                                    [_vm._v("fiber_manual_record")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "label",
                          fn: function ({ item }) {
                            return [
                              _c(
                                "div",
                                {
                                  class: [
                                    _vm.selectedIndex >= 0 &&
                                    _vm.layer.children[
                                      _vm.selectedIndex
                                    ].getAttrs().identifier == item.identifier
                                      ? "selected orange--text darken-2"
                                      : "",
                                  ],
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotoInput(item)
                                    },
                                    dblclick: function ($event) {
                                      return _vm.showCustomModal()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item.name))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3428123681
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.menuTabs == "menu-upload",
                  expression: "menuTabs == 'menu-upload'",
                },
              ],
            },
            [
              _c(
                "v-flex",
                { staticClass: "upload-tab" },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "upload-left-menu dropzone",
                      on: {
                        dragover: _vm.docdragover,
                        dragleave: _vm.docdragleave,
                        drop: _vm.docdrop,
                      },
                    },
                    [
                      _c("div", { staticClass: "dropzone-info" }, [
                        _c("span", [_vm._v("Drag and Drop to upload files")]),
                      ]),
                      _c("input", {
                        ref: "file",
                        staticClass: "d-none",
                        attrs: {
                          type: "file",
                          multiple: "",
                          name: "fields[assetsFieldHandle][]",
                          id: "assetsFieldHandle",
                          accept: ".pdf",
                          disabled: _vm.isLoading,
                        },
                      }),
                    ]
                  ),
                  _c("v-flex", { staticClass: "uploaded-section" }, [
                    _vm.uploadedFileList.length > 0
                      ? _c(
                          "div",
                          { staticClass: "uploaded-file-list" },
                          [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("Uploaded Files:"),
                            ]),
                            _c(
                              "v-list",
                              _vm._l(_vm.uploadedFileList, function (item) {
                                return _c(
                                  "v-list-tile",
                                  { key: item.name },
                                  [
                                    _c(
                                      "v-list-tile-content",
                                      [
                                        _c("v-list-tile-title", {
                                          domProps: {
                                            innerHTML: _vm._s(item.name),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-tile-action",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: item.status == "pending",
                                                expression:
                                                  "item.status == 'pending'",
                                              },
                                            ],
                                            staticClass: "warning--text",
                                          },
                                          [_vm._v("schedule")]
                                        ),
                                        _c("v-progress-circular", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                item.status == "processing",
                                              expression:
                                                "item.status == 'processing'",
                                            },
                                          ],
                                          attrs: {
                                            size: 25,
                                            width: 3,
                                            color: "blue accent-4",
                                            indeterminate: "",
                                          },
                                        }),
                                        _c(
                                          "v-icon",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  item.status == "completed",
                                                expression:
                                                  "item.status == 'completed'",
                                              },
                                            ],
                                            staticClass: "success--text",
                                          },
                                          [_vm._v("done")]
                                        ),
                                        _c(
                                          "v-icon",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: item.status == "error",
                                                expression:
                                                  "item.status == 'error'",
                                              },
                                            ],
                                            staticClass: "red--text",
                                          },
                                          [_vm._v("close")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.menuTabs == "menu-clipboard",
                  expression: "menuTabs == 'menu-clipboard'",
                },
              ],
            },
            [
              _c(
                "v-flex",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.clipboard.length == 0,
                      expression: "clipboard.length == 0",
                    },
                  ],
                  attrs: { "text-xs-center": "", "pa-4": "" },
                },
                [_vm._v("There are no elements to be added.\n          ")]
              ),
              _c(
                "v-flex",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.clipboard.length > 0,
                      expression: "clipboard.length > 0",
                    },
                  ],
                },
                _vm._l(_vm.clipboard, function (subchild, iddex) {
                  return _c(
                    "v-list-tile",
                    {
                      key: "sclip" + iddex,
                      staticClass: "grey lighten-3 grabbable",
                      class: [
                        "px-1",
                        _vm.selectedProperty != null &&
                        _vm.selectedProperty.name == subchild.name
                          ? "selected"
                          : "",
                      ],
                      attrs: { draggable: "true" },
                      on: {
                        drag: function ($event) {
                          return _vm.drag(subchild, $event)
                        },
                        dragstart: function ($event) {
                          return _vm.dragstart(subchild, $event)
                        },
                        dragend: function ($event) {
                          return _vm.dragend(subchild, $event, iddex)
                        },
                        dragenter: function ($event) {
                          return _vm.dragenter(subchild, $event)
                        },
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.drag()
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-tile-action",
                        [
                          _c(
                            "v-icon",
                            { staticClass: "grey--text text--lighten-1" },
                            [_vm._v("drag_indicator")]
                          ),
                        ],
                        1
                      ),
                      _c("v-list-tile-title", [_vm._v(_vm._s(subchild.name))]),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "coding-right-drawer",
          attrs: { right: "", clipped: "", app: "", fixed: "" },
          model: {
            value: _vm.rightDrawer,
            callback: function ($$v) {
              _vm.rightDrawer = $$v
            },
            expression: "rightDrawer",
          },
        },
        [
          _c(
            "v-toolbar",
            { staticClass: "elevation-0", attrs: { color: "white" } },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { flat: "", color: "grey", icon: "" },
                  on: {
                    click: function ($event) {
                      _vm.rightDrawer = !_vm.rightDrawer
                    },
                  },
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
            ],
            1
          ),
          _vm.selectedIndex >= 0 && _vm.multiselect.length == 0
            ? _c("ElementEditor", {
                attrs: {
                  selectedElement: _vm.layer.children[_vm.selectedIndex],
                  selectedIdentifier: _vm.selectedIdentifier,
                  colorPropertySelected: _vm.colorPropertySelected,
                  fontSizeValues: _vm.fontSizeValues,
                  textFieldTypes: _vm.textFieldTypes,
                },
                on: {
                  showCustomModal: _vm.showCustomModal,
                  onAttributeChanged: _vm.onAttributeChanged,
                  previousInput: _vm.previousInput,
                  nextInput: _vm.nextInput,
                  deleteInput: _vm.deleteInput,
                  duplicateInput: _vm.duplicateInput,
                  displayTransferDialog: _vm.displayTransferDialog,
                },
              })
            : _vm._e(),
          _vm.selectedIndex >= 0 && _vm.multiselect.length > 0
            ? _c("MultiElementEditor", {
                attrs: {
                  layer: _vm.layer,
                  selectedElementsArray: _vm.multiselect,
                  fontSizeValues: _vm.fontSizeValues,
                  textFieldTypes: _vm.textFieldTypes,
                  forbiddenTypes: _vm.forbiddenTypes,
                },
                on: {
                  onAttributeChanged: _vm.changeAttibuteMultipleObjects,
                  deleteInput: _vm.deleteInput,
                  duplicateInput: _vm.duplicateInput,
                  displayTransferDialog: _vm.displayTransferDialog,
                  positionAction: _vm.positionAction,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-content",
        { staticClass: "coding-wrap", attrs: { app: "" } },
        [
          _vm.isLoading
            ? _c(
                "v-layout",
                {
                  staticStyle: { height: "100%" },
                  attrs: {
                    column: "",
                    "justify-center": "",
                    "align-center": "",
                  },
                },
                [
                  _c("v-progress-circular", {
                    attrs: {
                      size: 70,
                      width: 7,
                      color: "primary",
                      indeterminate: "",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isLoading,
                  expression: "!isLoading",
                },
              ],
              staticClass: "main-container",
              style: {
                height: _vm.fullHeight + "px",
                display: _vm.isLoading ? "none" : "block",
              },
            },
            [
              _c("div", { attrs: { id: "pdf-container" } }),
              _c("div", {
                attrs: { id: "fields-container" },
                on: {
                  dragover: function ($event) {
                    return _vm.dragover($event)
                  },
                  dragleave: function ($event) {
                    return _vm.dragleave($event)
                  },
                  contextmenu: function ($event) {
                    $event.preventDefault()
                    return _vm.listenclick.apply(null, arguments)
                  },
                },
              }),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "800" },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "tenant" } },
                [
                  _c("v-toolbar-title"),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function ($event) {
                          _vm.showDialog = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "pa-5" },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: "Input name", required: "" },
                            model: {
                              value: _vm.custom.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.custom, "name", $$v)
                              },
                              expression: "custom.name",
                            },
                          }),
                          _c("v-select", {
                            attrs: {
                              items: _vm.custom.inputTypes,
                              label: "Input Type",
                              required: "",
                            },
                            model: {
                              value: _vm.custom.selectedInputType,
                              callback: function ($$v) {
                                _vm.$set(_vm.custom, "selectedInputType", $$v)
                              },
                              expression: "custom.selectedInputType",
                            },
                          }),
                          _vm.custom.selectedInputType == "Radio" &&
                          !_vm.selectedIdentifier
                            ? _c("v-text-field", {
                                attrs: {
                                  label: "Number of options",
                                  mask: "#",
                                  required: "",
                                },
                                model: {
                                  value: _vm.custom.optionNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.custom, "optionNumber", $$v)
                                  },
                                  expression: "custom.optionNumber",
                                },
                              })
                            : _vm._e(),
                          _vm.custom.selectedInputType == "Radio"
                            ? _c("v-text-field", {
                                attrs: { label: "Value", required: "" },
                                model: {
                                  value: _vm.custom.value,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.custom, "value", $$v)
                                  },
                                  expression: "custom.value",
                                },
                              })
                            : _vm._e(),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                disabled: _vm.checkIfDoneIsDisabled(),
                              },
                              on: { click: _vm.drawCustomInput },
                            },
                            [_vm._v("\n                DONE\n            ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", flat: "" },
                      on: {
                        click: function ($event) {
                          _vm.showDialog = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "450" },
          model: {
            value: _vm.closePdfDialog,
            callback: function ($$v) {
              _vm.closePdfDialog = $$v
            },
            expression: "closePdfDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", { staticClass: "pa-5" }, [
                _c("h2", { staticClass: "text-xs-center" }, [
                  _vm._v("Are you sure?"),
                ]),
                _c("h4", { staticClass: "text-xs-center" }, [
                  _vm._v("Do you want to close?"),
                ]),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success", flat: "" },
                      on: {
                        click: function ($event) {
                          _vm.closePdfDialog = false
                        },
                      },
                    },
                    [_vm._v("No")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", flat: "" },
                      on: { click: _vm.closeSavedPdf },
                    },
                    [_vm._v("Yes")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "450" },
          model: {
            value: _vm.removeFormPdfDialog,
            callback: function ($$v) {
              _vm.removeFormPdfDialog = $$v
            },
            expression: "removeFormPdfDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", { staticClass: "pa-5" }, [
                _c("h2", { staticClass: "text-xs-center" }, [
                  _vm._v("Are you sure?"),
                ]),
                _c("h4", { staticClass: "text-xs-center" }, [
                  _vm._v("Do you want to remove all forms page?"),
                ]),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success", flat: "" },
                      on: {
                        click: function ($event) {
                          _vm.removeFormPdfDialog = false
                        },
                      },
                    },
                    [_vm._v("No")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", flat: "" },
                      on: { click: _vm.deleteForms },
                    },
                    [_vm._v("Yes")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "450" },
          model: {
            value: _vm.uploadPrevPdfDialog,
            callback: function ($$v) {
              _vm.uploadPrevPdfDialog = $$v
            },
            expression: "uploadPrevPdfDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", { staticClass: "pa-5" }, [
                _c("h2", { staticClass: "text-xs-center" }, [
                  _vm._v("We found previous PDF coding was not closed"),
                ]),
                _c("h3", { staticClass: "text-xs-center" }, [
                  _vm._v("Do you want to continue coding?"),
                ]),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", flat: "" },
                      on: { click: _vm.closeSavedPdf },
                    },
                    [_vm._v("No")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success", flat: "" },
                      on: { click: _vm.uploadPrevPdf },
                    },
                    [_vm._v("Yes")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "450" },
          model: {
            value: _vm.transferLabelsDialog,
            callback: function ($$v) {
              _vm.transferLabelsDialog = $$v
            },
            expression: "transferLabelsDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-form",
                {
                  model: {
                    value: _vm.isValidTransferLabelForm,
                    callback: function ($$v) {
                      _vm.isValidTransferLabelForm = $$v
                    },
                    expression: "isValidTransferLabelForm",
                  },
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pa-5" },
                    [
                      _c("h3", { staticClass: "text-xs-center" }, [
                        _vm._v(
                          "Do you want to move the selected elements to another page?"
                        ),
                      ]),
                      _c(
                        "v-layout",
                        {
                          staticClass: "justify-space-around",
                          attrs: { "mt-5": "", row: "", wrap: "" },
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs4: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Move from:",
                                  value: _vm.transferLabel.from,
                                  disabled: "",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.transferLabelsDialog
                            ? _c(
                                "v-flex",
                                { attrs: { xs4: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Move to:",
                                      rules: [
                                        _vm.transferRules.required,
                                        _vm.transferRules.gt_zero,
                                        _vm.transferRules.valid_page,
                                        _vm.transferRules.different_dest,
                                        _vm.transferRules.valid,
                                      ],
                                      mask: "###",
                                    },
                                    model: {
                                      value: _vm.transferLabel.to,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.transferLabel, "to", $$v)
                                      },
                                      expression: "transferLabel.to",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", flat: "" },
                      on: {
                        click: function ($event) {
                          _vm.transferLabelsDialog = false
                        },
                      },
                    },
                    [_vm._v("No")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "success",
                        flat: "",
                        disabled: !_vm.isValidTransferLabelForm,
                      },
                      on: { click: _vm.confirmTransfer },
                    },
                    [_vm._v("Yes")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("ResetDialog", {
        attrs: { displayDialog: _vm.displayResetDialog },
        on: {
          closeDialog: function ($event) {
            _vm.displayResetDialog = !_vm.displayResetDialog
          },
          resetDocument: _vm.resetDocument,
        },
      }),
      _c("StartModule", {
        key: _vm.startDialogKey,
        attrs: {
          displayModule: _vm.showStartModule,
          stopLoading: _vm.stopLoadingDialog,
          apiBaseUrl: _vm.apiBaseUrl,
        },
        on: {
          closeDialog: function ($event) {
            return _vm.closeStartDialog()
          },
          uploadFiles: _vm.uploadFiles,
          importFile: _vm.importFileFromProperty,
        },
      }),
      _c("DownloadFile", {
        attrs: {
          displayModule: _vm.showDownloadDialog,
          stopLoading: _vm.stopLoadingDialog,
        },
        on: {
          closeDialog: function ($event) {
            _vm.showDownloadDialog = !_vm.showDownloadDialog
          },
          confirmDownload: _vm.downloadWorkingDocument,
        },
      }),
      _c("InfoMessage", {
        attrs: {
          displayModule: _vm.showInfoMessage,
          customMsg: _vm.customMessage,
        },
        on: {
          closeDialog: function ($event) {
            _vm.showInfoMessage = !_vm.showInfoMessage
          },
        },
      }),
      _c("SubmitModule", {
        attrs: {
          displayModule: _vm.showSubmitDialog,
          apiBaseUrl: _vm.apiBaseUrl,
        },
        on: {
          closeDialog: function ($event) {
            _vm.showSubmitDialog = !_vm.showSubmitDialog
          },
          submitDocument: _vm.askConfirmation,
          confirmSubmit: _vm.confirmSubmitApp,
        },
      }),
      _c("ConfirmAction", {
        attrs: {
          displayModule: _vm.showConfirmDialog,
          customMsg: _vm.customMessage,
        },
        on: {
          confirmAction: _vm.compleSubmitDocument,
          closeDialog: function ($event) {
            _vm.showConfirmDialog = !_vm.showConfirmDialog
          },
        },
      }),
      _c("ConfirmAction", {
        attrs: {
          displayModule: _vm.showConfirmSubmitDialog,
          customMsg: _vm.customMessage,
        },
        on: {
          confirmAction: _vm.compleSubmitDocument,
          closeDialog: function ($event) {
            _vm.showConfirmSubmitDialog = !_vm.showConfirmSubmitDialog
          },
        },
      }),
      _c("SearchDocument", {
        attrs: {
          displayModule: _vm.showSearchDocumentsModule,
          apiBaseUrl: _vm.apiBaseUrl,
        },
        on: {
          itemSelected: _vm.getPendingDocumentDetails,
          closeDialog: function ($event) {
            _vm.showSearchDocumentsModule = !_vm.showSearchDocumentsModule
          },
        },
      }),
      _c(
        "div",
        {
          class: _vm.draggingItem.custom ? "dragable-custom" : "",
          attrs: { id: "cloneNode" },
        },
        [_vm._v(_vm._s(_vm.draggingItem.name))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }