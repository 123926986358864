var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "preview-slide",
      class: { "d-none": _vm.deleted },
      on: {
        mouseover: function ($event) {
          _vm.hover = true
        },
        mouseleave: function ($event) {
          _vm.hover = false
        },
      },
    },
    [
      _c(
        "v-icon",
        {
          staticClass: "close-icon",
          class: { hover: _vm.hover },
          on: { click: _vm.deleteSlide },
        },
        [_vm._v("close")]
      ),
      _c("img", {
        key: _vm.idx,
        class: {
          selected: _vm.pageNumber == _vm.selectedPage,
          landscape: _vm.landscapePage,
        },
        attrs: { src: _vm.content },
        on: {
          click: function ($event) {
            return _vm.pageClicked($event)
          },
        },
      }),
      _c("div", [_c("span", [_vm._v(_vm._s(_vm.pageNumber))])]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }