<template>
    <v-dialog persistent v-model="displayDialog" width="450">
        <v-card>
            <v-card-text class="pa-5">
                <h2 class="text-xs-center">Are you sure?</h2>
                <h4 class="text-xs-center">Do you want to reset the document?</h4>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" flat @click="closeDialog()">No</v-btn>
                <v-btn color="success" flat @click="confirmAction()">Yes</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    export default {
        name: 'ResetDialog',
        props: {
            displayDialog: {
                type: Boolean,
                default: false
            },
        },
        methods: {
            closeDialog() {
                this.$emit('closeDialog');
            },
            confirmAction(){
                this.$emit('resetDocument');
            },
        },
    }

</script>