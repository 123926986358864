var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "right-side-scroll" },
    [
      _c(
        "v-card",
        { staticClass: "my-2" },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c("v-flex", { attrs: { xs3: "" } }, [
                    _c("div", {
                      staticClass: "property-color",
                      style: { backgroundColor: _vm.labelAttributes.fill },
                    }),
                  ]),
                  _c("v-flex", { attrs: { xs9: "" } }, [
                    _c("div", [
                      _vm.elemAttributes.id.trim().length > 0
                        ? _c("span", [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.elemAttributes.id) +
                                "\n                        "
                            ),
                          ])
                        : _c("span", [_vm._v("No field selected")]),
                    ]),
                    _c("div", [
                      _c("span", [_vm._v(_vm._s(_vm.elemAttributes.type))]),
                    ]),
                    _vm.elemAttributes.type == "Radio"
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              "Value: " + _vm._s(_vm.elemAttributes.content)
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { row: "", "pt-1": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-checkbox", {
                        attrs: { color: "primary", label: "Required" },
                        on: { change: _vm.onRequiredChanged },
                        model: {
                          value: _vm.elemAttributes.required,
                          callback: function ($$v) {
                            _vm.$set(_vm.elemAttributes, "required", $$v)
                          },
                          expression: "elemAttributes.required",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-checkbox", {
                        attrs: { color: "primary", label: "Read-only" },
                        on: { change: _vm.onReadOnlyChanged },
                        model: {
                          value: _vm.elemAttributes.readOnly,
                          callback: function ($$v) {
                            _vm.$set(_vm.elemAttributes, "readOnly", $$v)
                          },
                          expression: "elemAttributes.readOnly",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.fontSizeValues,
                          label: "Font size",
                        },
                        on: { change: _vm.onFontSizeChanged },
                        model: {
                          value: _vm.elemAttributes.fontSize,
                          callback: function ($$v) {
                            _vm.$set(_vm.elemAttributes, "fontSize", $$v)
                          },
                          expression: "elemAttributes.fontSize",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              ["Text", "type"].indexOf(_vm.elemAttributes.type) != -1
                ? _c(
                    "v-layout",
                    { attrs: { row: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.textFieldTypes,
                              label: "Text Type",
                            },
                            on: { change: _vm.onTextTypeChanged },
                            model: {
                              value: _vm.elemAttributes.textType,
                              callback: function ($$v) {
                                _vm.$set(_vm.elemAttributes, "textType", $$v)
                              },
                              expression: "elemAttributes.textType",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-layout",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        ["Text", "type"].indexOf(_vm.elemAttributes.type) != -1,
                      expression:
                        "['Text', 'type'].indexOf(elemAttributes.type) != -1",
                    },
                  ],
                  attrs: { row: "" },
                },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          id: "field-max-lenght",
                          label: "Max Length",
                          mask: "###",
                        },
                        on: { change: _vm.onMaxLengthChanged },
                        model: {
                          value: _vm.elemAttributes.maxLength,
                          callback: function ($$v) {
                            _vm.$set(_vm.elemAttributes, "maxLength", $$v)
                          },
                          expression: "elemAttributes.maxLength",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { row: "", "pt-1": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { x12: "" } },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            row: "",
                            "justify-space-between": "",
                            "pt-1": "",
                          },
                        },
                        [
                          _c("v-icon", { on: { click: _vm.previousInput } }, [
                            _vm._v("keyboard_arrow_left"),
                          ]),
                          _c("v-icon", { on: { click: _vm.nextInput } }, [
                            _vm._v("keyboard_arrow_right"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "v-layout",
                { attrs: { row: "", "justify-space-around": "" } },
                [
                  _vm.labelAttributes.fill != _vm.colorPropertySelected
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "blue-grey lighten-2 fab-action-button",
                          attrs: { dark: "", fab: "" },
                          on: { click: _vm.showCustomModal },
                        },
                        [
                          _c("v-icon", { staticClass: "font-icon" }, [
                            _vm._v("settings"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      staticClass: "orange lighten-1 fab-action-button",
                      attrs: { dark: "", fab: "" },
                      on: { click: _vm.deleteInput },
                    },
                    [
                      _c("v-icon", { staticClass: "font-icon" }, [
                        _vm._v("delete_outline"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "teal lighten-1 fab-action-button",
                      attrs: { dark: "", fab: "" },
                      on: { click: _vm.displayTransferDialog },
                    },
                    [
                      _c("v-icon", { staticClass: "font-icon" }, [
                        _vm._v("low_priority"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text fab-action-button",
                      attrs: {
                        color: "primary",
                        outline: "",
                        fab: "",
                        depressed: "",
                      },
                      on: { click: _vm.duplicateInput },
                    },
                    [
                      _c("v-icon", { staticClass: "font-icon" }, [
                        _vm._v("content_copy"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }