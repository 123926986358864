const mixin = {
  data() {
    return {
      hasChanges: false,
    }
  },
  methods: {
    _routeLeave(event) {
      if (!this.hasChanges) {
        return true
      }

      if (event) {
        return (event.returnValue = `Are you sure you want to leave?`)
      } else {
        const answer = this.$dialog.confirm({
          text: 'Are you sure you want to leave?',
          title: 'You have unsaved changes',
          actions: {
            false: 'Cancel',
            true: {
              text: 'Leave',
              color: 'primary',
            },
          },
        })

        return answer
      }
    },
  },
  created() {
    window.addEventListener('beforeunload', this._routeLeave)
  },
  destroyed() {
    window.removeEventListener('beforeunload', this._routeLeave)
  },
  async beforeRouteLeave(to, from, next) {
    const answer = await this._routeLeave()
    if (answer) {
      next()
    } else {
      next(false)
    }
  },
}

export default mixin
