<template>
    <div>
        <v-dialog persistent v-model="displayModule" max-width="550">
            <v-card class="pa-3 relative">
                <div class="close-icon pr-3">
                    <v-icon class="pointer" @click="closeDialog()">close</v-icon>
                </div>
                <v-card-title class="headline pt-5 pb-0 flex-column">
                    <div class="d-flex justify-center full-width">
                        <img src="../assets/building.svg" class="property-icon" />
                    </div>
                    <div v-if="step == 1" class="d-flex flex-column full-width">
                        <div class="d-flex justify-center full-width my-3">
                            <span class="header-title">Select a property to submit the saved document</span>
                        </div>
                        <SearchProperty :apiBaseUrl="apiBaseUrl" @itemSelected="elementSelected"></SearchProperty>
                    </div>
                    <div v-if="step == 2">
                        <div class="d-flex justify-center full-width px-5 my-3 relative">
                            <v-icon @click="goBack()" class="float-left-abs">undo</v-icon>
                            <span class="header-title font-weight-medium">{{propertySelected.name}}</span>
                        </div>
                        <div class="d-flex justify-center full-width px-5">
                            <span class="import-instructions-text">Please choose one or more application types for this document to apply.</span>
                        </div>
                        <div class="d-flex full-width px-3 my-3">
                            <v-checkbox
                                color="primary"
                                v-model="leaseMarked"
                                label="Lease"
                            />
                            <v-checkbox
                                color="primary"
                                v-model="purchaseMarked"
                                label="Purchase"
                            />
                            <v-checkbox
                                color="primary"
                                v-model="guestMarked"
                                label="Guest"
                            />
                        </div>
                    </div>
                </v-card-title>
                <v-card-actions class="mt-3 pb-3 pr-3 pt-0 justify-center">
                    <v-btn v-if="step==2" depressed class="full-width" color="primary darken-1" dark :disabled="isDisabled()" @click="confirmSubmit()">Submit</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

    import SearchProperty from "./SearchProperty";

    export default {
        name: 'SubmitModule',
        data() {
            return {
                leaseMarked: false,
                purchaseMarked: false,
                guestMarked: false,
                step: null,
                propertySelected: null,
            }
        },
        props: {
            displayModule: {
                type: Boolean,
                default: false
            },
            propertyName: {
                type: String,
                default: 'Icon Brickell'
            },
            apiBaseUrl: {
                required: true
            }
        },
        components: {
            SearchProperty
        },
        watch: {
            displayModule: function(){
                const self = this;
                if(self.displayModule){
                    self.step = 1;
                } else {
                    setTimeout(() => {
                        self.clearData();
                    }, 1000);

                }
            }
        },
        methods: {
            closeDialog() {
                this.$emit('closeDialog');
            },
            isDisabled(){
                return !this.leaseMarked && !this.purchaseMarked && !this.guestMarked;
            },
            confirmSubmit() {
                const options = this.getOptionsSelected();
                const documentData = {
                    idProperty: this.propertySelected.id,
                    type: options
                };
                this.$emit('submitDocument', documentData);
            },
            clearData() {
                this.leaseMarked = false;
                this.purchaseMarked = false;
                this.guestMarked = false;
                this.step = null;
                this.propertySelected = null;
            },
            getOptionsSelected() {
                let types = [];
                if(this.leaseMarked){
                    types.push('lease');
                }
                if(this.purchaseMarked){
                    types.push('purchase');
                }
                if(this.guestMarked){
                    types.push('guest');
                }
                const options = types.join(',');
                return options;
            },
            elementSelected(item){
                this.propertySelected = item;
                this.step = 2;
            },
            goBack() {
                this.clearData();
                this.step = 1;
            }
        }
    }
</script>

<style>

</style>