var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: { "offset-y": "", "offset-x": "", "full-width": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g({ attrs: { icon: "" } }, on),
                [_c("v-icon", [_vm._v("more_vert")])],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-list",
        { staticClass: "pa-0" },
        [
          _c(
            "v-list-tile",
            { attrs: { avatar: "", to: "/account" } },
            [
              _c(
                "v-list-tile-avatar",
                [
                  _c("Jazzicon", {
                    attrs: { address: _vm.currentUser.email, size: 40 },
                  }),
                ],
                1
              ),
              _c(
                "v-list-tile-content",
                [
                  _c("v-list-tile-title", [
                    _vm._v(_vm._s(_vm.currentUser.fullname)),
                  ]),
                  _c("v-list-tile-sub-title", [
                    _vm._v(_vm._s(_vm.currentUser.email)),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-list-tile",
            { attrs: { to: "/account" } },
            [_c("v-list-tile-title", [_vm._v("Your Account")])],
            1
          ),
          _c(
            "v-list-tile",
            { attrs: { to: "/logout" } },
            [_c("v-list-tile-title", [_vm._v("Logout")])],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }