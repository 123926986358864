var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "750" },
          model: {
            value: _vm.displayModule,
            callback: function ($$v) {
              _vm.displayModule = $$v
            },
            expression: "displayModule",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-3 relative search-document-dialog" },
            [
              _c(
                "div",
                { staticClass: "close-icon pr-3" },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "pointer",
                      on: {
                        click: function ($event) {
                          return _vm.closeDialog()
                        },
                      },
                    },
                    [_vm._v("close")]
                  ),
                ],
                1
              ),
              _c(
                "v-card-title",
                { staticClass: "headline pt-5 pb-0 flex-column" },
                [
                  _c("div", { staticClass: "d-flex flex-column full-width" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-center full-width" },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "header-icon",
                            attrs: { color: "primary" },
                          },
                          [_vm._v("search")]
                        ),
                      ],
                      1
                    ),
                    !_vm.displayResults
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-center full-width mb-3 mt-5",
                          },
                          [
                            _c("v-progress-circular", {
                              attrs: {
                                size: 70,
                                width: 7,
                                color: "primary",
                                indeterminate: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.displayResults
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-center full-width mb-3",
                          },
                          [
                            _c("DataTable", {
                              attrs: {
                                columns: _vm.headers,
                                elements: _vm.searchResults.pendingDocuments,
                                state: _vm.tableState,
                              },
                              on: {
                                itemSelected: _vm.propertyChosen,
                                changePage: _vm.changePage,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }