export default
  [
    {
      name: 'Applicant',
      mode: 'standard',
      children: [
        { type: "Text", name: 'cellPhone_a', comments: "Applicant cell phone", required: true},
        { type: "Text", name: 'citizenship_a',        comments: "Applicant citizenship"},
        { type: "Text", name: 'dob_a', comments: "Applicant date of birth, Applicant DOB", required: true},
        { type: "Text", name: 'email_a', comments: "Applicant email", required: true},
        { type: "Text", name: 'fullName_a', comments: "Applicant full name", required: true},
        { type: "Text", name: 'otherPhone_a',         comments: "Applicant other cell phone"},
        { type: "Text", name: 'passportCountry_a',    comments: "Applicant passport country"},
        { type: "Text", name: 'passportNumber_a',     comments: "Applicant passport number"},
        { type: "Text", name: 'ssn_a', comments: "Applicant social security number, Applicant SSN", required: true},
      ]
    },
    {
      name: 'Approval/Denial Letter',
      mode: 'standard',
      children: [
        { type: "Date", name: 'decisionDate', comments: "Decision letter date"},
      ]
    },
    {
      name: 'Authorization Page',
      mode: 'standard',
      children: [
        { type: "Date", name: 'dateSigned',           comments: "Date signed"},
        { type: "Text", name: 'firstName_a',          comments: "Applicant first name"},
        { type: "Text", name: 'firstName_c',          comments: "Co-applicant first name"},
        { type: "Text", name: 'lastName_a',           comments: "Applicant last name"},
        { type: "Text", name: 'lastName_c',           comments: "Co-applicant last name"},
        { type: "Initials", name: 'middleInitial_a',  comments: "Applicant middle name initial"},
        { type: "Initials", name: 'middleInitial_c',  comments: "Co-applicant middle name initial"},
      ]
    },
    {
      name: 'Co-Applicant',
      mode: 'standard',
      children: [
        { type: "Text", name: 'cellPhone_c',          comments: "Co-applicant cell phone"},
        { type: "Text", name: 'citizenship_c',        comments: "Co-applicant citizenship"},
        { type: "Text", name: 'dob_c',                comments: "Co-applicant DOB, Co-applicant date of birth"},
        { type: "Text", name: 'coApplicantTypeValue', comments: "Co-applicant type"},
        { type: "Text", name: 'email_c',              comments: "Co-applicant email"},
        { type: "Text", name: 'fullName_c',           comments: "Co-applicant full name"},
        { type: "Text", name: 'otherPhone_c',         comments: "Co-applicant other cell phone"},
        { type: "Text", name: 'passportCountry_c',    comments: "Co-applicant passport country"},
        { type: "Text", name: 'passportNumber_c',     comments: "Co-applicant passport number"},
        { type: "Text", name: 'ssn_c',                comments: "Co-applicant social security number, SSN"},
      ]
    },
    {
      name: 'Current Residence',
      mode: 'standard',
      children: [
        { type: "Text", name: 'beenVacated_a',        comments: "Applicant current residence has been vacated"},
        { type: "Text", name: 'beenVacated_c',        comments: "Co-applicant current residence has been vacated"},
        { type: "Text", name: 'city_a', comments: "Applicant current residence city", required: true},
        { type: "Text", name: 'city_c',               comments: "Co-applicant current residence city"},
        { type: "Text", name: 'country_a', comments: "Applicant current residence country", required: true},
        { type: "Text", name: 'country_c',            comments: "Co-applicant current residence country"},
        { type: "Text", name: 'gaveAdequateNotice_a', comments: "Applicant current residence gave adequate notice"},
        { type: "Text", name: 'gaveAdequateNotice_c', comments: "Co-applicant current residence gave adequate notice"},
        { type: "Text", name: 'houseNumber_a', comments: "Applicant current residence house number", required: true},
        { type: "Text", name: 'houseNumber_c',        comments: "Co-applicant current residence house number"},
        { type: "Text", name: 'landlordEmail_a', comments: "Applicant current residence landlord email", required: true},
        { type: "Text", name: 'landlordEmail_c',      comments: "Co-applicant current residence landlord email"},
        { type: "Text", name: 'landlordName_a', comments: "Applicant current residence landlord name", required: true},
        { type: "Text", name: 'landlordName_c',       comments: "Co-applicant current residence landlord name"},
        { type: "Text", name: 'landlordPhone_a', comments: "Applicant current residence landlord phone", required: true},
        { type: "Text", name: 'landlordPhone_c',      comments: "Co-applicant current residence landlord phone"},
        { type: "Text", name: 'months_a', comments: "Applicant current residence months", required: true},
        { type: "Text", name: 'months_c',             comments: "Co-applicant current residence months"},
        { type: "Text", name: 'ownHome_a',            comments: "Applicant own home"},
        { type: "Text", name: 'ownHome_c',            comments: "Co-applicant own home"},
        { type: "Text", name: 'rentalAmount_a', comments: "Applicant current residence rental amount", required: true},
        { type: "Text", name: 'rentalAmount_c',       comments: "Co-applicant current residence rental amount"},
        { type: "Text", name: 'state_a', comments: "Applicant current residence state", required: true},
        { type: "Text", name: 'state_c',              comments: "Co-applicant current residence state"},
        { type: "Text", name: 'street_a', comments: "Applicant current residence street", required: true},
        { type: "Text", name: 'street_c',             comments: "Co-applicant current residence street"},
        { type: "Text", name: 'streetType_a', comments: "Applicant current residence street type", required: true},
        { type: "Text", name: 'streetType_c',         comments: "Co-applicant current residence street type"},
        { type: "Text", name: 'years_a', comments: "Applicant current residence years", required: true},
        { type: "Text", name: 'years_c',              comments: "Co-applicant current residence years"},
        { type: "Text", name: 'zipCode_a', comments: "Applicant current residence zip code", required: true},
        { type: "Text", name: 'zipCode_c',            comments: "Co-applicant current residence zip code"},
      ]
    },
    {
      name: 'Date applied with Signature',
      mode: 'standard',
      children: [
        { type: "Date", name: 'TenantDate_1', comments: "Applicant signature date 1", required: true},
        { type: "Date", name: 'TenantDate_2', comments: "Co-applicant Signature date 2"},
      ]
    },
    {
      name: 'Emergency Contact',
      mode: 'standard',
      children: [
        { type: "Text", name: 'cityState',        comments: "Emergency contact state"},
        { type: "Text", name: 'ecCell', comments: "Emergency contact cell", required: true},
        { type: "Text", name: 'ecEmail', comments: "Emergency contact email", required: true},
        { type: "Text", name: 'ecHome', comments: "Emergency contact home Phone", required: true},
        { type: "Text", name: 'ecHouseNumber',      comments: "Emergency house number"},
        { type: "Text", name: 'ecName', comments: "Emergency contact name", required: true},
        { type: "Text", name: 'ecRelationship', comments: "Emergency contact relationship", required: true},
        { type: "Text", name: 'emergencyCountry',      comments: "Emergency contact country"},
      ]
    },
    {
      name: 'Employment Applicant',
      mode: 'standard',
      children: [
        { type: "Text", name: 'bonus_a',                      comments: "Applicant bonus if self-employed"},
        { type: "Text", name: 'busName_a',                    comments: "Applicant business name if self-employed"},
        { type: "Text", name: 'busPhone_a',                   comments: "Applicant business phone if self-employed"},
        { type: "Text", name: 'busType_a',                    comments: "Applicant business type if self-employed"},
        { type: "Text", name: 'busYears_a',                   comments: "Applicant business years if self-employed"},
        { type: "Text", name: 'empAddress_a',                 comments: "Applicant employment address"},
        { type: "Text", name: 'empAmount_a',                  comments: "Applicant employment amount monthly income"},
        { type: "Text", name: 'empCity_a',                    comments: "Applicant employment city"},
        { type: "Text", name: 'empCountry_a',                 comments: "Applicant employment country"},
        { type: "Text", name: 'empFirm_a',                    comments: "Applicant employment firm"},
        { type: "Text", name: 'employmentPayIntervalValue_a', comments: "Applicant employment payment interval"},
        { type: "Text", name: 'employmentTypeValue_a', comments: "Applicant employment type", required: true},
        { type: "Text", name: 'empStart_a',                   comments: "Applicant employment start"},
        { type: "Text", name: 'empState_a',                   comments: "Applicant employment state"},
        { type: "Text", name: 'empTitle_a',                   comments: "Applicant employment title"},
        { type: "Text", name: 'empZipCode_a',                 comments: "Applicant employment zip code"},
        { type: "Text", name: 'income_a',                     comments: "Applicant yearly income"},
        { type: "Text", name: 'supEmail_a',                   comments: "Applicant superior email"},
        { type: "Text", name: 'supName_a',                    comments: "Applicant superior name"},
        { type: "Text", name: 'supPhone_a',                   comments: "Applicant superior phone"},
        { type: "Text", name: 'supTitle_a',                   comments: "Applicant superior title"},
      ]
    },
    {
      name: 'Employment Co-Applicant',
      mode: 'standard',
      children: [
        { type: "Text", name: 'bonus_c',                      comments: "Co-applicant bonus if self-employed"},
        { type: "Text", name: 'busName_c',                    comments: "Co-applicant business name if self-employed"},
        { type: "Text", name: 'busPhone_c',                   comments: "Co-applicant business phone if self-employed"},
        { type: "Text", name: 'busType_c',                    comments: "Co-applicant business type if self-employed"},
        { type: "Text", name: 'busYears_c',                   comments: "Co-applicant business years if self-employed"},
        { type: "Text", name: 'empAddress_c',                 comments: "Co-applicant employment address"},
        { type: "Text", name: 'empAmount_c',                  comments: "Co-applicant employment mountly income"},
        { type: "Text", name: 'empCity_c',                    comments: "Co-applicant employment city"},
        { type: "Text", name: 'empCountry_c',                 comments: "Co-applicant employment country"},
        { type: "Text", name: 'empFirm_c',                    comments: "Co-applicant employment firm"},
        { type: "Text", name: 'employmentPayIntervalValue_c', comments: "Co-applicant employment payment interval"},
        { type: "Text", name: 'employmentTypeValue_c',        comments: "Co-applicant employment type"},
        { type: "Text", name: 'empStart_c',                   comments: "Co-applicant employment start"},
        { type: "Text", name: 'empState_c',                   comments: "Co-applicant employment state"},
        { type: "Text", name: 'empTitle_c',                   comments: "Co-applicant employment title"},
        { type: "Text", name: 'empZipCode_c',                 comments: "Co-applicant employment zip code"},
        { type: "Text", name: 'income_c',                     comments: "Co-applicant yearly income"},
        { type: "Text", name: 'supEmail_c',                   comments: "Co-applicant superior email"},
        { type: "Text", name: 'supName_c',                    comments: "Co-applicant superior name"},
        { type: "Text", name: 'supPhone_c',                   comments: "Co-applicant superior phone"},
        { type: "Text", name: 'supTitle_c',                   comments: "Co-applicant superior title"},
      ]
    },
    {
      name: 'Initials',
      mode: 'standard',
      children: [
        { type: "Initials", name: 'TenantInitials_1', comments: "Applicant initials", required: true},
        { type: "Initials", name: 'TenantInitials_2', comments: "Co-applicant initials"},
      ]
    },
    {
      name: 'Moving Address',
      mode: 'standard',
      children: [
        { type: "Text", name: 'city', comments: "Move in property city", required: true},
        { type: "Text", name: 'houseNumber', comments: "Move in property house number", required: true},
        { type: "Text", name: 'propertyAddress', comments: "Full move in Property address", required: true},
        { type: "Text", name: 'state', comments: "Move in property state", required: true},
        { type: "Text", name: 'street', comments: "Move in property street", required: true},
        { type: "Text", name: 'unit', comments: "Move in property unit number, Unit#", required: true},
        { type: "Text", name: 'zipCode', comments: "Move in property ZIP code", required: true},
      ]
    },
    {
      name: 'Not page specific',
      mode: 'standard',
      children: [
        { type: "Text", name: 'applicationFee', comments: "Application fee"},
        { type: "Text", name: 'applicationId',  comments: "Application ID"},
        { type: "Text", name: 'bothNames',      comments: "Both names"},
        { type: "Text", name: 'firstName',      comments: "First name"},
        { type: "Text", name: 'fullName',       comments: "Full name"},
        { type: "Text", name: 'lastName',       comments: "Last name"},
      ]
    },
    {
      name: 'Other Occupants',
      mode: 'standard',
      children: [
        { type: "Text", name: 'o1Age',          comments: "Other occupant 1 age"},
        { type: "Text", name: 'o1Name',         comments: "Other occupant 1 name"},
        { type: "Text", name: 'o1Occupation',   comments: "Other occupant 1 occupation"},
        { type: "Text", name: 'o1Relationship', comments: "Other occupant 1 relationship"},
        { type: "Text", name: 'o2Age',          comments: "Other occupant 2 age"},
        { type: "Text", name: 'o2Name',         comments: "Other occupant 2 name"},
        { type: "Text", name: 'o2Occupation',   comments: "Other occupant 2 occupation"},
        { type: "Text", name: 'o2Relationship', comments: "Other occupant 2 relationship"},
        { type: "Text", name: 'o3Age',          comments: "Other occupant 3 age"},
        { type: "Text", name: 'o3Name',         comments: "Other occupant 3 name"},
        { type: "Text", name: 'o3Occupation',   comments: "Other occupant 3 occupation"},
        { type: "Text", name: 'o3Relationship', comments: "Other occupant 3 relationship"},
      ]
    },
    {
      name: 'Property Page',
      mode: 'standard',
      children: [
        { type: "Text", name: 'addressLine2', comments: "Property address line 2", required: true},
        { type: "Text", name: 'buildingName', comments: "Property building name", required: true},
        { type: "Date", name: 'closingDate', comments: "Closing date", required: true},
        { type: "Date", name: 'leaseEndDate', comments: "Lease end date", required: true},
        { type: "Text", name: 'leaseMonths', comments: "Lease number of months", required: true},
        { type: "Text", name: 'leaseTerm', comments: "Lease term", required: true},
        { type: "Text", name: 'monthlyRent', comments: "Property monthly rent", required: true},
        { type: "Date", name: 'moveInDate', comments: "Move in date or lease start date", required: true},
        { type: "Text", name: 'occupancyTypeValue', comments: "Type of occupancy"},
        { type: "Text", name: 'propertyId',         comments: "Property ID"},
      ]
    },
    {
      name: 'Realtors',
      mode: 'standard',
      children: [
        { type: "Text", name: 'realtorEmail', 	comments: "Realtor email"},
        { type: "Text", name: 'realtorName', 	  comments: "Realtor name"},
        { type: "Text", name: 'realtorPhone', 	comments: "Realtor phone"},
      ]
    },
    {
      name: 'Receipt',
      mode: 'standard',
      children: [
        { type: "Text", name: 'pmtAmount',      comments: "Receipt amount"},
        { type: "Text", name: 'pmtCardNumber',  comments: "Receipt Card Number"},
        { type: "Text", name: 'pmtName',        comments: "Receipt Payee Name"},
        { type: "Date", name: 'pmtPostedDate',  comments: "Receipt Posted date"},
        { type: "Text", name: 'pmtTransId',     comments: "Receipt Transfer ID"},
      ]
    },
    {
      name: 'References',
      mode: 'standard',
      children: [
        { type: "Text", name: 'ref1Cell_a', comments: "Applicant reference 1 phone", required: true},
        { type: "Text", name: 'ref1Cell_c',               comments: "Co-applicant reference 1 phone"},
        { type: "Text", name: 'ref1Country_a',            comments: "Applicant reference 1 country"},
        { type: "Text", name: 'ref1Country_c',            comments: "Co-applicant reference 1 country"},
        { type: "Text", name: 'ref1Email_a', comments: "Applicant reference 1 email", required: true},
        { type: "Text", name: 'ref1Email_c',              comments: "Co-applicant reference 1 email"},
        { type: "Text", name: 'ref1Home_a',               comments: "Applicant reference 1 home Phone"},
        { type: "Text", name: 'ref1Home_c',               comments: "Co-applicant reference 1 home Phone"},
        { type: "Text", name: 'ref1Name_a', comments: "Applicant reference 1 name", required: true},
        { type: "Text", name: 'ref1Name_c',               comments: "Co-applicant reference 1 name"},
        { type: "Text", name: 'ref1RelationshipValue_a', comments: "Applicant reference 1 relationship", required: true},
        { type: "Text", name: 'ref1RelationshipValue_c',  comments: "Co-applicant reference 1 relationship"},
        { type: "Text", name: 'ref2Cell_a', comments: "Applicant reference 2 phone", required: true},
        { type: "Text", name: 'ref2Cell_c',               comments: "Co-applicant reference 2 phone"},
        { type: "Text", name: 'ref2Country_a',            comments: "Applicant reference 2 country"},
        { type: "Text", name: 'ref2Country_c',            comments: "Co-applicant reference 2 country"},
        { type: "Text", name: 'ref2Email_a', comments: "Applicant reference 2 email", required: true},
        { type: "Text", name: 'ref2Email_c',              comments: "Co-applicant reference 2 email"},
        { type: "Text", name: 'ref2Home_a',               comments: "Applicant reference 1 home Phone"},
        { type: "Text", name: 'ref2Home_c',               comments: "Co-applicant reference 1 home Phone"},
        { type: "Text", name: 'ref2Name_a', comments: "Applicant reference 2 name", required: true},
        { type: "Text", name: 'ref2Name_c',               comments: "Co-applicant reference 2 name"},
        { type: "Text", name: 'ref2RelationshipValue_a', comments: "Applicant reference 2 relationship", required: true},
        { type: "Text", name: 'ref2RelationshipValue_c',  comments: "Co-applicant reference 2 relationship"},
      ]
    },
    {
      name: 'Signatures',
      mode: 'standard',
      children: [
        { type: "Signature", name: 'TenantSignature_1', comments: "Applicant signature", required: true},
        { type: "Signature", name: 'TenantSignature_2', comments: "Co-applicant signature"},
      ]
    },
    {
      name: 'Owner',
      mode: 'multitab',
      pos: 2,
      options: {
        Data: [
          { type: "Text", name: 'ownerEmail', comments: "Current Owner email"},
          { type: "Text", name: 'ownerName',  comments: "Current Owner name"},
          { type: "Text", name: 'ownerPhone', comments: "Current Owner phone"},
        ],
        Signature: [
          { type: "Date", name: 'TenantDate_Owner', comments: "Current Owner signature date (Please use only if the property form is asking for Current Owner Signatures)", required: true},
          { type: "Initials", name: 'TenantInitials_Owner', comments: "Current Owner signature initials (Please use only if the property form is asking for Current Owner Signatures)", required: true},
          { type: "Text", name: 'TenantName_Owner', comments: "Current Owner Name (Please use only if the property form is asking for Current Owner Signatures)", required: true},
          { type: "Signature", name: 'TenantSignature_Owner', comments: "Current Owner Signature (Please use only if the property form is asking for Current Owner Signatures)", required: true},
        ]
      },
      children: [
        { type: "Text", name: 'ownerEmail', comments: "Current Owner email"},
        { type: "Text", name: 'ownerName',  comments: "Current Owner name"},
        { type: "Text", name: 'ownerPhone', comments: "Current Owner phone"},
        { type: "Date", name: 'TenantDate_Owner',      comments: "Current Owner signature date (Please use only if the property form is asking for Current Owner Signatures)"},
        { type: "Initials", name: 'TenantInitials_Owner',      comments: "Current Owner signature initials (Please use only if the property form is asking for Current Owner Signatures)"},
        { type: "Text", name: 'TenantName_Owner',      comments: "Current Owner Name (Please use only if the property form is asking for Current Owner Signatures)"},
        { type: "Signature", name: 'TenantSignature_Owner',  comments: "Current Owner Signature (Please use only if the property form is asking for Current Owner Signatures)"},
      ]
    },
    {
      name: 'Pet',
      mode: 'multitab',
      pos: 0,
      options: {
          Pet: [
            { type: "Text", name: 'petAge',         comments: "Pet age"},
            { type: "Text", name: 'petBreed',       comments: "Pet breed"},
            { type: "Text", name: 'petDescription', comments: "Pet description"},
            { type: "Text", name: 'petLicense',     comments: "Pet license"},
            { type: "Text", name: 'petName',        comments: "Pet name"},
            { type: "Text", name: 'petSexValue',    comments: "Pet sex value"},
            { type: "Text", name: 'petTypeValue',   comments: "Pet type value"},
            { type: "Text", name: 'petWeight',      comments: "Pet weight"},
          ],
          Pet2: [
            { type: "Text", name: 'petAge2',         comments: "Pet age 2"},
            { type: "Text", name: 'petBreed2',       comments: "Pet breed 2"},
            { type: "Text", name: 'petDescription2', comments: "Pet description 2"},
            { type: "Text", name: 'petLicense2',     comments: "Pet license 2"},
            { type: "Text", name: 'petName2',        comments: "Pet name 2"},
            { type: "Text", name: 'petSexValue2',    comments: "Pet sex value 2"},
            { type: "Text", name: 'petTypeValue2',   comments: "Pet type value 2"},
            { type: "Text", name: 'petWeight2',      comments: "Pet weight 2"},
          ],
      },
      children: [
          { type: "Text", name: 'petAge',         comments: "Pet age"},
          { type: "Text", name: 'petAge2',         comments: "Pet age 2"},
          { type: "Text", name: 'petBreed',       comments: "Pet breed"},
          { type: "Text", name: 'petBreed2',       comments: "Pet breed 2"},
          { type: "Text", name: 'petDescription', comments: "Pet description"},
          { type: "Text", name: 'petDescription2', comments: "Pet description 2"},
          { type: "Text", name: 'petLicense',     comments: "Pet license"},
          { type: "Text", name: 'petLicense2',     comments: "Pet license 2"},
          { type: "Text", name: 'petName',        comments: "Pet name"},
          { type: "Text", name: 'petName2',        comments: "Pet name 2"},
          { type: "Text", name: 'petSexValue',    comments: "Pet sex value"},
          { type: "Text", name: 'petSexValue2',    comments: "Pet sex value 2"},
          { type: "Text", name: 'petTypeValue',   comments: "Pet type value"},
          { type: "Text", name: 'petTypeValue2',   comments: "Pet type value 2"},
          { type: "Text", name: 'petWeight',      comments: "Pet weight"},
          { type: "Text", name: 'petWeight2',      comments: "Pet weight 2"},
      ]
    },
    {
      name: 'Vehicle',
      mode: 'multitab',
      pos: 1,
      options: {
            Vehicle: [
                { type: "Text", name: 'vehColor', comments: "Vehicle color"},
                { type: "Text", name: 'vehInsCo', comments: "Vehicle insurance company"},
                { type: "Text", name: 'vehMake',  comments: "Vehicle make"},
                { type: "Text", name: 'vehModel', comments: "Vehicle model"},
                { type: "Text", name: 'vehState', comments: "Vehicle state"},
                { type: "Text", name: 'vehTag',   comments: "Vehicle tag"},
                { type: "Text", name: 'vehVin',   comments: "Vehicle VIN number, Vehicle identification number"},
                { type: "Text", name: 'vehYear',  comments: "Vehicle year"},
            ],
            Vehicle2: [
                { type: "Text", name: 'vehColor2', comments: "Vehicle color 2"},
                { type: "Text", name: 'vehInsCo2', comments: "Vehicle insurance company 2"},
                { type: "Text", name: 'vehMake2',  comments: "Vehicle make 2"},
                { type: "Text", name: 'vehModel2', comments: "Vehicle model 2"},
                { type: "Text", name: 'vehState2', comments: "Vehicle state 2"},
                { type: "Text", name: 'vehTag2',   comments: "Vehicle tag 2"},
                { type: "Text", name: 'vehVin2',   comments: "Vehicle VIN number, Vehicle identification number 2"},
                { type: "Text", name: 'vehYear2',  comments: "Vehicle year 2"},
            ],
            Vehicle3: [
                { type: "Text", name: 'vehColor3', comments: "Vehicle color 3"},
                { type: "Text", name: 'vehInsCo3', comments: "Vehicle insurance company 3"},
                { type: "Text", name: 'vehMake3',  comments: "Vehicle make 3"},
                { type: "Text", name: 'vehModel3', comments: "Vehicle model 3"},
                { type: "Text", name: 'vehState3', comments: "Vehicle state 3"},
                { type: "Text", name: 'vehTag3',   comments: "Vehicle tag 3"},
                { type: "Text", name: 'vehVin3',   comments: "Vehicle VIN number, Vehicle identification number 3"},
                { type: "Text", name: 'vehYear3',  comments: "Vehicle year 3"},
            ]
      },
      children: [
        { type: "Text", name: 'vehColor', comments: "Vehicle color"},
        { type: "Text", name: 'vehColor2', comments: "Vehicle color 2"},
        { type: "Text", name: 'vehColor3', comments: "Vehicle color 3"},
        { type: "Text", name: 'vehInsCo', comments: "Vehicle insurance company"},
        { type: "Text", name: 'vehInsCo2', comments: "Vehicle insurance company 2"},
        { type: "Text", name: 'vehInsCo3', comments: "Vehicle insurance company 3"},
        { type: "Text", name: 'vehMake',  comments: "Vehicle make"},
        { type: "Text", name: 'vehMake2',  comments: "Vehicle make 2"},
        { type: "Text", name: 'vehMake3',  comments: "Vehicle make 3"},
        { type: "Text", name: 'vehModel', comments: "Vehicle model"},
        { type: "Text", name: 'vehModel2', comments: "Vehicle model 2"},
        { type: "Text", name: 'vehModel3', comments: "Vehicle model 3"},
        { type: "Text", name: 'vehState', comments: "Vehicle state"},
        { type: "Text", name: 'vehState2', comments: "Vehicle state 2"},
        { type: "Text", name: 'vehState3', comments: "Vehicle state 3"},
        { type: "Text", name: 'vehTag',   comments: "Vehicle tag"},
        { type: "Text", name: 'vehTag2',   comments: "Vehicle tag 2"},
        { type: "Text", name: 'vehTag3',   comments: "Vehicle tag 3"},
        { type: "Text", name: 'vehVin',   comments: "Vehicle VIN number, Vehicle identification number"},
        { type: "Text", name: 'vehVin2',   comments: "Vehicle VIN number, Vehicle identification number 2"},
        { type: "Text", name: 'vehVin3',   comments: "Vehicle VIN number, Vehicle identification number 3"},
        { type: "Text", name: 'vehYear',  comments: "Vehicle year"},
        { type: "Text", name: 'vehYear2',  comments: "Vehicle year 2"},
        { type: "Text", name: 'vehYear3',  comments: "Vehicle year 3"},
      ]
    },
    {
      name: 'Current Address',
      mode: 'multitab',
      pos: 3,
      options: {
        Applicant: [
          { type: "Text", name: 'houseNumber_a', comments: "Applicant current address house number", required: true},
          { type: "Text", name: 'street_a', comments: "Applicant current address street", required: true},
          { type: "Text", name: 'streetType_a', comments: "Applicant current address street type", required: true},
          { type: "Text", name: 'city_a', comments: "Applicant current address city", required: true},
          { type: "Text", name: 'state_a', comments: "Applicant current address state", required: true},
          { type: "Text", name: 'zipCode_a', comments: "Applicant current address zip code", required: true},
        ],
        'Co-Applicant': [
          { type: "Text", name: 'houseNumber_c',        comments: "Co-applicant current address house number"},
          { type: "Text", name: 'street_c',             comments: "Co-applicant current address street"},
          { type: "Text", name: 'streetType_c',         comments: "Co-applicant current address street type"},
          { type: "Text", name: 'city_c',               comments: "Co-applicant current address city"},
          { type: "Text", name: 'state_c',              comments: "Co-applicant current address state"},
          { type: "Text", name: 'zipCode_c',            comments: "Co-applicant current address zip code"},
        ]
      },
      children: [
        { type: "Text", name: 'houseNumber_a', comments: "Applicant current address house number", required: true},
        { type: "Text", name: 'street_a', comments: "Applicant current address street", required: true},
        { type: "Text", name: 'streetType_a', comments: "Applicant current address street type", required: true},
        { type: "Text", name: 'city_a', comments: "Applicant current address city", required: true},
        { type: "Text", name: 'state_a', comments: "Applicant current address state", required: true},
        { type: "Text", name: 'zipCode_a', comments: "Applicant current address zip code", required: true},
        { type: "Text", name: 'houseNumber_c',        comments: "Co-applicant current address house number"},
        { type: "Text", name: 'street_c',             comments: "Co-applicant current address street"},
        { type: "Text", name: 'streetType_c',         comments: "Co-applicant current address street type"},
        { type: "Text", name: 'city_c',               comments: "Co-applicant current address city"},
        { type: "Text", name: 'state_c',              comments: "Co-applicant current address state"},
        { type: "Text", name: 'zipCode_c',            comments: "Co-applicant current address zip code"},
      ]
    },
    {
      name: 'Compliance Manual Codes',
      mode: 'multitab',
      pos: 4,
      options: {
        Applicant: [
          { type: "Text", name: 'beenArrested',       comments: "Applicant has been arrested?", custom: true},
          { type: "Text", name: 'beenConvicted',      comments: "Applicant has been convicted?", custom: true},
          { type: "Text", name: 'beenEvicted',        comments: "Applicant has been evicted?", custom: true},
          { type: "Text", name: 'beenSued',           comments: "Applicant has been sued?", custom: true},
          { type: "Text", name: 'brokenLease',        comments: "Applicant has broken lease?", custom: true},
          { type: "Text", name: 'filedBankruptcy',    comments: "Applicant has filed bankruptcy?", custom: true},
          { type: "Text", name: 'mailingAddress', comments: "Mailing Address", custom: true},
        ],
        'Co-Applicant': [
          { type: "Text", name: 'beenArrested1',       comments: "Co-Applicant has been arrested?", custom: true},
          { type: "Text", name: 'beenConvicted1',      comments: "Co-Applicant has been convicted?", custom: true},
          { type: "Text", name: 'beenEvicted1',        comments: "Co-Applicant has been evicted?", custom: true},
          { type: "Text", name: 'beenSued1',           comments: "Co-Applicant has been sued?", custom: true},
          { type: "Text", name: 'brokenLease1',        comments: "Co-Applicant has broken lease?", custom: true},
          { type: "Text", name: 'filedBankruptcy1',    comments: "Co-Applicant has filed bankruptcy?", custom: true},
        ],
        'Current Residence': [
          { type: "Text", name: 'howLongCurrentAdd_a',       comments: "How long current address applicant", custom: true},
          { type: "Text", name: 'howLongCurrentAdd_c',       comments: "How long current address co-applicant", custom: true},
        ],
        'Employment Applicant': [
          { type: "Text", name: 'empEnd_a',       comments: "Employment end date applicant", custom: true},
          { type: "Text", name: 'empHowLong_a',       comments: "Employment how long applicant", custom: true},
        ],
        'Employment Co-Applicant': [
          { type: "Text", name: 'empEnd_c',       comments: "Employment end date co-applicant", custom: true},
          { type: "Text", name: 'empHowLong_c',       comments: "Employment how long co-applicant", custom: true},
        ],
        Realtor: [
          { type: "Text", name: 'realtorAddress',       comments: "Realtor address", custom: true},
        ],
        References: [
          { type: "Text", name: 'ref1Address_a',       comments: "Applicant Reference 1 address", custom: true},
          { type: "Text", name: 'ref2Address_a',       comments: "Applicant Reference 2 address", custom: true},
          { type: "Text", name: 'ref1Address_c',       comments: "Co-Applicant Reference 1 address", custom: true},
          { type: "Text", name: 'ref2Address_c',       comments: "Co-Applicant Reference 2 address", custom: true},
        ],
        'Owner Data': [
          { type: "Text", name: 'ownerAddress',       comments: "Owner address", custom: true},
        ],
        Pet: [
          { type: "Text", name: 'petColor',       comments: "Pet color", custom: true},
          { type: "Text", name: 'petWeightMaturity',       comments: "Pet weight maturity", custom: true},
          { type: "Text", name: 'petVetName',       comments: "Pet veterinary name", custom: true},
          { type: "Text", name: 'petVetPhone',       comments: "Pet veterinary phone", custom: true},
        ],
        'Emotional Support Animal': [
          { type: "Text", name: 'ESAName',       comments: "Emotional Support Animal Name", custom: true},
          { type: "Text", name: 'ESABreed',       comments: "Emotional Support Animal Breed", custom: true},
          { type: "Text", name: 'ESAAge',       comments: "Emotional Support Animal Age", custom: true},
          { type: "Text", name: 'ESAWeight',       comments: "Emotional Support Animal Weight", custom: true},
          { type: "Text", name: 'ESALicense',       comments: "Emotional Support Animal License", custom: true},
          { type: "Text", name: 'ESATypeValue',       comments: "Emotional Support Animal TypeValue", custom: true},
          { type: "Text", name: 'ESASexValue',       comments: "Emotional Support Animal SexValue", custom: true},
          { type: "Text", name: 'ESADescription',       comments: "Emotional Support Animal Description", custom: true},
        ],
      },
      children: [
        { type: "Text", name: 'beenArrested',       comments: "Applicant has been arrested?", custom: true},
        { type: "Text", name: 'beenConvicted',      comments: "Applicant has been convicted?", custom: true},
        { type: "Text", name: 'beenEvicted',        comments: "Applicant has been evicted?", custom: true},
        { type: "Text", name: 'beenSued',           comments: "Applicant has been sued?", custom: true},
        { type: "Text", name: 'brokenLease',        comments: "Applicant has broken lease?", custom: true},
        { type: "Text", name: 'filedBankruptcy',    comments: "Applicant has filed bankruptcy?", custom: true},
        { type: "Text", name: 'mailingAddress', comments: "Mailing Address", custom: true},
        { type: "Text", name: 'beenArrested1',       comments: "Co-Applicant has been arrested?", custom: true},
        { type: "Text", name: 'beenConvicted1',      comments: "Co-Applicant has been convicted?", custom: true},
        { type: "Text", name: 'beenEvicted1',        comments: "Co-Applicant has been evicted?", custom: true},
        { type: "Text", name: 'beenSued1',           comments: "Co-Applicant has been sued?", custom: true},
        { type: "Text", name: 'brokenLease1',        comments: "Co-Applicant has broken lease?", custom: true},
        { type: "Text", name: 'filedBankruptcy1',    comments: "Co-Applicant has filed bankruptcy?", custom: true},
        { type: "Text", name: 'howLongCurrentAdd_a',       comments: "How long current address applicant", custom: true},
        { type: "Text", name: 'howLongCurrentAdd_c',       comments: "How long current address co-applicant", custom: true},
        { type: "Text", name: 'empEnd_a',       comments: "Employment end date applicant", custom: true},
        { type: "Text", name: 'empHowLong_a',       comments: "Employment how long applicant", custom: true},
        { type: "Text", name: 'empEnd_c',       comments: "Employment end date co-applicant", custom: true},
        { type: "Text", name: 'empHowLong_c',       comments: "Employment how long co-applicant", custom: true},
        { type: "Text", name: 'realtorAddress',       comments: "Realtor address", custom: true},
        { type: "Text", name: 'ref1Address_a',       comments: "Applicant Reference 1 address", custom: true},
        { type: "Text", name: 'ref2Address_a',       comments: "Applicant Reference 2 address", custom: true},
        { type: "Text", name: 'ref1Address_c',       comments: "Co-Applicant Reference 1 address", custom: true},
        { type: "Text", name: 'ref2Address_c',       comments: "Co-Applicant Reference 2 address", custom: true},
        { type: "Text", name: 'ownerAddress',       comments: "Owner address", custom: true},
        { type: "Text", name: 'petColor',       comments: "Pet color", custom: true},
        { type: "Text", name: 'petWeightMaturity',       comments: "Pet weight maturity", custom: true},
        { type: "Text", name: 'petVetName',       comments: "Pet veterinary name", custom: true},
        { type: "Text", name: 'petVetPhone',       comments: "Pet veterinary phone", custom: true},
        { type: "Text", name: 'ESAName',       comments: "Emotional Support Animal Name", custom: true},
        { type: "Text", name: 'ESABreed',       comments: "Emotional Support Animal Breed", custom: true},
        { type: "Text", name: 'ESAAge',       comments: "Emotional Support Animal Age", custom: true},
        { type: "Text", name: 'ESAWeight',       comments: "Emotional Support Animal Weight", custom: true},
        { type: "Text", name: 'ESALicense',       comments: "Emotional Support Animal License", custom: true},
        { type: "Text", name: 'ESATypeValue',       comments: "Emotional Support Animal TypeValue", custom: true},
        { type: "Text", name: 'ESASexValue',       comments: "Emotional Support Animal SexValue", custom: true},
        { type: "Text", name: 'ESADescription',       comments: "Emotional Support Animal Description", custom: true},
      ]
    },
  ]
