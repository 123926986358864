var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "550" },
          model: {
            value: _vm.displayDialog,
            callback: function ($$v) {
              _vm.displayDialog = $$v
            },
            expression: "displayDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-3 relative" },
            [
              _c(
                "div",
                { staticClass: "close-icon pr-3" },
                [
                  !_vm.isConnecting
                    ? _c(
                        "v-icon",
                        {
                          staticClass: "pointer",
                          on: {
                            click: function ($event) {
                              return _vm.closeDialog()
                            },
                          },
                        },
                        [_vm._v("close")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-card-title",
                { staticClass: "headline pt-5 pb-0 flex-column" },
                [
                  _vm.step == 1
                    ? _c(
                        "div",
                        { staticClass: "d-flex flex-column full-width" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-center full-width" },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "header-icon",
                                  attrs: { color: "primary" },
                                },
                                [_vm._v("upload_file")]
                              ),
                            ],
                            1
                          ),
                          !_vm.displayResults
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-center full-width my-3",
                                },
                                [
                                  _c("span", { staticClass: "header-title" }, [
                                    _vm._v("Upload a new PDF File"),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          !_vm.displayResults
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-center full-width my-3 dropzone",
                                  on: {
                                    dragover: function ($event) {
                                      return _vm.filedragover($event)
                                    },
                                    dragleave: function ($event) {
                                      return _vm.filedragleave($event)
                                    },
                                    drop: function ($event) {
                                      return _vm.filedrop($event)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "dropzone-info-area" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "selected-files-to-upload",
                                        },
                                        _vm._l(
                                          _vm.filesAdded,
                                          function (file, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: "file" + index,
                                                staticClass: "dropzone-content",
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass:
                                                      "material-icons-outlined font-size-34",
                                                    attrs: { color: "primary" },
                                                  },
                                                  [_vm._v("description")]
                                                ),
                                                _c("span", [
                                                  _vm._v(_vm._s(file.name)),
                                                ]),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass:
                                                      "material-icons-outlined delete-file pointer",
                                                    attrs: { color: "error" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeFile(
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("delete")]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "dropzone-content" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                fab: "",
                                                small: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.browseFile()
                                                },
                                              },
                                            },
                                            [_c("v-icon", [_vm._v("backup")])],
                                            1
                                          ),
                                          _c("span", [
                                            _vm._v("Drag a file here or "),
                                          ]),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "ml-1",
                                              attrs: { href: "@" },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.browseFile()
                                                },
                                              },
                                            },
                                            [_vm._v(" browse")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c("input", {
                                    ref: "uplfile",
                                    staticClass: "d-none",
                                    attrs: {
                                      type: "file",
                                      multiple: "",
                                      name: "fields[assetsFieldHandle][]",
                                      id: "assetsFieldHandle",
                                      accept: ".pdf",
                                      disabled: _vm.isLoading,
                                    },
                                    on: { change: _vm.fileSelected },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-center full-width my-3 relative",
                            },
                            [
                              _vm.displayResults
                                ? _c(
                                    "v-icon",
                                    {
                                      staticClass: "float-left-abs",
                                      on: {
                                        click: function ($event) {
                                          return _vm.cleanSearch()
                                        },
                                      },
                                    },
                                    [_vm._v("undo")]
                                  )
                                : _vm._e(),
                              _c("span", { staticClass: "header-title" }, [
                                _vm._v("Or import from existing property"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-center full-width my-3",
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Search by Property ID",
                                  outline: "",
                                  "append-icon": "search",
                                },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.searchProperties()
                                  },
                                  "click:append": function ($event) {
                                    return _vm.searchProperties()
                                  },
                                },
                                model: {
                                  value: _vm.propertyId,
                                  callback: function ($$v) {
                                    _vm.propertyId = $$v
                                  },
                                  expression: "propertyId",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.displayResults
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-center full-width mb-3",
                                },
                                [
                                  _c("DataTable", {
                                    attrs: {
                                      columns: _vm.headers,
                                      elements: _vm.searchResults.properties,
                                      state: _vm.tableState,
                                    },
                                    on: {
                                      changePage: _vm.changePage,
                                      itemSelected: _vm.propertyChosen,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _vm.step == 2
                    ? _c(
                        "div",
                        { staticClass: "d-flex flex-column full-width" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-center full-width" },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "header-icon",
                                  attrs: { color: "primary" },
                                },
                                [_vm._v("upload_file")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-center full-width my-3 relative",
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "float-left-abs",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goBack()
                                    },
                                  },
                                },
                                [_vm._v("undo")]
                              ),
                              _c("span", { staticClass: "header-title" }, [
                                _vm._v("Import from existing property"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-center full-width my-3",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "header-title font-weight-medium",
                                },
                                [_vm._v(_vm._s(_vm.propertySelected.name))]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-center full-width" },
                            [
                              _c(
                                "span",
                                { staticClass: "import-instructions-text" },
                                [
                                  _vm._v(
                                    "Now choose from the following list one of the available documents to be imported."
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-start full-width my-3",
                            },
                            [
                              _vm.types.length
                                ? _c(
                                    "v-radio-group",
                                    {
                                      model: {
                                        value: _vm.docType,
                                        callback: function ($$v) {
                                          _vm.docType = $$v
                                        },
                                        expression: "docType",
                                      },
                                    },
                                    _vm._l(_vm.types, function (typ, index) {
                                      return _c("v-radio", {
                                        key: index,
                                        attrs: { label: typ, value: index },
                                      })
                                    }),
                                    1
                                  )
                                : _vm._e(),
                              _vm.types.length == 0
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "import-instructions-text red--text",
                                    },
                                    [
                                      _vm._v(
                                        "The selected property has no documents to import."
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _c(
                "v-card-actions",
                { staticClass: "pb-3 pr-3 pt-0 justify-center" },
                [
                  _vm.step == 1
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "full-width",
                          attrs: {
                            depressed: "",
                            color: "primary darken-1",
                            dark: "",
                            disabled: _vm.isDisabled(),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.uploadFile()
                            },
                          },
                        },
                        [
                          !_vm.isConnecting
                            ? _c("span", [_vm._v("Continue")])
                            : _vm._e(),
                          _vm.isConnecting
                            ? _c("v-progress-circular", {
                                attrs: {
                                  size: 25,
                                  width: 3,
                                  color: "white accent-4",
                                  indeterminate: "",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.step == 2
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "full-width",
                          attrs: {
                            depressed: "",
                            color: "primary darken-1",
                            dark: "",
                            disabled: _vm.isDisabled(),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.importDoc()
                            },
                          },
                        },
                        [
                          !_vm.isConnecting
                            ? _c("span", [_vm._v("Import")])
                            : _vm._e(),
                          _vm.isConnecting
                            ? _c("v-progress-circular", {
                                attrs: {
                                  size: 25,
                                  width: 3,
                                  color: "white accent-4",
                                  indeterminate: "",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }