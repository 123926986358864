<template>
    <div>
        <v-dialog persistent v-model="displayModule" max-width="450">
            <v-card class="pa-3 relative">
                <div class="close-icon pr-3">
                    <v-icon class="pointer" @click="closeDialog()">close</v-icon>
                </div>
                <v-card-title class="headline pt-5 pb-0 flex-column">
                    <div class="d-flex justify-center full-width">
                        <v-icon class="material-icons ask-icon-title">help_outline</v-icon>
                    </div>
                    <div class="pre-formatted d-flex justify-center full-width px-5 my-3">
                        <span class="header-title" v-html="message"></span>
                    </div>
                </v-card-title>
                <v-card-actions class="mt-3 pb-3 pr-3 pt-0 justify-space-around">
                    <v-btn depressed color="error" dark @click="confirm(false)">NO</v-btn>
                    <v-btn depressed color="success" dark @click="confirm(true)">YES</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'ConfirmAction',
    data() {
        return {
            message: '',
        }
    },
    props: {
        displayModule: {
            type: Boolean,
            default: false
        },
        customMsg: {
            type: Object
        }
    },
    methods: {
        closeDialog() {
            this.$emit('closeDialog');
        },
        confirm(answer) {
            this.$emit('confirmAction', answer);
        },
        initialize(){
            this.message = '';
        },
    },
    watch: {
        displayModule: function(){
            if(this.displayModule){
                const { message, title } = this.customMsg;
                this.message = message ? message : '';
                this.title = title ? title : '';
            } else {
                this.initialize();
            }
        }
    },
}
</script>

<style>
.ask-icon-title {
    font-size: 70px;
    color: #fb8c00 !important;
}
.pre-formatted {
  white-space: pre;
}
</style>
