<template>
    <div>
        <div class="d-flex justify-center full-width my-3">
            <v-text-field
                v-model="propertyId"
                label="Search by Property ID"
                outline
                append-icon="search"
                @keyup.enter="searchProperties()"
                @click:append="searchProperties()"
            ></v-text-field>
        </div>
        <div v-if="displayResults" class="d-flex justify-center full-width mb-3">
            <DataTable :columns="headers"
                       :elements="searchResults.properties"
                       :state="tableState"
                       @changePage="changePage"
                       @itemSelected="propertyChosen">
            </DataTable>
        </div>
    </div>
</template>

<script>
    import DataTable from "./DataTable";
    import axios from "axios";

    export default {
        name: 'SearchProperty',
        data() {
            return {
                propertyId: '',
                headers: [
                    {
                        text: "Property Id",
                        align: 'center',
                        sortable: false,
                        value: 'id'
                    },
                    {
                        text: "Property Name",
                        align: 'center',
                        sortable: false,
                        value: 'name'
                    },
                    {
                        text: "",
                        align: 'center',
                        sortable: false,
                        value: 'actions'
                    },
                ],
                searchResults: {},
                displayResults: false,
                tableState: {}
            }
        },
        components: {
            DataTable
        },
        props: {
            apiBaseUrl: {
                required: true
            }
        },
        methods: {
            searchProperties(page = 1, size = 5){
                let self = this;
                self.$emit("makeSearch");
                self.displayResults = false;
                const headers = {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.token
                    }
                };
                let params = '?page=' + (page-1) + '&size=' + size;
                if(this.propertyId) {
                    if (this.propertyId == parseInt(this.propertyId, 10)) {
                        params += '&id=' + this.propertyId;
                    } else {
                        params += '&name=' + this.propertyId;
                    }
                }
                axios.get(self.apiBaseUrl + 'codingtool/properties' + params, headers).then((response) => {
                    if(response.data){
                        self.searchResults = response.data;
                        self.tableState = {
                            totalItems: self.searchResults.totalItems,
                            currentPage: self.searchResults.currentPage+1,
                            pageSize: self.searchResults.totalPage,
                            totalPages: self.searchResults.totalPage
                        }
                        self.displayResults = true;
                    }
                }).catch(e => {

                });
            },
            changePage(page){
                this.searchProperties(page);
            },
            propertyChosen(item){
                this.$emit('itemSelected', item);
            },
            clearSearch(){
                this.propertyId = '';
                this.searchResults = {};
                this.displayResults = false;
                this.tableState = {};
            }
        }
    }
</script>
