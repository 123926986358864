var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "450" },
          model: {
            value: _vm.displayModule,
            callback: function ($$v) {
              _vm.displayModule = $$v
            },
            expression: "displayModule",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-3 relative" },
            [
              _c(
                "div",
                { staticClass: "close-icon pr-3" },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "pointer",
                      on: {
                        click: function ($event) {
                          return _vm.closeDialog()
                        },
                      },
                    },
                    [_vm._v("close")]
                  ),
                ],
                1
              ),
              _c(
                "v-card-title",
                { staticClass: "headline pt-5 pb-0 flex-column" },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-center full-width" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "material-icons confirm-icon-title" },
                        [_vm._v("check_circle")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex justify-center full-width px-5 my-3",
                    },
                    [
                      _c("div", { staticClass: "info-title" }, [
                        _vm._v(_vm._s(_vm.title)),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex justify-center full-width px-5 my-3",
                    },
                    [
                      _c("span", { staticClass: "header-title" }, [
                        _vm._v(_vm._s(_vm.message)),
                      ]),
                    ]
                  ),
                ]
              ),
              _c(
                "v-card-actions",
                { staticClass: "mt-3 pb-3 pr-3 pt-0 justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "full-width",
                      attrs: {
                        depressed: "",
                        color: "primary darken-1",
                        dark: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialog()
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }