var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "450" },
          model: {
            value: _vm.displayModule,
            callback: function ($$v) {
              _vm.displayModule = $$v
            },
            expression: "displayModule",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-3 relative" },
            [
              _c(
                "div",
                { staticClass: "close-icon pr-3" },
                [
                  !_vm.isConnecting
                    ? _c(
                        "v-icon",
                        {
                          staticClass: "pointer",
                          on: {
                            click: function ($event) {
                              return _vm.closeDialog()
                            },
                          },
                        },
                        [_vm._v("close")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-card-title",
                { staticClass: "headline pt-5 pb-0 flex-column" },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-center full-width" },
                    [
                      _c("img", {
                        staticClass: "property-icon",
                        attrs: { src: require("../assets/building.svg") },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex justify-center full-width px-5 my-3",
                    },
                    [
                      _c("span", { staticClass: "import-instructions-text" }, [
                        _vm._v("Please name your document before download it"),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-center full-width my-1" },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Filename", outline: "" },
                        model: {
                          value: _vm.filename,
                          callback: function ($$v) {
                            _vm.filename = $$v
                          },
                          expression: "filename",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "v-card-actions",
                { staticClass: "mt-3 pb-3 pr-3 pt-0 justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "full-width",
                      attrs: {
                        depressed: "",
                        color: "primary darken-1",
                        dark: "",
                        disabled: _vm.isDisabled(),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.confirmDownload()
                        },
                      },
                    },
                    [
                      !_vm.isConnecting
                        ? _c("span", [_vm._v("Download Document")])
                        : _vm._e(),
                      _vm.isConnecting
                        ? _c("v-progress-circular", {
                            attrs: {
                              size: 25,
                              width: 3,
                              color: "white accent-4",
                              indeterminate: "",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }