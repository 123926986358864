<template>
    <div>
        <v-dialog persistent v-model="displayModule" max-width="450">
            <v-card class="pa-3 relative">
                <div class="close-icon pr-3">
                    <v-icon class="pointer" @click="closeDialog()">close</v-icon>
                </div>
                <v-card-title class="headline pt-5 pb-0 flex-column">
                    <div class="d-flex justify-center full-width">
                        <v-icon class="material-icons confirm-icon-title">check_circle</v-icon>
                    </div>
                    <div class="d-flex justify-center full-width px-5 my-3">
                        <div class="info-title">{{title}}</div>
                    </div>
                    <div class="d-flex justify-center full-width px-5 my-3">
                        <span class="header-title">{{message}}</span>
                    </div>
                </v-card-title>
                <v-card-actions class="mt-3 pb-3 pr-3 pt-0 justify-center">
                    <v-btn depressed class="full-width" color="primary darken-1" dark @click="closeDialog()">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'InfoMessage',
    data() {
        return {
            message: '',
            title: ''
        }
    },
    props: {
        displayModule: {
            type: Boolean,
            default: false
        },
        customMsg: {
            type: Object
        }
    },
    methods: {
        closeDialog() {
            this.$emit('closeDialog');
        },
        initialize(){
            this.message = '';
            this.title = '';
        },
    },
    watch: {
        displayModule: function(){
            if(this.displayModule){
                const { message, title } = this.customMsg;
                this.message = message ? message : '';
                this.title = title ? title : '';
            } else {
                this.initialize();
            }
        }
    },
}
</script>

<style>
    .confirm-icon-title {
        font-size: 70px;
        color: #8bc53f !important;
    }

    .info-title {
        text-align: center;
        color: #2B388F;
        font-size: 25px;
    }

</style>