import { ALIGN_ACTIONS, MATCH_ACTIONS } from '@/libs/constants'
const konvatools = {
    methods: {
        addToStack(action, element) {
            let elemData;
            if (this.actionsStack.length == 10) {
                this.actionsStack.splice(0, 1);
            }
            if(action == 'add'){
                let elements = [];
                for (let i = 0; i < element.length; i++) {
                    elements.push({
                        identifier: element[i],
                        page: this.selectedLabel.getAttrs().page
                    });
                }
                elemData = {
                    action: action,
                    elements: elements,
                };
                this.actionsStack.push(elemData);
            } else if(action == 'delete' || action == 'cut') {
                elemData = {
                    action: action,
                    elements: element,
                };
                this.actionsStack.push(elemData);
            } else if(action == 'transfer'){
                elemData = {
                    action: action,
                    elements: element,
                    from: this.transferLabel.from,
                    to: this.transferLabel.to
                };
                this.actionsStack.push(elemData);
            } else if(action == 'edit'){
                elemData = {
                    action: action,
                    elements: element,
                };
                this.actionsStack.push(elemData);
            } else if(action == 'resize'){
                elemData = {
                    action: action,
                    elements: element,
                };
                this.actionsStack.push(elemData);
            } else if(action == 'order') {
                elemData = {
                    action: action,
                    pages: element
                };
                this.actionsStack.push(elemData);
            } else if(action == 'deletePage') {
                elemData = {
                    action: action,
                    attributes: element
                };
                this.actionsStack.push(elemData);
            }
            this.storeChanges();
        },
        askConfirmation(submitData){
            this.showSubmitDialog = !this.showSubmitDialog;
            this.customMessage = {
                'message': 'Are you sure do you want to submit the current document?'
            };
            this.showConfirmDialog = true;
            this.submitData = submitData;
        },
        /*checkIfDisableSubmit(url) {
            let disable = false;
            if(this.actionsStack.length > 0) {
                disable = true;
            } else if(this.isOriginalDocument(url)) {
                disable = true;
            }
            return disable;
        },*/
        checkifPointInsideShape(x,y,selectionElement){
            let inside = false;
            if((selectionElement.x < x) && (x < (selectionElement.x + selectionElement.width)) &&
                (selectionElement.y < y) && (y < (selectionElement.y + selectionElement.height))){
                inside = true;
            }
            return inside;
        },
        checkIfSelectedElement(minHeight, maxHeight, selectionElement, analizedElement) {
            let selected = false;
            let x, y;
            if(minHeight <= analizedElement.y && analizedElement.y < maxHeight){
                for(let i = 0; i < 2 && !selected; i++){
                    x = analizedElement.x + (i*analizedElement.width);
                    y = analizedElement.y + (i*analizedElement.height);
                    if(this.checkifPointInsideShape(x, y, selectionElement)){
                        selected = true;
                    }
                }
            }
            return selected;
        },
        checkPointerPosition(pointerPos){
            if(pointerPos.x < 0){
                pointerPos.x = 0;
            } else if(pointerPos.x > this.stage.width()){
                pointerPos.x = this.stage.width();
            }
            if(pointerPos.y < 0){
                pointerPos.y = 0;
            } else if(pointerPos.y > this.stage.height()){
                pointerPos.y = this.stage.height();
            }
            return pointerPos;
        },
        closeStartDialog(){
            this.showStartModule = !this.showStartModule;
            this.startDialogKey = new Date().getTime();
        },
        collectDownloadedFile(filename, file){
            const blob = this.b64toBlob(file, 'application/pdf');
            const e = document.createEvent('MouseEvents'),
                a = document.createElement('a');
            a.download = filename + '.pdf';
            a.href = window.URL.createObjectURL(blob);
            a.dataset.downloadurl = ['application/pdf', a.download, a.href].join(':');
            e.initEvent(
                'click',
                true,
                false,
                window,
                0,
                0,
                0,
                0,
                0,
                false,
                false,
                false,
                false,
                0,
                null
            );
            a.dispatchEvent(e);
        },
        confirmSubmitApp(confirm){
            this.showConfirmDialog = false;
            if(confirm){
                this.customMessage = {
                    title: "Thank you",
                    message: "The document has been saved and assigned successfully"
                };
                this.showInfoMessage = true;
            }
        },
        confirmTransfer(){
            this.cutElement('transfer');
            this.pasteElement(parseInt(this.transferLabel.to), null);
            this.transferLabelsDialog = false;
            this.transferLabel = {
                from: '',
                to: ''
            };
        },
        copyElement(){
            this.clipboard = [];
            if(!this.multiselect.length){
                this.clipboard.push({...this.selectedLabel.getAttrs(), clipboard: true});
            } else {
                for(let i = 0; i < this.multiselect.length; i++){
                    let label = this.getSelectedLabel(this.multiselect[i]);
                    this.clipboard.push({...label.getAttrs(), clipboard: true});
                }
            }
        },
        cutElement(action = 'cut'){
            this.clipboard = [];
            let page, attrs;
            let cutElem = [];
            if(!this.multiselect.length){
                attrs = {...this.selectedLabel.getAttrs()};
                page = attrs.page;
                this.clipboard.push({...attrs, clipboard: true});
                cutElem.push(attrs);
                this.layer.children.splice(this.selectedIndex, 2);
            } else {
                for(let i = 0; i < this.multiselect.length; i++){
                    let label = this.getSelectedLabel(this.multiselect[i]);
                    attrs = {...label.getAttrs()};
                    this.clipboard.push({...attrs, clipboard: true});
                    if(!page){
                        page = attrs.page;
                    }
                    let index = this.getSelectedIndex(this.multiselect[i]);
                    this.layer.children.splice(index, 2);
                    cutElem.push(attrs);
                }
            }
            this.layer.draw();
            this.calculateInputsInSpecificPage(page);
            this.addToStack(action, cutElem);
        },
        deleteInput() {
            let page;
            let deletedElem = [];
            if(!this.multiselect.length) {
                page = this.selectedLabel.getAttrs().page;
                deletedElem.push(this.selectedLabel.getAttrs());
                this.selectedLabel = null;
                this.layer.children.splice(this.selectedIndex, 2);
            } else {
                for(let i = 0; i < this.multiselect.length; i++){
                    let label = this.getSelectedLabel(this.multiselect[i]);
                    let attrs = label.getAttrs();
                    if(!page){
                        page = attrs.page;
                    }
                    let index = this.getSelectedIndex(this.multiselect[i]);
                    deletedElem.push(attrs);
                    this.layer.children.splice(index, 2);
                }
            }
            this.layer.draw();
            this.calculateInputsInSpecificPage(page);
            this.releaseSelected();
            this.addToStack('delete', deletedElem);
        },
        destroyKonva(){
            for(let i = 0; i < this.stageArray.length; i++){
                this.stageArray[i].destroy();
            }
            if(this.layer != null) {
                this.layer.remove();
            }
        },
        downloadConfirmed() {
            this.showDownloadDialog = false;
            this.customMessage = {
                title: "Thank you",
                message: "File has been downloaded successfully!"
            };
            this.showInfoMessage = true;
        },
        duplicateInput() {
            let attrs, option, createdId;
            if(!this.multiselect.length){
                attrs = this.layer.children[this.selectedIndex].getAttrs();
                if(attrs.type == "Radio"){
                    option = {
                        value: attrs.content,
                    };
                }
                createdId = new Date().getTime();
                this.createField({
                    identifier: createdId,
                    makeSelected: true,
                    isFieldNameSelected: attrs.name.trim().length == 0 ? false : true,
                    isRequired: attrs.required,
                    isReadonly: attrs.readOnly,
                    id: attrs.id,
                    name: attrs.name,
                    type: attrs.type,
                    option: option,
                    withoutIcon: true,
                    isCustom: attrs.custom,
                    x: attrs.x + 20,
                    y: attrs.y + 40,
                    width: attrs.width,
                    height: attrs.height,
                    numpage: attrs.page,
                    fontSize: attrs.fontSize
                });
                this.addToStack('add', [createdId]);
            } else {
                createdId = [];
                let newId;
                this.multiselect.forEach((identifier) => {
                    let index = this.getSelectedIndex(identifier);
                    attrs = this.layer.children[index].getAttrs();
                    if(attrs.type == "Radio"){
                        option = {
                            value: attrs.content,
                        };
                    }
                    newId = new Date().getTime();
                    this.createField({
                        identifier: newId,
                        makeSelected: true,
                        isFieldNameSelected: attrs.name.trim().length == 0 ? false : true,
                        isRequired: attrs.required,
                        isReadonly: attrs.readOnly,
                        id: attrs.id,
                        name: attrs.name,
                        type: attrs.type,
                        option: option,
                        withoutIcon: true,
                        isCustom: attrs.custom,
                        x: attrs.x + 20,
                        y: attrs.y + 40,
                        width: attrs.width,
                        height: attrs.height,
                        numpage: attrs.page,
                        fontSize: attrs.fontSize
                    });
                    createdId.push(newId);
                });
                if(createdId.length > 0){
                    this.addToStack('add', createdId);
                }
            }
            this.calculateInputsInSpecificPage(attrs.page);
        },
        getCursorPosition(evt) {
            let pointerPos = evt.target.pointerPos || this.stage.getPointerPosition();
            if(pointerPos && pointerPos.x && pointerPos.y){
                pointerPos = this.checkPointerPosition(pointerPos);
                this.cursorPosition = [pointerPos.x,pointerPos.y];
                if(this.selectMode){
                    this.refreshSelectedArea();
                }
            }
        },
        keepBoxInTheStageAtDrag(attrs){
            let corrected = false;
            if(attrs.x < 0){
                attrs.x = 0;
                corrected = true;
            } else if((attrs.x + attrs.width) > this.layer.parent.width()){
                attrs.x = this.layer.parent.width() - attrs.width;
                corrected = true;
            }
            if(attrs.y < 0){
                attrs.y = 0;
                corrected = true;
            } else if((attrs.y + attrs.height) > this.layer.parent.height()){
                attrs.y = this.layer.parent.height() - attrs.height;
                corrected = true;
            }
            return {
                attrs: attrs,
                corrected: corrected
            };
        },
        keepBoxInTheStageAtResize(attrs){
            let corrected = false;
            if(attrs.x < 0){
                attrs.x = 0;
                corrected = true;
            } else if((attrs.x + attrs.width) > this.layer.parent.width()){
                attrs.width = this.layer.parent.width() - attrs.x;
                corrected = true;
            }
            if(attrs.y < 0){
                attrs.y = 0;
                corrected = true;
            } else if((attrs.y + attrs.height) > this.layer.parent.height()){
                attrs.height = this.layer.parent.height() - attrs.y;
                corrected = true;
            }
            return {
                attrs: attrs,
                corrected: corrected
            };
        },
        isComplianceRole(user){
            let isCompliance = false;
            if(user && user.auth) {
                isCompliance = user.auth.split(',').includes('ROLE_L_CM');
            }
            return isCompliance;
        },
        isOriginalDocument(url){
            let isOrignal = false;
            const chain = url.match(new RegExp(/working-pdf\/(.*?)_original.pdf\?X-Amz-/, 'g'));
            if(chain) {
                isOrignal = true;
            }
            return isOrignal;
        },
        pasteElement(page = null, action = 'add'){
            let createdId = [];
            let newId;
            if(!page){
                page = this.page;
            }
            for(let i = 0; i < this.clipboard.length; i++) {
                newId = action == null ? this.clipboard[i].identifier : new Date().getTime();
                this.createField({
                    identifier: newId,
                    makeSelected: true,
                    isFieldNameSelected: true,
                    isRequired: this.clipboard[i].required,
                    isReadonly: this.clipboard[i].readOnly,
                    id: this.clipboard[i].name,
                    name: this.clipboard[i].name,
                    type: this.clipboard[i].type,
                    option: null,
                    withoutIcon: true,
                    isCustom: this.clipboard[i].custom,
                    x: this.clipboard[i].x,
                    y: this.clipboard[i].y,
                    width: this.clipboard[i].width,
                    height: this.clipboard[i].height,
                    numpage: page,
                    fontSize: this.clipboard[i].fontSize
                });
                createdId.push(newId);
            }
            this.calculateInputsInSpecificPage(page);
            if(action){
                this.addToStack(action, createdId);
            }
        },
        positionAction(action){
            const focusedElem = this.getSelectedLabel(this.focusedElement);
            const attrs = focusedElem.getAttrs();
            if(this.multiselectLabelIndex.length == 0){
                this.getMultiSelectedLabels();
            }
            let modifiedElem = [];
            this.multiselectLabelIndex.forEach((labelIndex) => {
                let att = {...this.layer.children[labelIndex].getAttrs()};
                if(att.identifier != this.focusedElement){
                    modifiedElem.push(att);
                }
            });
            this.addToStack('edit', modifiedElem);
            if(action == ALIGN_ACTIONS.ALIGN_TOP){
                this.multiselectLabelIndex.forEach((labelIndex) => {
                    let att = this.layer.children[labelIndex].getAttrs();
                    if(att.identifier != this.focusedElement){
                        this.layer.children[labelIndex].setAttrs({
                            y: attrs.y,
                        });
                    }
                });
                this.layer.draw();
            } else if(action == ALIGN_ACTIONS.ALIGN_BOTTOM){
                const bottomPoint = attrs.y + attrs.height;
                this.multiselectLabelIndex.forEach((labelIndex) => {
                    let att = this.layer.children[labelIndex].getAttrs();
                    if(att.identifier != this.focusedElement){
                        this.layer.children[labelIndex].setAttrs({
                            y: bottomPoint - att.height,
                        });
                    }
                });
                this.layer.draw();
            } else if(action == ALIGN_ACTIONS.ALIGN_CENTER_HORIZONTALLY) {
                const centerY = attrs.y + (attrs.height/2);
                this.multiselectLabelIndex.forEach((labelIndex) => {
                    let att = this.layer.children[labelIndex].getAttrs();
                    if(att.identifier != this.focusedElement){
                        this.layer.children[labelIndex].setAttrs({
                            y: centerY - (att.height/2),
                        });
                    }
                });
                this.layer.draw();
            } else if(action == ALIGN_ACTIONS.ALIGN_CENTER_VERTICALLY) {
                const centerX = attrs.x + (attrs.width/2);
                this.multiselectLabelIndex.forEach((labelIndex) => {
                    let att = this.layer.children[labelIndex].getAttrs();
                    if(att.identifier != this.focusedElement){
                        this.layer.children[labelIndex].setAttrs({
                            x: centerX - (att.width/2),
                        });
                    }
                });
                this.layer.draw();
            } else if(action == MATCH_ACTIONS.MATCH_BOX || action == MATCH_ACTIONS.MATCH_WIDTH || action == MATCH_ACTIONS.MATCH_HEIGHT) {
                let params = {};
                if (action == MATCH_ACTIONS.MATCH_WIDTH) {
                    params = {
                        width: attrs.width,
                    };
                } else if (action == MATCH_ACTIONS.MATCH_HEIGHT) {
                    params = {
                        height: attrs.height,
                    };
                } else {
                    params = {
                        width: attrs.width,
                        height: attrs.height
                    };
                }
                this.multiselectLabelIndex.forEach((labelIndex) => {
                    let att = this.layer.children[labelIndex].getAttrs();
                    if (att.identifier != this.focusedElement) {
                        this.layer.children[labelIndex].setAttrs(params);
                        this.layer.children[labelIndex].children[0].setAttrs(params);
                        if(action == MATCH_ACTIONS.MATCH_BOX || action == MATCH_ACTIONS.MATCH_WIDTH){
                            this.layer.children[labelIndex].children[1].setAttrs({
                                width: attrs.width
                            });
                        }
                        this.layer.children[labelIndex+1].forceUpdate();
                    }
                });
                this.layer.draw();
            }
        },
        refreshSelectedArea(){
            let element = this.selectionArea;
            const attributes = element.getAttrs();
            let selectedIndex = this.getSelectedIndex(attributes.identifier);
            let area = this.layer.children[selectedIndex];
            const width = this.cursorPosition[0] - attributes.x;
            const height = this.cursorPosition[1] - attributes.y;
            area.width(width);
            area.height(height);
            this.layer.draw();
        },
        restorePage(attrs){
            const valuesArr = this.trashcan.splice(attrs.trashIdx, 1);
            const values = valuesArr[0];
            const pdfPages = document.querySelector('#'+ this.pdfContainerId);
            const pdfPage = pdfPages.children[attrs.order];
            pdfPages.insertBefore(values.pdfPage, pdfPage);
            const konvaStages = document.querySelector('#fields-container');
            const konvaStage = konvaStages.children[attrs.order];
            konvaStages.insertBefore(values.konvaST, konvaStage);
            this.stageArray.splice(attrs.order, 0, values.stage);
            this.stageArray[attrs.order].children[0].draw();
            this.pages.splice(attrs.order, 0, values.pageSettings);
            this.orderPagesArray = [];
            for(let i = 0; i < values.orderPagesArray.length; i++){
                this.orderPagesArray.push(values.orderPagesArray[i])
            }
            const lmkeyPos = this.leftMenuKeys.indexOf(values.lmkey);
            if(lmkeyPos > -1){
                const aux = this.leftMenuKeys.splice(lmkeyPos, 1);
                this.leftMenuKeys.splice(attrs.order, 0, aux[0]);
            }
            this.numPages += 1;
            this.updateKonvaStageOrder();
        },
        selectElementInsideTheArea(){
            const clientSelectionArea = this.selectionArea.getClientRect();
            const minHeight = 0;
            const maxHeight = this.pages[this.page-1].height;
            let attrs, clientRect, selectedElement;
            let selectedIndexArray = [];
            if(this.selectedIndex != -1){
                this.focusedElement = this.selectedIdentifier;
                this.layer.children[this.selectedIndex+1].setAttrs({
                    borderEnabled: true,
                    borderStroke: this.focusedBorderColor,
                    borderStrokeWidth: 2
                });
            }

            for(let i = 0; i < this.layer.children.length; i++){
                attrs = this.layer.children[i].getAttrs();
                if(this.layer.children[i].className == 'Label'){
                    clientRect = this.layer.children[i].getClientRect();
                    selectedElement = this.checkIfSelectedElement(minHeight, maxHeight, clientSelectionArea, clientRect);
                    if(selectedElement && this.multiselect.indexOf(attrs.identifier) == -1){
                        if(this.selectedIndex == -1){
                            this.setSelected(attrs.identifier);
                            this.focusedElement = attrs.identifier;
                            this.layer.children[i+1].setAttrs({
                                borderEnabled: true,
                                borderStroke: this.focusedBorderColor,
                                borderStrokeWidth: 2
                            });
                        }
                        this.multiselect.push(attrs.identifier);
                        this.layer.children[i+1].resizeEnabled(true);
                        selectedIndexArray.push(i);
                    }
                }
            }
        },
        undoAction(){
            this.releaseSelected();
            let aux = this.actionsStack.splice(this.actionsStack.length-1, 1);
            if(aux.length == 1) {
                aux = aux[0];
                if(aux.action == 'add'){
                    this.undoAddSubaction(aux);
                } else if(aux.action == 'delete' || aux.action == 'cut'){
                    this.undoPasteSubaction(aux);
                    if(aux.action == 'cut'){
                        this.clipboard = [];
                    }
                } else if(aux.action == 'transfer'){
                    this.undoAddSubaction(aux, aux.to-1);
                    this.undoPasteSubaction(aux, aux.from-1);
                } else if(aux.action == 'edit'){
                    this.undoEditLabel(aux);
                } else if(aux.action == 'resize'){
                    this.undoResizeLabel(aux);
                } else if(aux.action == 'order'){
                    this.orderPages(aux.pages.to, aux.pages.from, true);
                } else if(aux.action == 'deletePage') {
                    this.restorePage(aux.attributes);
                }
            }
            this.storeChanges();
        },
        undoAddSubaction(aux, page = null){
            let labelpage;
            for(let i = 0; i < aux.elements.length; i++){
                labelpage = page != null ? page : aux.elements[i].page-1;
                this.layer = this.stageArray[labelpage].getLayers()[0];
                let elementIndex = this.getSelectedIndex(aux.elements[i].identifier);
                this.layer.children.splice(elementIndex, 2);
            }
            this.layer.draw();
            this.calculateInputsInSpecificPage(labelpage);
        },
        undoPasteSubaction(aux, page = null){
            let labelpage;
            for(let i = 0; i < aux.elements.length; i++){
                labelpage = page != null ? page : aux.elements[i].page-1;
                this.layer = this.stageArray[labelpage].getLayers()[0];
                this.createField({
                    identifier: aux.elements[i].identifier,
                    makeSelected: false,
                    isFieldNameSelected: aux.elements[i].isFieldNameSelected,
                    isRequired: aux.elements[i].required || false,
                    isReadonly: aux.elements[i].readOnly,
                    id: aux.elements[i].id,
                    name: aux.elements[i].name,
                    type: aux.elements[i].type,
                    option: aux.elements[i].type == "Radio" ? {value: aux.elements[i].content} : null,
                    withoutIcon: true,
                    isCustom: aux.elements[i].custom,
                    x: aux.elements[i].x,
                    y: aux.elements[i].y,
                    width: aux.elements[i].width,
                    height: aux.elements[i].height,
                    numpage: aux.elements[i].page
                });
            }
            this.layer.draw();
            this.calculateInputsInSpecificPage(labelpage);
        },
        undoEditLabel(aux){
            let labelpage;
            for(let i = 0; i < aux.elements.length; i++){
                labelpage = aux.elements[i].page-1;
                this.layer = this.stageArray[labelpage].getLayers()[0];
                let elementIndex = this.getSelectedIndex(aux.elements[i].identifier);
                this.layer.children[elementIndex].setAttrs(aux.elements[i]);
                this.layer.children[elementIndex+1].setAttrs({
                    borderEnabled: aux.elements[i].required,
                    borderStroke: aux.elements[i].required ? this.requiredBorderColor : this.normalBorderColor
                });
                this.layer.children[elementIndex].children[0].setAttrs({
                    fill:
                        !aux.elements[i].custom
                            ? this.colorPropertySelected
                            : this.colorCustomPropertySelected
                });
                let size = this.fontSizeMap[aux.elements[i].fontSize];
                this.layer.children[elementIndex].children[1].setAttrs({
                    text: aux.elements[i].name,
                    fontSize: size
                });
            }
            this.layer.draw();
            this.calculateInputsInSpecificPage(labelpage);
        },
        undoResizeLabel(aux){
            let labelpage;
            for(let i = 0; i < aux.elements.length; i++){
                labelpage = aux.elements[i].page-1;
                this.layer = this.stageArray[labelpage].getLayers()[0];
                let elementIndex = this.getSelectedIndex(aux.elements[i].identifier);
                this.layer.children[elementIndex].setAttrs(aux.elements[i]);
                this.layer.children[elementIndex].children[0].setAttrs({
                    width: aux.elements[i].width,
                    height: aux.elements[i].height,
                    scaleX: 1
                });
                this.layer.children[elementIndex].children[1].setAttrs({
                    width: aux.elements[i].width,
                    scaleX: 1
                });
                this.layer.children[elementIndex+1].forceUpdate();
            }
            this.layer.draw();
            this.calculateInputsInSpecificPage(labelpage);
        },
        saveOldAttributesBeforeAction(element){
            let elementsAttrs = [];
            let attrs = element.getAttrs();
            if(this.multiselect.length > 0){
                this.multiselect.forEach((identifier) => {
                    let label = this.getSelectedLabel(identifier);
                    let lattrs = label.getAttrs();
                    elementsAttrs.push({...lattrs});
                });
            } else {
                //let copy = Object.assign({}, { ...attrs });
                const {x, y, width, height, page, identifier} = attrs;
                const copy = {
                    x: x,
                    y: y,
                    width: width,
                    height: height,
                    page: page,
                    identifier: identifier,
                    scaleX: 1
                }
                elementsAttrs.push(copy);
            }
            this.addToStack('resize', elementsAttrs);
        },

    }
}

export default konvatools;
