<template>
    <div class="text-center">
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    fab
                    small
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon class="font-icon">text_format</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-tile v-for="(item, index) in options"
                             :key="index"
                             class="options-list"
                             :class="{'font-selected': selected == item}"
                             @click="optionClicked(item)">
                    <v-list-tile-title>{{ item }}</v-list-tile-title>
                </v-list-tile>
            </v-list>
        </v-menu>
    </div>
</template>

<script>

    export default {
        name: 'FontSelector',
        props: {
            options: {
                required: true
            },
            selected: {
                required: true
            }
        },
        methods: {
            optionClicked: function (option) {
                this.$emit('fontSelected', option);
            }
        }
    }
</script>

<style scoped>

.options-list {
    cursor: pointer;
}

.font-selected {
    background-color: darkgrey;
}

.font-icon {
    font-size: 24px !important;
}

</style>