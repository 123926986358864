<template>
    <div class="right-side-scroll">
        <v-card class="my-2">
            <v-card-text>
                <v-layout row>
                    <v-flex xs3>
                        <div
                            class="property-color"
                            :style="{backgroundColor: labelAttributes.fill}"
                        ></div>
                    </v-flex>
                    <v-flex xs9>
                        <div>
                            <span v-if="elemAttributes.id.trim().length>0">
                                {{elemAttributes.id}}
                            </span>
                            <span v-else>No field selected</span>
                        </div>
                        <div>
                            <span>{{elemAttributes.type}}</span>
                        </div>
                        <div v-if="elemAttributes.type=='Radio'">
                            <span>Value: {{elemAttributes.content}}</span>
                        </div>
                    </v-flex>
                </v-layout>
                <!--<v-layout row pt-3 pl-1>
                    <v-flex xs12>
                        <span v-if="selectedProperty!=null">{{selectedProperty.comments}}</span>
                    </v-flex>
                </v-layout>-->
                <v-layout row pt-1>
                    <v-flex xs12>
                        <v-checkbox
                            @change="onRequiredChanged"
                            color="primary"
                            v-model="elemAttributes.required"
                            label="Required"
                        />
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex xs12>
                        <v-checkbox
                            @change="onReadOnlyChanged"
                            color="primary"
                            v-model="elemAttributes.readOnly"
                            label="Read-only"
                        />
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex xs12>
                        <v-select
                            @change="onFontSizeChanged"
                            :items="fontSizeValues"
                            v-model="elemAttributes.fontSize"
                            label="Font size"
                        />
                    </v-flex>
                </v-layout>
                <v-layout v-if="['Text', 'type'].indexOf(elemAttributes.type) != -1" row>
                    <v-flex xs12>
                        <v-select
                            @change="onTextTypeChanged"
                            :items="textFieldTypes"
                            v-model="elemAttributes.textType"
                            label="Text Type"
                        />
                    </v-flex>
                </v-layout>
                <v-layout v-show="['Text', 'type'].indexOf(elemAttributes.type) != -1" row>
                    <v-flex xs12>
                        <v-text-field
                            @change="onMaxLengthChanged"
                            v-model="elemAttributes.maxLength"
                            id="field-max-lenght"
                            label="Max Length"
                            mask="###"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout row pt-1>
                    <v-flex x12>
                        <v-layout row justify-space-between pt-1>
                            <v-icon @click="previousInput">keyboard_arrow_left</v-icon>
                            <v-icon @click="nextInput">keyboard_arrow_right</v-icon>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-card-text>

            <div>
                <v-layout row justify-space-around>
                    <v-btn
                        dark
                        fab
                        class="blue-grey lighten-2 fab-action-button"
                        @click="showCustomModal"
                        v-if="labelAttributes.fill != colorPropertySelected">
                        <v-icon class="font-icon">settings</v-icon>
                    </v-btn>
                    <v-btn dark fab @click="deleteInput" class="orange lighten-1 fab-action-button">
                        <v-icon class="font-icon">delete_outline</v-icon>
                    </v-btn>
                    <v-btn dark fab @click="displayTransferDialog" class="teal lighten-1 fab-action-button">
                        <v-icon class="font-icon">low_priority</v-icon>
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="white--text fab-action-button"
                        outline
                        fab
                        depressed
                        @click="duplicateInput">
                        <v-icon class="font-icon">content_copy</v-icon>
                    </v-btn>
                </v-layout>
            </div>

            <!--<v-card class="mb-2">
                <v-card-title class="px-2">
                    <span>Suggestions</span>
                </v-card-title>
                <v-card-text class="suggestions-holder">
                    <v-chip
                        @click="propertySelection(sugProp)"
                        v-for="(sugProp,propIndex) in propertiesMatches"
                        :key="propIndex"
                    >{{sugProp.name}}</v-chip>
                </v-card-text>
            </v-card>-->
        </v-card>
    </div>
</template>

<script>

    export default {
        name: 'ElementEditor',
        data() {
            return {
                elemAttributes: {},
                labelAttributes: {}
            }
        },
        props: {
            selectedElement: {
                required: true
            },
            selectedIdentifier: {
                required: true
            },
            colorPropertySelected: {
                required: true
            },
            fontSizeValues: {
                required: true
            },
            textFieldTypes: {
                required: true
            }
        },
        created() {
            this.elemAttributes = {...this.selectedElement.getAttrs()};
            this.labelAttributes = this.selectedElement.children[0].getAttrs();
        },
        methods: {
            onRequiredChanged(value){
                const params = {
                    attr: 'required',
                    value: value
                };
                this.$emit('onAttributeChanged', params);
            },
            onReadOnlyChanged(value){
                const params = {
                    attr: 'readOnly',
                    value: value
                };
                this.$emit('onAttributeChanged', params);
            },
            onFontSizeChanged(value){
                const params = {
                    attr: 'fontSize',
                    value: value
                };
                this.$emit('onAttributeChanged', params);
            },
            onTextTypeChanged(value){
                const params = {
                    attr: 'textType',
                    value: value
                };
                this.$emit('onAttributeChanged', params);
            },
            onMaxLengthChanged(value){
                const params = {
                    attr: 'maxLength',
                    value: value
                };
                this.$emit('onAttributeChanged', params);
            },
            previousInput(){
                this.$emit('previousInput');
            },
            nextInput(){
                this.$emit('nextInput');
            },
            showCustomModal(){
                this.$emit('showCustomModal');
            },
            deleteInput(){
                this.$emit('deleteInput');
            },
            duplicateInput(){
                this.$emit('duplicateInput');
            },
            displayTransferDialog() {
                this.$emit('displayTransferDialog');
            }
        },
        watch: {
            selectedIdentifier: function(){
                this.elemAttributes = this.selectedElement.getAttrs();
                this.labelAttributes = this.selectedElement.children[0].getAttrs();
            },
        },
    }

</script>