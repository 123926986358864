var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            color: "primary",
                            dark: "",
                            fab: "",
                            small: "",
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { staticClass: "font-icon" }, [
                        _vm._v("text_format"),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "v-list",
            _vm._l(_vm.options, function (item, index) {
              return _c(
                "v-list-tile",
                {
                  key: index,
                  staticClass: "options-list",
                  class: { "font-selected": _vm.selected == item },
                  on: {
                    click: function ($event) {
                      return _vm.optionClicked(item)
                    },
                  },
                },
                [_c("v-list-tile-title", [_vm._v(_vm._s(item))])],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }