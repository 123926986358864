<template>
  <v-app id="coding">
    <v-alert :value="displayAlert" type="success" class="alert-component white success--text" outline transition="fade-transition">{{ successMessage }}</v-alert>
    <v-alert :value="displayErrorAlert" type="error" class="alert-component white error--text" outline transition="fade-transition">{{ errorMessage }}</v-alert>
    <v-toolbar
      color="white"
      app
      dark
      fixed
      clipped-left
      clipped-right
      class="coding-toolbar elevation-0 relative"
    >
        <v-toolbar-side-icon @click="leftDrawer = !leftDrawer" class="white--text"></v-toolbar-side-icon>
        <v-tooltip bottom color="red darken-1">
            <template v-slot:activator="{ on }">
                <v-btn color="error" v-if="stageArray.length > 0" @click="showCustomModal" fab small v-on="on">
                    <v-icon class="font-icon">add</v-icon>
                </v-btn>
            </template>
            <span>Add Input</span>
        </v-tooltip>
        <v-tooltip bottom color="red darken-1">
            <template v-slot:activator="{ on }">
                <span v-on="on">
                    <FontSelector :options="fontSizeValues" :selected="settings.fontSize" @fontSelected="changeFontSelected" ></FontSelector>
                </span>
            </template>
            <span>Font size</span>
        </v-tooltip>
        <v-tooltip bottom color="red darken-1">
            <template v-slot:activator="{ on }">
                <v-btn color="warning"
                     dark
                     fab
                     small
                     :disabled="actionsStack.length == 0"
                     @click="undoAction()"
                     v-on="on"
                >
                  <v-icon class="font-icon">settings_backup_restore</v-icon>
                </v-btn>
            </template>
            <span>Undo</span>
        </v-tooltip>
        <v-tooltip bottom color="red darken-1">
            <template v-slot:activator="{ on }">
                <v-btn v-if="stageArray.length > 0"
                       color="teal"
                       dark
                       fab
                       small
                       @click="displayResetDialog = true"
                       v-on="on"
                >
                    <v-icon class="font-icon">autorenew</v-icon>
                </v-btn>
            </template>
            <span>Reset document</span>
        </v-tooltip>
        <v-tooltip bottom color="red darken-1">
            <template v-slot:activator="{ on }">
                <v-btn v-show="stageArray.length > 0" color="primary" @click="saveDocument()" fab small v-on="on">
                    <v-icon class="font-icon">save</v-icon>
                </v-btn>
            </template>
            <span>Save document</span>
        </v-tooltip>
        <v-tooltip bottom color="red darken-1">
            <template v-slot:activator="{ on }">
                <v-btn v-show="stageArray.length > 0" color="error" @click="removeFormPdfDialog = true" fab small v-on="on">
                    <v-icon class="font-icon">delete</v-icon>
                </v-btn>
            </template>
            <span>Delete forms</span>
        </v-tooltip>
        <div v-if="stageArray.length > 0" class="page-numeration">
            <h2 v-if="selectedPropertyId && selectedPropertyName">{{ selectedPropertyId }} - {{ selectedPropertyName }} - {{ selectedType }}</h2>
            <v-chip  class="orange darken-3">Page {{ page }} / {{ numPages }}</v-chip>
        </div>

      <v-spacer></v-spacer>

        <v-tooltip bottom color="red darken-1">
            <template v-slot:activator="{ on }">
                <v-btn
                v-if="!isLoading && stageArray.length > 0"
                color="primary"
                @click="openClosePdfWarning"
                fab
                small
                v-on="on"
                >
                  <v-icon class="font-icon">close</v-icon>
                </v-btn>
            </template>
            <span>Close document</span>
        </v-tooltip>

        <v-tooltip bottom color="red darken-1">
            <template v-slot:activator="{ on }">
                <v-btn v-show="stageArray.length == 0" color="indigo lighten-1" @click="showSearchDocumentsModule = true" fab small v-on="on">
                    <v-icon class="font-icon">search</v-icon>
                </v-btn>
            </template>
            <span>Pending documents</span>
        </v-tooltip>
        <v-tooltip bottom color="red darken-1">
            <template v-slot:activator="{ on }">
                <v-btn v-show="stageArray.length == 0" color="primary" @click="showStartModule = true" fab small v-on="on">
                    <v-icon class="font-icon">backup</v-icon>
                </v-btn>
            </template>
            <span>Upload/Import document</span>
        </v-tooltip>





      <input type="file" style="display: none" ref="file" @change="onFilePicked" />
        <v-tooltip bottom color="red darken-1">
            <template v-slot:activator="{ on }">
                <v-btn @click="showDownloadDialog = true" v-if="stageArray.length > 0" color="success" fab small v-on="on">
                    <v-icon class="font-icon">download</v-icon>
                </v-btn>
            </template>
            <span>Download document</span>
        </v-tooltip>
        <v-tooltip bottom color="red darken-1">
            <template v-slot:activator="{ on }">
                <span v-if="stageArray.length > 0" v-on="on">
                    <v-btn @click="confirmSubmitDialog" color="success" fab small :disabled="lockSubmit">
                        <v-icon class="font-icon">ios_share</v-icon>
                    </v-btn>
                </span>
            </template>
            <span>{{ lockSubmit ? 'Save document before submit' : 'Submit document'}}</span>
        </v-tooltip>
      <LogoutMenu v-if="currentUser" :currentUser="currentUser"></LogoutMenu>
    </v-toolbar>

    <v-navigation-drawer fixed clipped class="coding-left-drawer white" app v-model="leftDrawer">
      <v-tabs v-model="menuTabs">
        <v-tooltip bottom color="red darken-1">
            <template v-slot:activator="{ on, attrs }">
                <v-tab href="#menu-fields" v-bind="attrs" v-on="on">
                    <v-icon>pan_tool</v-icon>
                </v-tab>
            </template>
            <span>Fields</span>
        </v-tooltip>
        <v-tooltip bottom color="red darken-1">
            <template v-slot:activator="{ on, attrs }">
                <v-tab href="#menu-preview" v-bind="attrs" v-on="on">
                    <v-icon>grid_view</v-icon>
                </v-tab>
            </template>
            <span>Thumbnails</span>
        </v-tooltip>
        <v-tooltip bottom color="red darken-1">
            <template v-slot:activator="{ on, attrs }">
                <v-tab href="#menu-layers" v-bind="attrs" v-on="on">
                    <v-icon>layers</v-icon>
                </v-tab>
            </template>
            <span>Layers</span>
        </v-tooltip>
        <v-tooltip v-if="stageArray.length > 0" bottom color="red darken-1">
          <template v-slot:activator="{ on, attrs }">
              <v-tab href="#menu-upload" v-bind="attrs" v-on="on">
                  <v-icon>file_upload</v-icon>
              </v-tab>
          </template>
          <span>Upload</span>
        </v-tooltip>
        <v-tooltip v-if="stageArray.length > 0" bottom color="red darken-1">
          <template v-slot:activator="{ on, attrs }">
              <v-tab href="#menu-clipboard" v-bind="attrs" v-on="on">
                  <v-icon class="rotate-90">attachment</v-icon>
              </v-tab>
          </template>
          <span>Clipboard</span>
        </v-tooltip>
      </v-tabs>
      <div v-show="menuTabs == 'menu-fields'">
        <v-flex
          v-if="stageArray.length == 0"
          text-xs-center
          pa-4
        >Document not loaded yet.</v-flex>
        <v-flex x12 pa-2 class="grey lighten-2" v-if="stageArray.length > 0">
          <v-text-field
            v-model="searchProperty"
            label="Search for input fields"
            flat
            solo
            hide-details
            clearable
            clear-icon="mdi-close-circle-outline"
            @click="releaseSelected()"
          />
        </v-flex>

        <v-list v-if="stageArray.length > 0">
            <v-list-group :key="'sp'+index" v-for="(item, index) in filterStandardProperties">
                <template v-slot:activator>
                    <v-list-tile>
                        <v-list-tile-title>{{ item.name }}</v-list-tile-title>
                        <v-icon class="grey--text text--lighten-1 rotate-y-180" @click.stop="addFullCategoryToClipboard(item.children)">reply_all</v-icon>
                    </v-list-tile>
                </template>
                <v-list-tile
                    v-on:drag="drag(child, $event)"
                    v-on:dragstart="dragstart(child, $event)"
                    v-on:dragend="dragend(child, $event)"
                    v-on:dragenter="dragenter(child, $event)"
                    v-for="(child, i) in item.children"
                    :key="'c'+i"
                    @click.prevent="addToClipboardStr(child)"
                    class="grey lighten-3 grabbable"
                    :class="['px-1',selectedProperty != null && selectedProperty.name == item.name?'selected':'']"
                    draggable="true"
                >
                    <v-list-tile-action>
                        <v-icon class="grey--text text--lighten-1">drag_indicator</v-icon>
                    </v-list-tile-action>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-list-tile-title v-on="on">{{ child.name }}</v-list-tile-title>
                    </template>
                    <span>{{ child.comments }}</span>
                  </v-tooltip>

                </v-list-tile>
            </v-list-group>
            <v-list-group :key="'mtp'+index" v-for="(item, index) in filterMultiTabProperties">
                <template v-slot:activator>
                    <v-list-tile draggable="true">
                        <v-list-tile-title>{{ item.name }}</v-list-tile-title>
                    </v-list-tile>
                </template>
                <v-expansion-panel >
                    <v-expansion-panel-content v-for="(tabEl, idx) in item.options" :key="idx">
                        <template v-slot:header>
                            <div>{{ idx }}</div>
                            <v-icon class="grey--text text--lighten-1 rotate-y-180" @click.stop="addFullCategoryToClipboard(tabEl)">reply_all</v-icon>
                        </template>
                        <div>
                            <v-list-tile
                                v-on:drag="drag(subchild, $event)"
                                v-on:dragstart="dragstart(subchild, $event)"
                                v-on:dragend="dragend(subchild, $event)"
                                v-on:dragenter="dragenter(subchild, $event)"
                                v-for="(subchild, iiee) in tabEl"
                                :key="'sc'+iiee"
                                @click.prevent="addToClipboardStr(subchild)"
                                class="grey lighten-3 grabbable"
                                :class="['px-1',selectedProperty != null && selectedProperty.name == item.name?'selected':'']"
                                draggable="true"
                            >
                                <v-list-tile-action>
                                    <v-icon class="grey--text text--lighten-1">drag_indicator</v-icon>
                                </v-list-tile-action>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-list-tile-title v-on="on">{{ subchild.name }}</v-list-tile-title>
                                </template>
                                <span>{{ subchild.comments }}</span>
                              </v-tooltip>
                            </v-list-tile>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-list-group>
        </v-list>
      </div>
      <div v-show="menuTabs == 'menu-preview'">
            <v-flex
                v-if="stageArray.length == 0"
                text-xs-center
                pa-4>
                Document not loaded yet.
            </v-flex>
            <div class="preview-bg">
                <PreviewMenu
                    ref="leftmenucmp"
                    :selectedPage="page"
                    :sortableKeys="leftMenuKeys"
                    :pagesMap="orderPagesArray"
                    :key="documentKey"
                    @pageClicked="goToPageNumber"
                    @pageNumberUpdated="orderPages"
                    @pageDeleted="removeSelectedPage"
                    @pageSelected="addMarkedPage">
                </PreviewMenu>
            </div>
      </div>
      <div v-show="menuTabs == 'menu-layers'">
        <v-flex
          v-if="!inputsPerPage.filter(x=>x.children.length>0).length"
          text-xs-center
          pa-4
        >You have no input in the document.</v-flex>
        <v-treeview
          open-on-click
          item-key="id"
          item-text="name"
          :items="inputsPerPage"
          class="mb-2"
          transition
          flat
          v-if="inputsPerPage.filter(x=>x.children.length>0).length"
        >
          <template v-slot:prepend="{ item }">
            <div
              :class="[selectedIndex >= 0 && layer.children[selectedIndex].getAttrs().identifier == item.identifier?'selected':'']"
            >
              <v-icon :color="getHistoryInputsColor(item)">fiber_manual_record</v-icon>
            </div>
          </template>
          <template v-slot:label="{ item }">
            <div
              @click="gotoInput(item)"
              @dblclick="showCustomModal()"
              :class="[selectedIndex >= 0 && layer.children[selectedIndex].getAttrs().identifier == item.identifier?'selected orange--text darken-2':'']"
            >{{item.name}}</div>
          </template>
        </v-treeview>
      </div>
      <div v-show="menuTabs == 'menu-upload'">
            <v-flex class="upload-tab">
                <v-flex class="upload-left-menu dropzone" @dragover="docdragover" @dragleave="docdragleave" @drop="docdrop">
                    <div class="dropzone-info">
                        <span>Drag and Drop to upload files</span>
                    </div>
                          <input type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle"
                                 class="d-none" ref="file" accept=".pdf" :disabled="isLoading" />
                </v-flex>
                <v-flex class="uploaded-section">
                    <div v-if="uploadedFileList.length > 0" class="uploaded-file-list">
                        <span class="font-weight-bold">Uploaded Files:</span>
                        <v-list >
                            <v-list-tile
                                v-for="item in uploadedFileList"
                                :key="item.name"
                            >
                                <v-list-tile-content>
                                    <v-list-tile-title v-html="item.name"></v-list-tile-title>
                                </v-list-tile-content>
                                <v-list-tile-action>
                                    <v-icon class="warning--text" v-show="item.status == 'pending'">schedule</v-icon>
                                    <v-progress-circular v-show="item.status == 'processing'" :size="25" :width="3" color="blue accent-4" indeterminate></v-progress-circular>
                                    <v-icon class="success--text" v-show="item.status == 'completed'">done</v-icon>
                                    <v-icon class="red--text" v-show="item.status == 'error'">close</v-icon>
                                </v-list-tile-action>
                            </v-list-tile>
                        </v-list>
                    </div>
                </v-flex>
            </v-flex>
      </div>
      <div v-show="menuTabs == 'menu-clipboard'">
            <v-flex
                v-show="clipboard.length == 0"
                text-xs-center
                pa-4
            >There are no elements to be added.
            </v-flex>
            <v-flex v-show="clipboard.length > 0">
                <v-list-tile
                    v-for="(subchild, iddex) in clipboard"
                    v-on:drag="drag(subchild, $event)"
                    v-on:dragstart="dragstart(subchild, $event)"
                    v-on:dragend="dragend(subchild, $event, iddex)"
                    v-on:dragenter="dragenter(subchild, $event)"
                    :key="'sclip'+iddex"
                    @click.prevent="drag()"
                    class="grey lighten-3 grabbable"
                    :class="['px-1',selectedProperty != null && selectedProperty.name == subchild.name?'selected':'']"
                    draggable="true"
                >
                    <v-list-tile-action>
                        <v-icon class="grey--text text--lighten-1">drag_indicator</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-title>{{ subchild.name }}</v-list-tile-title>
                </v-list-tile>
            </v-flex>
      </div>
    </v-navigation-drawer>
    <v-navigation-drawer right clipped app v-model="rightDrawer" fixed class="coding-right-drawer">
      <v-toolbar color="white" class="elevation-0">
        <v-spacer></v-spacer>

        <v-btn @click="rightDrawer = !rightDrawer" flat color="grey" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <ElementEditor v-if="selectedIndex >= 0 && multiselect.length == 0"
                     :selectedElement="layer.children[selectedIndex]"
                     :selectedIdentifier="selectedIdentifier"
                     :colorPropertySelected="colorPropertySelected"
                     :fontSizeValues="fontSizeValues"
                     :textFieldTypes="textFieldTypes"
                     @showCustomModal="showCustomModal"
                     @onAttributeChanged="onAttributeChanged"
                     @previousInput="previousInput"
                     @nextInput="nextInput"
                     @deleteInput="deleteInput"
                     @duplicateInput="duplicateInput"
                     @displayTransferDialog="displayTransferDialog"
      >
      </ElementEditor>
      <MultiElementEditor v-if="selectedIndex >= 0 && multiselect.length > 0"
                       :layer="layer"
                       :selectedElementsArray="multiselect"
                       :fontSizeValues="fontSizeValues"
                       :textFieldTypes="textFieldTypes"
                       :forbiddenTypes="forbiddenTypes"
                       @onAttributeChanged="changeAttibuteMultipleObjects"
                       @deleteInput="deleteInput"
                       @duplicateInput="duplicateInput"
                       @displayTransferDialog="displayTransferDialog"
                       @positionAction="positionAction"
      >
      </MultiElementEditor>

    </v-navigation-drawer>

    <v-content app class="coding-wrap">
      <v-layout v-if="isLoading" column style="height:100%" justify-center align-center>
        <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
      </v-layout>
      <div v-show="!isLoading"
        class="main-container"
        :style="{height:fullHeight+'px' , display: isLoading ? 'none':'block'}"
      >
        <div id="pdf-container"></div>
        <div
          id="fields-container"
          v-on:dragover="dragover($event)"
          v-on:dragleave="dragleave($event)"
          @click.right.prevent="listenclick"
        ></div>
      </div>
    </v-content>

    <v-dialog persistent v-model="showDialog" width="800">
      <v-card>
        <v-toolbar color="tenant">
          <v-toolbar-title></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="showDialog=false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-5">
          <v-layout row wrap>
            <v-flex xs12>
              <v-text-field v-model="custom.name" label="Input name" required />
              <v-select
                :items="custom.inputTypes"
                v-model="custom.selectedInputType"
                label="Input Type"

                required
              />
              <v-text-field
                v-if="custom.selectedInputType == 'Radio' && !selectedIdentifier"
                v-model="custom.optionNumber"
                label="Number of options"
                mask="#"
                required
              />
              <v-text-field
                v-if="custom.selectedInputType == 'Radio'"
                v-model="custom.value"
                label="Value"
                required
              />
              <!--v-checkbox @change="onRequiredChanged" class="mt-0" v-model="custom.isRequired" label="Required" />
              <v-checkbox @change="onReadOnlyChanged" class="mt-0" v-model="custom.isReadonly" label="Read-only" /-->

              <v-btn color="primary"
                     @click="drawCustomInput"
                     :disabled="checkIfDoneIsDisabled()">
                  DONE
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="showDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="closePdfDialog" width="450">
      <v-card>
        <v-card-text class="pa-5">
          <h2 class="text-xs-center">Are you sure?</h2>
          <h4 class="text-xs-center">Do you want to close?</h4>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" flat @click="closePdfDialog = false">No</v-btn>
          <v-btn color="error" flat @click="closeSavedPdf">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="removeFormPdfDialog" width="450">
      <v-card>
        <v-card-text class="pa-5">
          <h2 class="text-xs-center">Are you sure?</h2>
          <h4 class="text-xs-center">Do you want to remove all forms page?</h4>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" flat @click="removeFormPdfDialog = false">No</v-btn>
          <v-btn color="error" flat @click="deleteForms">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="uploadPrevPdfDialog" width="450">
      <v-card>
        <v-card-text class="pa-5">
          <h2 class="text-xs-center">We found previous PDF coding was not closed</h2>
          <h3 class="text-xs-center">Do you want to continue coding?</h3>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" flat @click="closeSavedPdf">No</v-btn>
          <v-btn color="success" flat @click="uploadPrevPdf">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="transferLabelsDialog" width="450">
      <v-card>
          <v-form v-model="isValidTransferLabelForm">
              <v-card-text class="pa-5">
                  <h3 class="text-xs-center">Do you want to move the selected elements to another page?</h3>
                  <v-layout mt-5 row wrap class="justify-space-around">

                          <v-flex xs4>
                              <v-text-field label="Move from:" :value="transferLabel.from" disabled/>
                          </v-flex>
                          <v-flex xs4 v-if="transferLabelsDialog">
                              <v-text-field  v-model="transferLabel.to"
                                            label="Move to:"
                                            :rules="[transferRules.required, transferRules.gt_zero, transferRules.valid_page, transferRules.different_dest, transferRules.valid]"
                                            mask="###"/>
                          </v-flex>
                  </v-layout>
              </v-card-text>
          </v-form>
          <v-divider></v-divider>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" flat @click="transferLabelsDialog = false">No</v-btn>
              <v-btn color="success" flat @click="confirmTransfer" :disabled="!isValidTransferLabelForm">Yes</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
    <ResetDialog
        :displayDialog="displayResetDialog"
        @closeDialog="displayResetDialog = !displayResetDialog"
        @resetDocument="resetDocument"
    >
    </ResetDialog>
    <StartModule :displayModule="showStartModule"
                 :key="startDialogKey"
                 :stopLoading="stopLoadingDialog"
                 :apiBaseUrl="apiBaseUrl"
                 @closeDialog="closeStartDialog()"
                 @uploadFiles="uploadFiles"
                 @importFile="importFileFromProperty">
    </StartModule>
    <DownloadFile :displayModule="showDownloadDialog"
                  :stopLoading="stopLoadingDialog"
                  @closeDialog="showDownloadDialog = !showDownloadDialog"
                  @confirmDownload="downloadWorkingDocument">
    </DownloadFile>
    <InfoMessage :displayModule="showInfoMessage"
                 :customMsg="customMessage"
                 @closeDialog="showInfoMessage = !showInfoMessage">
    </InfoMessage>
    <SubmitModule :displayModule="showSubmitDialog"
                  :apiBaseUrl="apiBaseUrl"
                  @closeDialog="showSubmitDialog = !showSubmitDialog"
                  @submitDocument="askConfirmation"
                  @confirmSubmit="confirmSubmitApp">
    </SubmitModule>
    <ConfirmAction :displayModule="showConfirmDialog"
                   :customMsg="customMessage"
                   @confirmAction="compleSubmitDocument"
                   @closeDialog="showConfirmDialog = !showConfirmDialog">
    </ConfirmAction>
    <ConfirmAction :displayModule="showConfirmSubmitDialog"
                   :customMsg="customMessage"
                   @confirmAction="compleSubmitDocument"
                   @closeDialog="showConfirmSubmitDialog = !showConfirmSubmitDialog">
    </ConfirmAction>
    <SearchDocument :displayModule="showSearchDocumentsModule"
                    :apiBaseUrl="apiBaseUrl"
                     @itemSelected="getPendingDocumentDetails"
                     @closeDialog="showSearchDocumentsModule = !showSearchDocumentsModule">
    </SearchDocument>
    <div id="cloneNode" :class="draggingItem.custom ? 'dragable-custom' : ''">{{ draggingItem.name }}</div>
  </v-app>
</template>
<script>
import config from '@/config'

import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import { ACTION_TYPES } from '@/libs/constants'
import UnsavedChanges from '../../mixin/UnsavedChanges'
import DisableAutocomplete from '../../mixin/DisableAutocomplete'
let pdfjsLib = require('pdfjs-dist/build/pdf.js');
pdfjsLib.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/build/pdf.worker.entry');
import Konva from 'konva';
import Konvatools from '@/utils/coding/konvatools';
import properties from './properties.js';
import PreviewMenu from '@/components/PreviewMenu';
import FontSelector from '@/components/FontSelector';
import LogoutMenu from '@/components/LogoutMenu';
import ElementEditor from '@/components/ElementEditor';
import MultiElementEditor from '@/components/MultiElementEditor';
import StartModule from '@/components/StartModule';
import DownloadFile from '@/components/DownloadFile';
import InfoMessage from '@/components/InfoMessage';
import SubmitModule from '@/components/SubmitModule';
import ConfirmAction from '@/components/ConfirmAction';
import SearchDocument from '@/components/SearchDocument';
import ResetDialog from '@/components/ResetDialog';

function defaultCustom() {
  return {
    inputTypes: ['Text', 'Signature', 'Checkbox', 'Radio', 'Initials', 'Date'],
    selectedInputType: 'Text',
    isRequired: false,
    isReadonly: false,
    name: '',
    value: '',
    optionNumber: ''
  }
}

export default {
  data() {
    return {
        actionsStack: [],
        apiBaseUrl: '',
        leftDrawer: true,
        rightDrawer: false,

        menuTabs: null,
        subMenuTabs: [null, null, null],
        draggingItem: {
            name: null,
        },

        isLoading: false,
        showDialog: false,
        guideLineOffset: 5,
        clipboard: [],
        colorLabel: '#fbc91f',
        colorLabelOver: '#ff0000',
        colorLabelSelected: '#ff0000',
        colorPropertySelected: '#6cc070',
        colorCustomPropertySelected: '#2196F3',
        requiredBorderColor: 'red',
        normalBorderColor: '#2996f3',
        focusedBorderColor: '#FFC107',
        customMessage: {},
        fontSizeValues: ["Huge", "Big", "Normal", "Small", "Tiny"],
        textFieldTypes: ["Alphanumeric", "Integer", "Decimal"],
        focusedElement: null,
        forbiddenTypes: ['Date', 'Signature', 'Initials', 'Radio', 'Checkbox'],
        documentKey: null,
        displayAlert: false,
        displayErrorAlert: false,
        displayResetDialog: false,

        settings: {
            fontSize: "Normal",
            textType: "Alphanumeric"
        },
        fontSizeMap: {
            Huge: 18,
            Big: 16,
            Normal: 14,
            Small: 12,
            Tiny: 10
        },

        layer: null,
        leftMenuKeys: [],
        markedPages: [],
        stage: null,
        stageArray: [],
        documentQueue: [],
        uploadedFileList:[],

        pdfScale: 1.5,
        numPages: 0,
        orderPagesArray: [],
        page: 1,
        pages: [],
        pdfContainerId: 'pdf-container',
        ocrResult: [],
        ocrValueTypesResult: [],

        searchProperty: '',
        properties: properties,

        selectedType: '',
        selectedLabel: null,
        selectedIdentifier: null,
        selectedProperty: null,
        selectedTag: null,
        selectedText: null,
        selectedImage: null,
        selectedIndex: -1,
        selectMode: false,
        selectionArea: null,
        submitDisabled: true,
        successMessage: '',
        errorMessage: '',
        multiselect: [],
        multiselectLabelIndex: [],
        initialPosition: {},
        selectedPropertyName: null,
        selectedPropertyId: null,

        custom: defaultCustom(),

        historyDrawer: false,
        inputsPerPage: [],

        propertiesMatches: [],

        annotationLayer: [],
        annotations: [],
        showConfirmDialog: false,
        showConfirmSubmitDialog: false,
        showDownloadDialog: false,
        showInfoMessage: false,
        showSearchDocumentsModule: false,
        showStartModule: false,
        showSubmitDialog: false,
        stopLoadingDialog: [],
        submitData: null,
        startDialogKey: null,
        isValidTransferLabelForm: false,
        transferLabelsDialog: false,
        transferLabel: {
            from: '',
            to: ''
        },
        transferRules: {
            required: v => {
                if(!v) {
                    return 'This field is required';
                }
            },
            gt_zero: v => {
                if (v && parseInt(v) < 1) {
                    return 'Page can not be less than 1';
                }
            },
            valid_page: v => {
                if (v && parseInt(v) > this.numPages) {
                    return 'That page does not exists';
                }
            },
            different_dest: v => {
                if (v && parseInt(v) == this.transferLabel.from) {
                    return 'Destination page must be different';
                }
            },
            valid: v => {
                if (v && 1 <= v && v <= this.numPages) {
                    return true;
                }
            }
        },
        trashcan: [],
        pdfOriginal: null,

        closePdfDialog: false,
        removeFormPdfDialog: false,
        uploadPrevPdfDialog: false,
        prevPdf: false,

        textRactJSON: null,
    }
  },
  components: {
    //UploadButton,
    PreviewMenu,
    FontSelector,
    LogoutMenu,
    ElementEditor,
    MultiElementEditor,
    StartModule,
    DownloadFile,
    InfoMessage,
    SubmitModule,
    ConfirmAction,
    SearchDocument,
    ResetDialog
  },
  mixins: [DisableAutocomplete, UnsavedChanges, Konvatools],
  created: function() {
    if (!this.isComplianceRole(this.currentUser)) {
      return this.$router.push("/properties");
    }
    const self = this;
    window.addEventListener('keyup', (e) => {
        if (e.code == 'Backspace' || e.code == 'Delete') {
        // Supr or Del
            if ((self.selectedIndex >= 0 || self.multiselect.length > 0) && !self.isAnyMenuOpened(e.target)) {
                self.deleteInput();
            } else if(self.markedPages.length > 0 && !self.isAnyMenuOpened(e.target)){
                self.deleteMarkedPages();
            }
        }
        if((e.ctrlKey || e.metaKey) && e.code == 'KeyC' && (self.selectedIndex != -1 || self.multiselect.length > 0)){ //Ctrl+C
            self.copyElement();
        }
        if((e.ctrlKey || e.metaKey) && e.code == 'KeyX' && (self.selectedIndex != -1 || self.multiselect.length > 0)){ //Ctrl+X
            self.cutElement();
        }
        if((e.ctrlKey || e.metaKey) && e.code == 'KeyV' && self.clipboard.length > 0){ //Ctrl+V
            self.pasteElement();
        }
        if((e.ctrlKey || e.metaKey) && e.code == 'KeyZ' && self.actionsStack.length > 0){ //Ctrl+Z
            self.undoAction();
        }
    });
    self.startDialogKey = new Date().getTime();
    self.documentKey = new Date().getTime();
    self.apiBaseUrl = config.restUrl;
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
      pdfData: 'pdfData',
      pdfFile: 'pdfFile',
      pdfUrl: 'pdfUrl',
      pdfChanges: 'pdfChanges'
    }),
    fullHeight() {
      return window.innerHeight - 80
    },
    filterStandardProperties() {
        const _this = this;
        const standardProperties = this.properties.filter((property) => {
            return property.mode == 'standard';
        });
        if (this.searchProperty) {
            let filter = [];
            standardProperties.forEach(item => {
                let pattern = item.name.match(new RegExp(_this.searchProperty, 'gi')) != null ? '.+' : _this.searchProperty;
                let result = item.children.filter(ch => {
                    return ch.name.match(new RegExp(pattern, 'gi'));
                });
                if (result.length) {
                    let matchItem = {
                        name: item.name,
                        mode: item.mode,
                        children: result
                    };
                    filter.push(matchItem);
                }
            });
            return filter;
        }
        return standardProperties;
    },
    filterMultiTabProperties() {
        const self = this;
        const multiTabProperties = self.properties.filter((property) => {
            return property.mode == 'multitab';
        });
        if (self.searchProperty) {
            let filter = [];
            multiTabProperties.forEach(item => {
                let pattern = item.name.match(new RegExp(self.searchProperty, 'gi')) != null ? '.+' : self.searchProperty;
                let result = item.children.filter(ch => {
                    return ch.name.match(new RegExp(pattern, 'gi'));
                });
                let filteredOptions = {};
                for (let key in item.options) {
                    let resultOptions = item.options[key].filter(opt => {
                        return opt.name.match(new RegExp(pattern, 'gi'));
                    });
                    if (resultOptions.length) {
                        filteredOptions[key] = resultOptions;
                    }
                }
                if (result.length) {
                    let matchItem = {
                        name: item.name,
                        mode: item.mode,
                        pos: item.pos,
                        children: result,
                        options: filteredOptions
                    };
                    filter.push(matchItem);
                }
            });
            return filter;
        }
        return multiTabProperties;
    },
    lockSubmit() {
        return this.submitDisabled || this.actionsStack.length > 0;
    }
  },
  filters: {
    pretty: function(value) {
      return JSON.stringify(value, null, 2)
    },
  },
  methods: {
    ...mapActions({
      SET_PDF_FILE: ACTION_TYPES.PDF_FILE,
      SET_PDF_DATA: ACTION_TYPES.PDF_DATA,
      SET_PDF_URL: ACTION_TYPES.PDF_URL,
      SET_PDF_CHANGES: ACTION_TYPES.PDF_CHANGES,
      REMOVE_PDF_DATA: ACTION_TYPES.REMOVE_PDF_DATA,
    }),
    alertError(message) {
      this.$events.$emit("showSnackbar", {
        message: message,
        color: "red"
      });
    },
    uploadPrevPdf() {
      this.uploadPrevPdfDialog = false;
      this.isLoading = true;
      this.prevPdf = true;
      this.renderPDF(this.pdfUrl);
    },
    closeSavedPdf() {
      this.prevPdf = false;
      this.REMOVE_PDF_DATA();
      //if (this.layer != null) this.layer.remove();
      this.destroyKonva();
      this.clear();
      this.documentKey = new Date().getTime();
      this.uploadPrevPdfDialog = false;
      this.selectedPropertyName = null;
      this.selectedPropertyId = null;
      this.selectedType = null;
    },
    openClosePdfWarning() {
      this.closePdfDialog = true
    },
    confirmSubmitDialog() {
      if (!this.selectedPropertyId && !this.selectedPropertyName) {
        this.showSubmitDialog = true;
      } else {
        this.submitData = {
          idProperty: this.selectedPropertyId,
          type: this.selectedType,
        };
        this.customMessage = {
          'message': `(${this.selectedPropertyId}) <strong>${this.selectedPropertyName}</strong> ${this.selectedType} type \nAre you sure do you want to submit the current document?`,
        };
        this.showConfirmSubmitDialog = true;
      }
    },
    convertToCSV(objArray) {
      let str = ''
      objArray.forEach(page => {
        str += page.page + ',' + page.height + ',' + page.width + ',' + page.initial
        page.fields.forEach(input => {
          str += '\t'
          for (let key in input) {
            if (key == 'position') {
              str +=
                input[key]['x'] +
                ',' +
                input[key]['y'] +
                ',' +
                input[key]['width'] +
                ',' +
                input[key]['height'] +
                ','
            } else {
              str += input[key] + ','
            }
          }
        })
        str += '\n'
      })

      return str
    },
    convertCSVtoJSON(str) {
      const jsonData = JSON.parse(str);
      return jsonData;
    },
    saveJSONtoVuex() {
      try {
        let obj = this.convertToCSV(this.convertToJSON(true));
        this.SET_PDF_DATA(obj);
      } catch (ex) {
        console.error(ex);
      }
    },
    onAttributeChanged(params) {
        let i = this.getSelectedIndex(this.selectedIdentifier);
        const { attr, value } = params;
        let prevAttr = {...this.layer.children[i].getAttrs()};
        this.addToStack('edit', [prevAttr]);
        if(attr == 'required' || attr == 'readOnly' || attr == 'textType' || attr == 'maxLength') {
            const newValues = {
                [attr]: value
            };
            this.layer.children[i].setAttrs(newValues);
            if(attr == 'required') {
                this.layer.children[i + 1].setAttrs({
                    borderEnabled: value,
                    borderStroke: value ? this.requiredBorderColor : this.normalBorderColor
                });
                this.layer.draw();
            }
        } else if(attr == 'fontSize'){
            this.onFontSizeChanged(value);
        }
    },
    onRequiredChanged(v) {
      let i = this.getSelectedIndex(this.selectedIdentifier)
      this.layer.children[i].setAttrs({
        required: v,
      })
      this.layer.children[i+1].setAttrs({
        borderEnabled: v,
        borderStroke: v ? this.requiredBorderColor : this.normalBorderColor
      })
      this.layer.draw()
      this.$forceUpdate()
    },
    onReadOnlyChanged(v) {
      this.layer.children[
        this.getSelectedIndex(this.selectedIdentifier)
      ].setAttrs({
        readOnly: v,
      })
      this.layer.draw()
      this.$forceUpdate()
    },
    onFontSizeChanged(value) {
        const size = this.fontSizeMap[value];
        this.selectedLabel.setAttrs({fontSize: value});
        this.selectedLabel.children[1].setAttrs({fontSize: size});
        this.layer.draw();
    },
    checkIfDoneIsDisabled() {
        let disabled = false;
        if(this.custom.name.trim().length == 0 || (this.custom.selectedInputType == 'Radio'
            && (this.custom.value.trim().length == 0 || (!this.selectedIdentifier && !this.isValidOptionNumber(this.custom.optionNumber))))) {
            disabled = true;
        }
        return disabled;
    },
    isValidOptionNumber(value){
        let valid = true;
        if(value == '' || parseInt(value) < 1){
            valid = false;
        }
        return valid;
    },
    uploadCodingDoc(file) {
      this.isLoading = true;
      this.clear();
      this.pdfOriginal = file.data;
      this.uploadedFileList.push({
          name: file.name,
          status: 'processing'
      });
      this.renderPDF({
          data: file.data,
          name: file.name
      });
      /*if (!this.prevPdf) {
        try {
          this.SET_PDF_FILE(file.name)
        } catch (ex) {
          console.error(ex)
        }
      }*/
    },
    onPdfFilePicked(e) {
      this.uploadPrevPdfDialog = false
      const files = e.target.files
      const fr = new FileReader()
      fr.readAsDataURL(files[0])
      fr.onload = () => {
        // Make a fileInfo Object
        let fileInfo = {
          name: files[0].name,
          type: files[0].type,
          size: Math.round(files[0].size / 1000) + ' kB',
          data: fr.result.split(';')[1].split(',')[1],
          file: files[0],
        }
        this.uploadCodingDoc(fileInfo, this.pdfData != null ? true : false)
      }
    },
    pickPdfFile() {
      this.$refs.uploadButton.$refs.uploadFile.click();
    },
    clear() {
      try {
        this.actionsStack = [];
        this.annotations = [];
        this.annotationLayer = [];
        this.clipboard = [];
        this.closePdfDialog = false;
        this.documentQueue = [];
        this.focusedElement = null;
        this.historyDrawer = false;
        this.initialPosition = {};
        this.inputsPerPage = [];
        this.isValidTransferLabelForm = false;
        this.layer = null;
        this.leftMenuKeys = [];
        this.markedPages = [];
        this.multiselect = [];
        this.multiselectLabelIndex = [];
        this.numPages = 0;
        this.ocrResult = [];
        this.ocrValueTypesResult = [];
        this.orderPagesArray = [];
        this.page = 1;
        this.pages = [];
        this.pdfOriginal = null;
        this.pdfScale = 1.5;
        //this.prevPdf = false;
        this.rightDrawer = false;
        this.searchProperty = '';
        this.selectedIdentifier = null;
        this.selectedImage = null;
        this.selectedIndex = -1;
        this.selectedLabel = null;
        this.selectedTag = null;
        this.selectedText = null;
        this.selectionArea = null;
        this.selectMode = false;

        this.settings = {
              fontSize: "Normal",
              textType: "Alphanumeric"
        };
        this.stage = null;
        this.stageArray = [];
        this.transferLabelsDialog = false;
        this.transferLabel = {
              from: '',
              to: ''
        };
        this.trashcan = [];
        this.uploadedFileList = [];

        this.propertiesMatches = [];
        const konvaContent = document.getElementById('fields-container');
        if(konvaContent){
            konvaContent.innerHTML = '';
        }
        const pdfContainer = document.getElementById('pdf-container');
        if(pdfContainer){
            pdfContainer.innerHTML = '';
        }
        self.documentKey = new Date().getTime();
        /*if(this.$refs.leftmenucmp){
            this.$refs.leftmenucmp.updatePages();
        }*/
      } catch (ex) {
          console.error(ex);
      }
    },
    importTextRact() {
      let ocrs = this.textRactJSON.Blocks.filter(b => b.BlockType == 'KEY_VALUE_SET')
      let ocrValueTypes = ocrs.filter(ocr => ocr.EntityTypes.includes('VALUE'))
      this.ocrResult = ocrs
      this.ocrValueTypesResult = ocrValueTypes

      let inputs = this.layer.children.filter(
        i => i.className != 'Transformer' && i.className != 'Line'
      )

      ocrValueTypes.forEach(ocr => {
        let y = 0
        let pageHeight = 0
        let pageWidth = 0
        for (let i = 0; i < this.pages.length; i++) {
          if (i == ocr.Page - 1) {
            pageHeight = this.pages[i].height
            pageWidth = this.pages[i].width
            break
          }
          y += this.pages[i].height
        }
        const { Left, Top, Height, Width } = ocr.Geometry.BoundingBox
        let x = Left * pageWidth
        y += Top * pageHeight
        let w = Width * pageWidth
        let h = Height * pageHeight

        let tempInputs = inputs.filter(i => i.getAttrs().y >= y - h)

        let count = 0

        for (let i = 0; i < tempInputs.length; i++) {
          const intersects = this.haveIntersection(tempInputs[i].getAttrs(), {
            x: x,
            y: y,
            width: w,
            height: h,
          })
          if (intersects) {
            count++
          }
        }
        y += document.querySelector('#pdf-container').offsetLeft
        if (count == 0) {
          this.createField({
              identifier: ocr.Id,
              makeSelected: false,
              isFieldNameSelected: false,
              isRequired: false,
              isReadonly: false,
              id: '',
              name: 'input',
              type: 'type',
              option: null,
              withoutIcon: true,
              isCustom: false,
              x: x,
              y: y,
              width: w,
              height: h
          });
        }
      })

      this.getSuggestions('', true)
      this.calculateInputsPerPage()
    },
    getSuggestions(identifier, showAllSuggestion = false) {
      if (!this.textRactJSON)
        return

      this.propertiesMatches = []
      let val = showAllSuggestion
        ? null
        : this.textRactJSON.Blocks.find(o => o.Id == identifier)
      if (val != null || showAllSuggestion) {
        let keys = this.ocrResult.filter(ocr => ocr.Relationships != null)
        let key = []
        keys.forEach(k => {
          k.Relationships.forEach(t => {
            if (showAllSuggestion) {
              key = key.concat(t.Ids)
            } else if (t.Ids.includes(val.Id)) {
              let childs = k.Relationships.find(t => t.Type == 'CHILD')
              key = key.concat(childs.Ids)
            }
          })
        })
        key = [...new Set(key.map(p => p))]
        let words = []
        key.forEach(k => {
          let t = this.textRactJSON.Blocks.find(b => b.Id == k)
          if (t && t.Text) {
            words.push(t.Text)
          }
        })
        words = [...new Set(words.map(p => p))]

        // SORT WORDS FOLLOWING CONFIDENCE
        words = words.sort((a, b) => {
          if (a.Confidence < b.Confidence) return 1
          if (a.Confidence > b.Confidence) return -1
          return 0
        })

        let propertiesMatches = []
        let allPropertyChilds = []
        this.properties.forEach(p => allPropertyChilds.push(...p.children))
        words.forEach(w => {
          w = w.replace(/[^a-zA-Z ]/g, '')
          propertiesMatches = propertiesMatches.concat(
            allPropertyChilds.filter(
              c =>
                c.name
                  .toLowerCase()
                  .trim()
                  .match(new RegExp(w.toLowerCase().trim())) != null ||
                c.comments
                  .toLowerCase()
                  .trim()
                  .match(new RegExp(w.toLowerCase().trim())) != null ||
                w
                  .toLowerCase()
                  .trim()
                  .match(new RegExp(c.name.toLowerCase().trim())) != null ||
                w
                  .toLowerCase()
                  .trim()
                  .match(new RegExp(c.comments.toLowerCase().trim())) != null
            )
          )
        })
        propertiesMatches = [...new Set(propertiesMatches.map(p => p))]
        this.propertiesMatches = propertiesMatches
        this.$forceUpdate()
      }
    },
    propertySelection(item, params, page) {
        if(!page){
            page = this.page;
            params = this.getInputPositionParams(page);
        }
        this.rightDrawer = true;
        this.selectedProperty = item;
        if (this.selectedIndex >= 0 && item.children == null) {
            let comments = '';
            if (item.comments && item.comments.length > 0) {
                comments = item.comments;
                let ind_comma = item.comments.indexOf(',');
                if (ind_comma > 0) {
                    comments = comments.substring(0, ind_comma);
                }
            }
            this.layer.children[this.selectedIndex].setAttrs({
                id: item.name,
                type: item.type,
                comments: comments,
                custom: item.custom ? item.custom : false,
                required: item.required || false
            });
            this.layer.children[this.selectedIndex].children[0].setAttrs({
                fill: this.colorPropertySelected,
            });
            this.layer.children[this.selectedIndex].children[1].setAttrs({
                text: item.name,
            });

            this.layer.children[this.selectedIndex+1].setAttrs({
                borderEnabled: item.required || false,
                borderStroke: item.required ? this.requiredBorderColor : this.normalBorderColor
            });

            this.$forceUpdate();
            this.layer.draw();
        } else if (this.selectedIndex == -1 && item.children == null) {
            const createdId = new Date().getTime();
            this.createField({
                identifier: createdId,
                makeSelected: true,
                isFieldNameSelected: true,
                isRequired: item.required || false,
                isReadonly: false,
                id: item.name,
                name: item.name,
                type: item.type,
                option: null,
                withoutIcon: true,
                isCustom: item.custom ? item.custom : false,
                x: params.x,
                y: params.y,
                width: params.width,
                height: params.height,
                numpage: page
            });
            this.addToStack('add', [createdId]);
        }
        this.calculateInputsInSpecificPage(page);
    },
    haveIntersection(r1, r2) {
      return !(
        r2.x > r1.x + r1.width ||
        r2.x + r2.width < r1.x ||
        r2.y > r1.y + r1.height ||
        r2.y + r2.height < r1.y
      )
    },
    quickCreateNewInput() {
        const coordinates = this.getInputPositionParams(this.page);
        const inputParams = {
            identifier: new Date().getTime(),
            makeSelected: true,
            isFieldNameSelected: false,
            isRequired: false,
            isReadonly: true,
            id: '',
            name: 'input',
            type: 'type',
            option: null,
            withoutIcon: true,
            isCustom: false,
            x: coordinates.x,
            y: coordinates.y,
            width: coordinates.width,
            height: coordinates.height,
            numpage: this.page
        }
        this.createField(inputParams);
        this.calculateInputsInSpecificPage(this.page);
    },
    getInputPositionParams(page){
        let params = {
            x: 40,
            y: 120,
            width: 120,
            height: 25
        };

        this.layer = this.stageArray[page-1].getLayers()[0];
        let inputs = this.layer.children.filter(i =>
            i.className != 'Transformer' && i.className != 'Line'
        );

        for (let i = 0; i < inputs.length; i++) {
            let attrs = inputs[i].getAttrs();
            if (this.haveIntersection(attrs, params)) {
                params.y += attrs.height + Math.abs(attrs.y - params.y) + 5;
            }
        }

        return params;
    },
    gotoInput(item) {
        if (item.children == null) {
            let pageIndex = parseInt(item.id.split(':')[1]);
            let childIndex = parseInt(item.id.split(':')[3]);
            let offset = 0;
            for(let i = 1; i <= pageIndex; i++){
                if(i == pageIndex){
                    offset += this.pages[pageIndex].height * 0.75;
                } else {
                    offset += this.pages[pageIndex].height;
                }
            }
            let scrollValue = 0;
            if(pageIndex > 0) {
                scrollValue = this.inputsPerPage[pageIndex].children[childIndex].position.y + offset;
            } else if(pageIndex == 0 && this.inputsPerPage[pageIndex].children[childIndex].position.y > 700){
                scrollValue = this.inputsPerPage[pageIndex].children[childIndex].position.y * 0.5;
            }
            document.querySelector('.main-container').scrollTop = scrollValue;
            this.layer = this.stageArray[pageIndex].getLayers()[0];
            this.setSelected(item.identifier);
        }
    },
    getHistoryInputsColor(item) {
        if (item.children) {
        let flag = true
        for (let i = 0; i < item.children.length; i++) {
            if (item.children[i].fill == this.colorLabelOver) {
            flag = false
            break
            }
        }
        return flag ? 'green' : 'red'
        } else {
        if (item.fill == this.colorCustomPropertySelected) return 'primary'
        return item.fill == this.colorLabelOver ? 'red' : 'green'
        }
    },
    showCustomModal() {
      if (this.selectedIndex >= 0) {
        this.custom.selectedInputType =
          this.layer.children[this.selectedIndex].getAttrs().type == 'type'
            ? 'Text'
            : this.layer.children[this.selectedIndex].getAttrs().type
        this.custom.name = this.layer.children[this.selectedIndex].getAttrs().id
        this.custom.isRequired = this.layer.children[
          this.selectedIndex
        ].getAttrs().required
        this.custom.value = this.layer.children[
          this.selectedIndex
        ].getAttrs().content
        this.custom.isReadonly = this.layer.children[
          this.selectedIndex
        ].getAttrs().readOnly
      } else {
        Object.assign(this.custom, defaultCustom())
      }
      this.showDialog = true
    },
    drawCustomInput() {
      let attrs = {
        type: this.custom.selectedInputType,
        id: this.custom.name,
        name: this.custom.name,
        required: this.custom.isRequired,
        readOnly: this.custom.isReadonly,
        custom: true,
      }
      let page = this.page;
      if (this.custom.selectedInputType == 'Radio') {
        attrs.option = {
            value: this.custom.value
        };
      }
      if (this.selectedIndex >= 0) {
        const oldAttrs = {...this.layer.children[this.selectedIndex].getAttrs()};
        if((attrs.type == 'Radio' || oldAttrs.type == 'Radio') && attrs.type != oldAttrs.type){
            this.convertLabelType(oldAttrs, attrs);
        } else {
            this.layer.children[this.selectedIndex].setAttrs(attrs);
            if (this.custom.name.trim().length > 0) {
                this.layer.children[this.selectedIndex].children[0].setAttrs({
                    fill:
                        this.custom.name.trim().length == 0
                            ? this.colorLabelOver
                            : this.colorCustomPropertySelected,
                })
                this.layer.children[this.selectedIndex].children[1].setAttrs({
                    text:
                        this.custom.name.trim().length == 0
                            ? 'No field selected'
                            : this.custom.name,
                })
            }
            this.layer.draw()
        }
        this.addToStack('edit', [oldAttrs]);
      } else {
        let coordinates;
        let noptions = this.custom.selectedInputType == 'Radio' ? this.custom.optionNumber : 1;
        let createdId = [];
        let newId;
        for(let i = 1; i <= noptions; i++){
            coordinates = this.getInputPositionParams(this.page);
            let option = {
                value: this.custom.selectedInputType == 'Radio' ? this.custom.value + i : null
            }
            newId = new Date().getTime();
            this.createField({
                identifier: newId,
                makeSelected: true,
                isFieldNameSelected: this.custom.name.trim().length > 0 ? true : false,
                isRequired: this.custom.isRequired,
                isReadonly: this.custom.isReadonly,
                id: this.custom.name.trim(),
                name: this.custom.name.trim(),
                type: this.custom.selectedInputType,
                option: option,
                withoutIcon: true,
                isCustom: true,
                x: coordinates.x,
                y: coordinates.y,
                width: coordinates.width,
                height: coordinates.height,
                numpage: this.page
            });
            createdId.push(newId);
            page = this.page;
        }
        this.addToStack('add', createdId);
      }

      //let page = this.layer.children[this.selectedIndex].getAttrs().page;
      this.calculateInputsInSpecificPage(page);

      Object.assign(this.custom, defaultCustom());
      this.showDialog = false;
    },
    changeAttibuteMultipleObjects(params) {
        if(this.multiselect.length){
            let changedElements = [];
            this.multiselect.forEach((identifier) => {
                let index = this.getSelectedIndex(identifier);
                let attrs = {...this.layer.children[index].getAttrs()};
                let copyAttrs = {...params};
                copyAttrs = this.filterBeforeApply(attrs, copyAttrs);
                changedElements.push(attrs);
                this.layer.children[index].setAttrs(copyAttrs);
                if('required' in copyAttrs){
                    this.layer.children[index+1].setAttrs({
                        borderEnabled: copyAttrs.required,
                        borderStroke: copyAttrs.required ? this.requiredBorderColor : this.normalBorderColor
                    });
                }
            });
            this.layer.draw();
            this.addToStack('edit', changedElements);
        }
    },
    filterBeforeApply(attrs, copyAttrs){
        if(!attrs.custom){
            if(copyAttrs.type){
                delete copyAttrs.type;
            }
        }
        if(this.forbiddenTypes.indexOf(attrs.type) != -1){
            if(copyAttrs.textType){
                delete copyAttrs.textType;
            }
            if(copyAttrs.maxLength){
                delete copyAttrs.maxLength;
            }
            if(copyAttrs.type && (attrs.type != 'Radio' && attrs.type != 'Checkbox')){
                delete copyAttrs.type;
            }
        }
        return copyAttrs;
    },
    getLabels(page = null){
        let children = [];
        if(page){
            this.layer = this.stageArray[page].getLayers()[0];
            children = this.layer.children.filter(i => i.className != 'Transformer' && i.className != 'Line');
        } else {
            for (let i = 0; i < this.numPages; i++) {
                this.layer = this.stageArray[i].getLayers()[0];
                let pageChildren = this.layer.children.filter(i => i.className != 'Transformer' && i.className != 'Line');
                if(pageChildren.length > 0){
                    children = children.concat(pageChildren);
                }
            }
            if(this.selectedLabel){
                const att = this.selectedLabel.getAttrs();
                this.layer = this.stageArray[att.page-1].getLayers()[0];
            }
        }
        return children;
    },

    sortedInputsByPosition() {
        let children = [];
        for (let i = 0; i < this.numPages; i++) {
            this.layer = this.stageArray[i].getLayers()[0];
            let pageChildren = this.layer.children.filter(i => i.className != 'Transformer' && i.className != 'Line');
            if(pageChildren.length > 0){
                children = children.concat(pageChildren);
            }
        }
        children = children.sort((a, b) => {
            let attra = a.getAttrs();
            let attrb = b.getAttrs();
            if (attra.y - 5 > attrb.y){
                return 1;
            } else {
                if (attra.y + 5 < attrb.y) {
                    return -1;
                }
                else {
                    if (attra.x <= attrb.x){
                        return 1;
                    } else {
                        return 0;
                    }
                }
            }
        });
        return children;
    },
    previousInput() {
        const children = this.getLabels();
        const selectedIndex = children.findIndex(c => c.getAttrs().identifier == this.selectedIdentifier);
        if (selectedIndex - 1 >= 0) {
            const prevElement = children[selectedIndex - 1];
            this.navigateToInput(prevElement);
        }
    },
    nextInput() {
        const children = this.getLabels();
        const selectedIndex = children.findIndex(c => c.getAttrs().identifier == this.selectedIdentifier);
        if (selectedIndex + 1 < children.length) {
            const nextElement = children[selectedIndex + 1];
            this.navigateToInput(nextElement);
        }
    },
    navigateToInput(element){
        const attrs = element.getAttrs();
        const pageIndex = attrs.page-1;
        let offset = 0;
        for(let i = 1; i <= pageIndex; i++){
            if(i == pageIndex){
                offset += this.pages[pageIndex].height * 0.75;
            } else {
                offset += this.pages[pageIndex].height;
            }
        }
        const scrollValue = attrs.y + offset;
        document.querySelector('.main-container').scrollTop = scrollValue;
        this.releaseSelected();
        this.layer = this.stageArray[pageIndex].getLayers()[0];
        this.setSelected(attrs.identifier);
    },
    getSelectedIndex(identifier) {
      return this.layer.children.findIndex(
        x => x.getAttrs().identifier == identifier
      )
    },
    getSelectedLabel(identifier) {
        let matched = this.layer.getChildren((node) => {
            return node.getAttrs().identifier == identifier;
        });
        return matched != null && matched.length > 0 ? matched[0] : null
    },
    setSelectedProperty(name) {
      for (let i = 0; i < this.properties.length; i++) {
        for (let j = 0; j < this.properties[i].children.length; j++) {
          if (this.properties[i].children[j].name == name) {
            this.selectedProperty = this.properties[i].children[j]
            return true
          }
        }
      }

      this.selectedProperty = null
      return false
    },
    setSelected(identifier) {
        if(this.selectedIndex != -1){
            this.releaseSelected();
        }
        this.selectedIndex = this.getSelectedIndex(identifier);
        this.selectedLabel = this.getSelectedLabel(identifier);
        this.selectedIdentifier = identifier;
        const labelPage = this.selectedLabel.getAttrs().page;
        this.layer = this.stageArray[labelPage-1].getLayers()[0];
        this.setSelectedProperty(this.layer.children[this.selectedIndex].getAttrs().id);
        this.layer.find('Transformer').resizeEnabled(false);
        this.layer.children[this.selectedIndex + 1].resizeEnabled(true);
        this.layer.draw();

        this.rightDrawer = true;
        this.$forceUpdate();
    },
    releaseSelected() {
        if(this.focusedElement){
            if(this.layer.children.length > 0 && this.layer.children.length >= this.selectedIndex + 1) {
                this.layer.children[this.selectedIndex + 1].setAttrs({
                    borderEnabled: this.selectedLabel.attrs.required ? true : false,
                    borderStrokeWidth: 1,
                    borderStroke: this.selectedLabel.attrs.required ? this.requiredBorderColor : this.normalBorderColor
                });
            }
            this.focusedElement = null;
        }
        let layer = this.selectedIdentifier != -1 ? this.getLayerOfSelectedElement() : null;
        this.selectedIndex = -1;
        this.selectedLabel = null;
        this.selectedIdentifier = null;
        this.rightDrawer = false;
        this.multiselect = [];
        this.multiselectLabelIndex = [];
        if(layer){
            layer.find('Transformer').resizeEnabled(false);
            layer.draw();
        }
    },
    getLayerOfSelectedElement(){
        let found = false;
        let index = null;
        for(let i = 0; i < this.stageArray.length && !found; i++){
            let auxLayer = this.stageArray[i].getLayers()[0];
            let element = auxLayer.getChildren((node) => {
                return node.getAttrs().identifier == this.selectedIdentifier;
            });
            if(element.length){
                found = true;
                index = i;
            }
        }
        return index >= 0 ? this.stageArray[index] : null;
    },
    setPageNumber(label) {
      let page = 1
      let height = 0
      for (let i = 0; i < this.pages.length; i++) {
        height += this.pages[i].height
        if (label.getAttrs().y <= height) {
          page = i + 1
          break
        }
      }
      label.setAttrs({
        page: page,
      })
    },
    calculateInputsPerPage() {
        if (this.layer == null && this.stageArray.length == 0){
            return [];
        }

        let inputObj = {};
        this.inputsPerPage = new Array(this.numPages);
        for (let i = 0; i < this.numPages; i++) {
            inputObj = {
                id: 'Page ' + i,
                name: 'Page ' + (i + 1),
                children: []
            };
            this.inputsPerPage[i] = inputObj;
            this.calculateInputsInSpecificPage(i+1);
        }
    },
    calculateInputsInSpecificPage(page){
        const self = this;
        if (self.layer == null){
            return [];
        }
        let children = self.sortInputsInPage(page-1);
        let pageElems = {...self.inputsPerPage[page-1]};
        pageElems.children = [];

        children.forEach(label => {
            let attrs = label.getAttrs();
            let page = parseInt(attrs.page);
            let params = {
                identifier: attrs.identifier,
                id: 'child:' + (page - 1).toString() + ':sl:' + pageElems.children.length + ':y:' + attrs.y,
                isSelected: false,
                name: attrs.id,
                fill: label.children[0].getAttrs().fill,
                position: {
                    x: attrs.x,
                    y: attrs.y,
                    width: attrs.width,
                    height: attrs.height
                }
            };
            pageElems.children.push(params);
        });
        self.inputsPerPage.splice(page-1, 1, pageElems);
    },
      sortInputsInPage(page) { 
        this.layer = this.stageArray[page].getLayers()[0];
        let children = this.layer.children.filter(i => i.className != 'Transformer' && i.className != 'Line');
        children = children.sort((a, b) => {
            let attra = a.getAttrs();
            let attrb = b.getAttrs();
            if (attra.y - 5 > attrb.y){
                return 1;
            } else {
                if (attra.y + 5 < attrb.y) {
                    return -1;
                }
                else {
                    if (attra.x <= attrb.x){
                        return 1;
                    } else {
                        return 0;
                    }
                }
            }
        });
        return children;
    },
    pickJSONFile() {
      this.$refs.file.click()
    },
    onFilePicked(e) {
      const files = e.target.files
      const fr = new FileReader()
      fr.readAsText(files[0])
      fr.addEventListener('load', () => {
        this.createInputsFromJSON(JSON.parse(fr.result), false, true)
      })
    },
    saveToJSON() {
      let jsonData = this.convertToJSON(true)
      const data = JSON.stringify(jsonData)
      const blob = new Blob([data], { type: 'text/plain' })
      const e = document.createEvent('MouseEvents'),
        a = document.createElement('a')
      a.download = 'inputs.json'
      a.href = window.URL.createObjectURL(blob)
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':')
      e.initEvent(
        'click',
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      )
      a.dispatchEvent(e)
    },
    storeChanges() {
        this.$nextTick(() => {
            const jsonData = this.convertToJSON(true);
            const coding = JSON.stringify(jsonData);
            this.SET_PDF_CHANGES(coding);
        });
    },
    downloadPDF() {
        let jsonData = this.convertToJSON(true);
        const coding = JSON.stringify(jsonData);

        const _this = this;
        _this.isLoading = true;
        _this.$http
        .post("/manager/documents/coding/preview", {
            fileData: _this.pdfOriginal,
            coding: coding
        })
        .then(response => {
            if (response && response.data && response.data.result){
                const blob = this.b64toBlob(response.data.result, 'application/pdf');

                const e = document.createEvent('MouseEvents'),
                    a = document.createElement('a');
                a.download = 'result.pdf';
                a.href = window.URL.createObjectURL(blob);
                a.dataset.downloadurl = ['application/pdf', a.download, a.href].join(':');
                e.initEvent(
                    'click',
                    true,
                    false,
                    window,
                    0,
                    0,
                    0,
                    0,
                    0,
                    false,
                    false,
                    false,
                    false,
                    0,
                    null
                );
                a.dispatchEvent(e);
            }
        })
        .catch(e => {
            // eslint-disable-next-line
            this.alertError('Coding error: ' + e)
            console.error(e)
        })
        .finally(() => {
            _this.isLoading = false;
        });
    },
    autoInputs() {
      const _this = this
      _this.isLoading = true
      _this.$http
        .post("/manager/documents/text", {
          'text': _this.pdfOriginal,
        }, {
          timeout: 1000000 ,
        })
        .then(response => {
          if (response && response.data && response.data.result){
            //const blob = this.b64toBlob(response.data.result, 'application/pdf')
          }
        })
        .catch(e => {
          // eslint-disable-next-line
          this.alertError('Coding error: ' + e)
          console.error(e)
        })
        .finally(() => {
          _this.isLoading = false
        });
    },
    b64toBlob (b64Data, contentType='', sliceSize=512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, {type: contentType});
      return blob;
    },
    createInputsFromJSON(jsonData, makeNewSelected = false, fromFile = false) {
        //this.layer.children.splice(0, this.layer.children.length);
        const self = this;
        jsonData.forEach(page => {
            if(page.page > 0){
                self.layer = self.stageArray[page.page - 1];
            }
            page.fields.forEach(label => {
                  let option = {
                    value: label.value == '' ? null : label.value,
                  };

                  let h = label.position.height;
                  let x = label.position.x;
                  let y = label.position.y;
                  let w = label.position.width;
                  self.createField({
                      identifier: fromFile ? new Date().getTime() : label.identifier,
                      makeSelected: makeNewSelected,
                      isFieldNameSelected: label.fieldName.trim().length == 0 ? false : true,
                      isRequired: label.required,
                      isReadonly: label.readOnly,
                      id: label.fieldName,
                      name: label.fieldName,
                      type: label.type,
                      option: option,
                      withoutIcon: true,
                      isCustom: label.custom,
                      x: x,
                      y: y,
                      width: w,
                      height: h,
                      numpage: label.page,
                      textType: label.textType,
                      fontSize: label.fontSize,
                      maxLength: label.maxLength
                  });
            });
        });
        self.calculateInputsPerPage();
    },
    convertToJSON(generateFile = false) {
        let jsonData = [];
        for (let i = 0; i < this.pages.length; i++) {
            jsonData.push({
                page: i + 1,
                height: this.pages[i].height,
                width: this.pages[i].width,
                fields: [],
                initial: this.pages[i].initial
            });
        }
        let childrens = this.getLabels();
        let attrs;
        childrens.forEach(label => {
            attrs = label.getAttrs();
            let page = attrs.page;
            let obj = {
                identifier: attrs.identifier,
                page: page,
                position: {
                    x: parseInt(attrs.x),
                    y: parseInt(attrs.y),
                    width: parseInt(attrs.width),
                    height: parseInt(attrs.height),
                },
                fieldName: attrs.id,
                custom: attrs.custom,
                type: attrs.type,
                required: attrs.required,
                readOnly: attrs.readOnly,
                fontSize: attrs.fontSize,
                value: attrs.content ? attrs.content : '',
                textType: attrs.textType ? attrs.textType : '',
                maxLength: attrs.maxLength ? attrs.maxLength : ''
            }
            if(obj.type == 'Radio') {
                obj.position.x = obj.position.x - Math.floor(obj.position.width / 2);
                obj.position.y = obj.position.y - Math.floor(obj.position.width / 2);
            }
            if (generateFile) {
                delete obj.identifier;
            }
            jsonData[page - 1].fields.push(obj);
        });
        return jsonData;
    },
    renderPDF(fileInfo) {
        const self = this;
        let fileReference;
        if(typeof fileInfo === 'object' && fileInfo.data) {
            fileReference = {
                data: atob(fileInfo.data)
            };
        } else {
            fileReference = fileInfo;
        }
        let jsonData = self.prevPdf && self.pdfChanges ? self.convertCSVtoJSON(self.pdfChanges) : null;
        const loadingTask = pdfjsLib.getDocument(fileReference);
        loadingTask.promise.then((pdf) => {
                self.numPages = pdf._pdfInfo.numPages;
                let validPages = self.numPages;
                if(jsonData && jsonData.length < validPages) {
                    validPages = jsonData.length;
                }
                let getPromises = new Array(validPages);
                let renderPromises = new Array(validPages);
                self.annotationLayer = new Array(validPages);
                for (let i = 1, j = 0; i <= self.numPages; i++, j++) {
                    getPromises[j] = self.getPageData(pdf, i);
                }
                Promise.all(getPromises).then((response) => {
                    let idx;
                    for(let k = 0; k < validPages; k++){
                        try {
                            idx = k;
                            if(jsonData){
                                idx = jsonData[k].initial;
                            }
                            if(idx >= 0) {
                                renderPromises[idx] = self.renderPage(response[idx].page, response[idx].rotation);
                            }
                        } catch (e) {
                            console.error("An error rendering page in the API happened, page number: " + response[k].number);
                            console.error(e);
                        }
                    }
                    Promise.all(renderPromises).then(() => {
                        self.completeRendering(jsonData);
                        self.updateDocumentStatus(fileInfo.name, 'completed');
                        self.documentKey = new Date().getTime();
                        if(self.pdfUrl) {
                            self.submitDisabled = self.isOriginalDocument(self.pdfUrl);
                        }
                        /*if(self.$refs.leftmenucmp){
                            self.$refs.leftmenucmp.updatePages();
                        }*/
                    });
                });
            },
            function(reason) {
                console.error(reason)
            }
        );
    },

    getPageData(pdf, index){
        return pdf.getPage(index).then(function(page) {
            return {
                page: page,
                rotation: 0,
                number: index
            }
        });
    },

    renderPage(page, rotation) {
        const self = this;
        const viewport = page.getViewport({scale: self.pdfScale, rotation: rotation});
        const canvas = document.createElement('canvas');
        document.getElementById(self.pdfContainerId).appendChild(canvas);
        canvas.className = 'pdf-canvas';
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        let pageIndex = page._pageIndex >= 0 ? page._pageIndex : page.pageIndex;
        //if(!self.prevPdf) {
            page.getAnnotations().then(ans => {
                let pageIndexAnn = page._pageIndex >= 0 ? page._pageIndex : page.pageIndex;
                if (self.stageArray.length > 0) {
                    pageIndexAnn += self.stageArray.length;
                }
                self.annotationLayer[pageIndexAnn] = ans;
            });
        //}

        self.pages.push({
            width: viewport.width,
            height: viewport.height,
            pdfHeight: page._pageInfo.view[3],
            pdfWidth: page._pageInfo.view[2],
            initial: pageIndex
        });

        const context = canvas.getContext('2d');
        const renderContext = {
            canvasContext: context,
            viewport: viewport,
        };
        return page.render(renderContext).promise.then(() => {
            return true;
        });
    },

    completeRendering(jsonData = null) {
        const self = this;
        self.renderKonva()
        if (jsonData) {
            self.numPages = self.pages.length;
            self.createInputsFromJSON(jsonData, false, true);
            //self.layer.draw();
            self.isLoading = false;
        } else {
            self.renderAnnotations();
            self.isLoading = false;
        }
    },

    renderAnnotations(from = 0){
        const self = this;
        let copy;
        for (let j = from; j < self.annotationLayer.length; j++) {
            if(self.annotationLayer[j]) {
                self.annotationLayer[j].forEach(a => {
                    copy = Object.assign({}, {page: j + 1}, a, {pageHeight: self.pages[j].pdfHeight});
                    self.annotations.push(copy);
                });
            }
        }
        self.createInputsFromAnnotation();
    },

    createInputsFromAnnotation() {
      let proChilds = []
      this.properties.forEach(p => {
        proChilds.push(...p.children)
      })
      this.annotations
        .filter(a => a.subtype == 'Widget')
        .forEach(annotation => {
          let type = 'Text'
          let proMatch = proChilds.find(p =>
            p.name
              .trim()
              .toLowerCase()
              .includes(annotation.fieldName.trim().toLowerCase())
          )
          if (proMatch) {
            type = proMatch.type
          } else {
            switch (annotation.fieldType) {
              case 'Btn':
                type = 'Radio'
                break
              default:
                break
            }
          }
          let isCustom =
            annotation.fieldName == null ||
            annotation.fieldName.trim().length == 0
              ? false
              : this.isCustom(annotation.fieldName)
          let x = annotation.rect[0] * this.pdfScale;
          let w = Math.abs(annotation.rect[2] - annotation.rect[0]) * this.pdfScale;
          let h = Math.abs(annotation.rect[3] - annotation.rect[1]) * this.pdfScale;
          let y = (annotation.pageHeight - annotation.rect[1]) * this.pdfScale - h;

          let option = {
            value: annotation.buttonValue,
          };
          let isRequired = false;
          if((type != 'Radio' && annotation.fieldFlags == 2) || (type == 'Radio' && annotation.fieldFlags == 49154)) {
              isRequired = true;
          }
          let params = {
              identifier: new Date().getTime(),
              makeSelected: false,
              isFieldNameSelected: annotation.fieldName == null || annotation.fieldName.trim().length == 0 ? false : true,
              isRequired: isRequired,
              isReadonly: annotation.readOnly,
              id: annotation.fieldName,
              name: annotation.fieldName,
              type: type,
              option: option,
              withoutIcon: true,
              isCustom: isCustom,
              x: x,
              y: y,
              width: w,
              height: h,
              numpage: annotation.page
          };
          this.createField(params);
        })
        this.calculateInputsPerPage();
        this.layer = null;
        this.annotationLayer = [];
        this.annotations = [];
    },
    isCustom(fieldName) {
      for (let i = 0; i < this.properties.length; i++) {
        for (let j = 0; j < this.properties[i].children.length; j++) {
          if (this.properties[i].children[j].name == fieldName) {
            return false
          }
        }
      }
      return true
    },
    createField(parameters) {
        const _this = this;
        const top = Math.abs(document.querySelector('#pdf-container').getBoundingClientRect().top);

        let labelWidth = parameters.width ? Math.max(parameters.width, 20) : parameters.withoutIcon ? 20 : 100;
        const fontSize = parameters.fontSize ? parameters.fontSize : this.settings.fontSize;
        if(parameters.type == "Radio" || parameters.type == "Checkbox"){
            labelWidth = 20;
            parameters.height = 20;
        }

        // create label
        let labelObj = {
            y: parameters.y || top + 80 + 40,
            x: parameters.x || 40,
            width: labelWidth,
            height: parameters.height ? Math.max(parameters.height, 20) : parameters.withoutIcon ? 20 : 100,
            draggable: true,
            name: parameters.name,
            id: parameters.id,
            required: parameters.isRequired ? parameters.isRequired : parameters.required ? parameters.required : false,
            readOnly: parameters.isReadonly,
            type: parameters.type,
            custom: parameters.isCustom,
            identifier: parameters.identifier,
            page: parameters.numpage,
            fontSize: fontSize,
            isFieldNameSelected: parameters.isFieldNameSelected || null
        };
        if(parameters.type == 'Text' || parameters.type == 'type'){
            labelObj.textType = parameters.textType ? parameters.textType : this.settings.textType;
            labelObj.maxLength = parameters.maxLength ? parameters.maxLength : '';
        }
        if (parameters.type == 'Radio') {
            labelObj.content = parameters.option.value;
            labelObj.x = labelObj.x + Math.floor(labelWidth/2);
            labelObj.y = labelObj.y + Math.floor(labelWidth/2);
        }
        let label = new Konva.Label(labelObj)
        if(parameters.numpage == null){
            _this.setPageNumber(label);
        }
        const attributes = label.getAttrs();
        let tag;
        if(parameters.type == 'Radio'){
            tag = new Konva.Circle({
                fill: parameters.isFieldNameSelected
                    ? parameters.isCustom
                        ? _this.colorCustomPropertySelected
                        : _this.colorPropertySelected
                    : _this.colorLabelOver,
                radius: labelWidth/2,
                opacity: 0.8,
                stroke: '#333',
                strokeWidth: 0,
            });
        } else {
            tag = new Konva.Rect({
                fill: parameters.isFieldNameSelected
                    ? parameters.isCustom
                        ? _this.colorCustomPropertySelected
                        : _this.colorPropertySelected
                    : _this.colorLabelOver,
                width: labelWidth,
                height: parameters.height ? Math.max(20, parameters.height) : parameters.withoutIcon ? 20 : 100,
                opacity: 0.8,
                stroke: '#333',
                strokeWidth: 0,
            });
        }
        label.add(tag);

        let text;
        let image;
        _this.layer = _this.stageArray[attributes.page-1].getLayers()[0];
        if (parameters.type == 'Signature' || parameters.type == 'type') {
            if (parameters.withoutIcon) {
                text = new Konva.Text({
                    text: parameters.id,
                    fontSize: _this.fontSizeMap[fontSize],
                    lineHeight: 1,
                    padding: 4,
                    fill: '#000000',
                    width: labelWidth,
                    wrap: 'none',
                    ellipsis: true
                });
                label.add(text);
            } else {
                const imageObj = new Image();
                imageObj.onload = function() {
                    image = new Konva.Image({
                        x: 30,
                        y: 30,
                        image: parameters.withoutIcon ? '' : imageObj,
                        width: 40,
                        height: 40,
                    });
                    label.add(image);
                    _this.layer.draw();
                }
                imageObj.src = 'fountain-pen.svg';
            }
        } else {
            text = new Konva.Text({
                width: labelWidth,
                text: parameters.name,
                fontFamily: 'Helvetica',
                fontSize: _this.fontSizeMap[fontSize],
                lineHeight: 1,
                padding: 4,
                fill: '#000000',
                wrap: 'none',
                ellipsis: true
            });
            label.add(text);
        }

        const tr = new Konva.Transformer({
            node: label,
            borderStroke: _this.normalBorderColor,
            anchorStroke: '#2996f3',
            anchorCornerRadius: 0,
            borderEnabled: false,
            resizeEnabled: false,
            rotateEnabled: false,
            enabledAnchors: [
                'top-left',
                'top-right',
                'bottom-right',
                'bottom-left',
                'top-center',
                'bottom-center',
                'middle-right',
                'middle-left',
            ],
            keepRatio: false,
            // set minimum width of text
            boundBoxFunc: function(oldBox, newBox) {
                if(_this.multiselect.length == 0) {
                    newBox.width = Math.max(20, newBox.width);
                    newBox.height = Math.max(20, newBox.height);
                    let reviewedPosition = _this.keepBoxInTheStageAtResize(newBox);
                    if(reviewedPosition.corrected){
                        newBox.width = reviewedPosition.attrs.width;
                        newBox.height = reviewedPosition.attrs.height;
                        tr.stopTransform();
                        tr.resizeEnabled(false);
                    }

                    label.setAttrs({
                        width: newBox.width,
                        height: newBox.height,
                        scaleX: 1,
                    });
                    tag.setAttrs({
                        width: newBox.width,
                        height: newBox.height,
                        scaleX: 1,
                    });
                    if (parameters.type == 'Signature' || parameters.type == 'type') {
                        if (!parameters.withoutIcon) {
                            image.setAttrs({
                                x: newBox.width / 2 - 20,
                                y: newBox.height / 2 - 20,
                            });
                        }
                    } else {
                        text.setAttrs({
                            width: newBox.width,
                            scaleX: 1,
                        });
                    }
                } else {
                    if(_this.multiselectLabelIndex.length == 0){
                        _this.getMultiSelectedLabels();
                    }

                    let xDiff = newBox.x - oldBox.x;
                    let yDiff = newBox.y - oldBox.y;
                    let widthDiff = newBox.width - oldBox.width;
                    let heightDiff = newBox.height - oldBox.height;

                    let attrs, elemBox;
                    _this.multiselectLabelIndex.forEach((position, index) => {
                        attrs = _this.layer.children[position].getAttrs();
                        if(label.index == position){
                            elemBox = {...newBox};
                        } else {
                            elemBox = {
                                x: attrs.x + xDiff,
                                y: attrs.y + yDiff,
                                width: attrs.width + widthDiff,
                                height: attrs.height + heightDiff
                            };
                        }

                        let reviewedPosition = _this.keepBoxInTheStageAtResize(elemBox);
                        if(reviewedPosition.corrected){
                            elemBox = {...reviewedPosition.attrs};
                            _this.layer.children[position+1].stopTransform();
                            _this.layer.children[position+1].resizeEnabled(false);
                        }
                        _this.layer.children[position].setAttrs({
                            x: elemBox.x,
                            y: elemBox.y,
                            width: elemBox.width,
                            height: elemBox.height,
                            scaleX: 1,
                        });
                        _this.layer.children[position].children[0].setAttrs({
                            width: elemBox.width,
                            height: elemBox.height,
                            scaleX: 1,
                        });
                        if ((attrs.type == 'Signature' || attrs.type == 'type') && !attrs.withoutIcon) {
                            _this.layer.children[position].children[1].setAttrs({
                                x: elemBox.width / 2 - 20,
                                y: elemBox.height / 2 - 20,
                            });
                        } else {
                            _this.layer.children[position].children[1].setAttrs({
                                width: elemBox.width,
                                scaleX: 1,
                            });
                        }
                        if(reviewedPosition.corrected){
                            _this.multiselectLabelIndex.splice(index, 1);
                        }
                    });
                }
                _this.layer.draw();
                return newBox;
            },
        });

        if (labelObj.required) {
            tr.setAttrs({
                borderEnabled: true,
                borderStroke: 'red',
            });
        }

        tr.on('transformstart', function () {
            _this.saveOldAttributesBeforeAction(_this.layer.children[this.index-1]);
        });

        // Label Mouse Down
        label.on('mousedown', function(e) {
            const attrs = this.getAttrs();
            _this.layer = _this.stageArray[attrs.page-1].getLayers()[0];
            if((e.evt.ctrlKey || e.evt.metaKey) && _this.selectedIndex != -1){
                if(_this.multiselect.indexOf(_this.selectedIdentifier) == -1){
                    _this.focusedElement = _this.selectedIdentifier;
                    _this.layer.children[_this.selectedIndex+1].setAttrs({
                        borderEnabled: true,
                        borderStroke: _this.focusedBorderColor,
                        borderStrokeWidth: 2
                    });
                    _this.multiselect.push(_this.selectedIdentifier);
                }
                //const attrs = this.getAttrs();
                if(_this.multiselect.indexOf(attrs.identifier) == -1){
                    _this.multiselect.push(attrs.identifier);
                    //_this.layer = _this.stageArray[attrs.page-1].getLayers()[0];
                    tr.resizeEnabled(true);
                }
                _this.layer.draw();
            } else {

                if(_this.multiselect.indexOf(attrs.identifier) == -1){

                    _this.setSelected(attrs.identifier);
                    _this.layer.find('Transformer').resizeEnabled(false);
                    tr.resizeEnabled(true);
                    _this.layer.draw();
                }
            }
        });

        label.on('dblclick', function () {
            _this.showCustomModal();
        });

        label.on('dragstart', function() {
            let elementsAttrs = [];
            let attrs = this.getAttrs();
            if(_this.multiselect.length > 0){
                _this.initialPosition = {
                    x: attrs.x,
                    y: attrs.y
                };
                _this.multiselect.forEach((identifier) => {
                    let label = _this.getSelectedLabel(identifier);
                    let lattrs = label.getAttrs();
                    elementsAttrs.push({...lattrs});
                });
            } else {
                elementsAttrs.push({...attrs});
            }
            _this.addToStack('edit', elementsAttrs);
        });

        label.on('dragmove', function() {
            let attrs = this.getAttrs();
            let params = {};
            let reviewedPosition;
            if(_this.multiselect.length > 0){
                const diffX = attrs.x - _this.initialPosition.x;
                const diffY = attrs.y - _this.initialPosition.y;
                let chattrs;
                if(_this.multiselectLabelIndex.length == 0){
                    _this.getMultiSelectedLabels();
                }
                _this.multiselectLabelIndex.forEach((position) => {
                    chattrs = _this.layer.children[position].getAttrs();
                    if(chattrs.identifier != attrs.identifier){
                        params = {
                            x: chattrs.x + diffX,
                            y: chattrs.y + diffY,
                            width: chattrs.width,
                            height: chattrs.height
                        };
                        reviewedPosition = _this.keepBoxInTheStageAtDrag(params);
                        _this.layer.children[position].setAttrs(reviewedPosition);
                    } else {
                        params = {
                            x: chattrs.x,
                            y: chattrs.y,
                            width: chattrs.width,
                            height: chattrs.height
                        };
                        reviewedPosition = _this.keepBoxInTheStageAtDrag(params);
                        if(reviewedPosition.corrected){
                            _this.layer.children[position].setAttrs(reviewedPosition);
                            attrs = this.getAttrs();
                        }
                    }
                });

                _this.initialPosition = {
                    x: attrs.x,
                    y: attrs.y
                };
                _this.layer.draw();
            } else {
                reviewedPosition = _this.keepBoxInTheStageAtDrag(attrs);
                if(reviewedPosition.corrected){
                    params = {
                        x: reviewedPosition.attrs.x,
                        y: reviewedPosition.attrs.y
                    };
                    this.setAttrs(params);
                    _this.layer.draw()
                }
            }
        });

        label.on('dragend', (e) => {
            const attrs = e.target.getAttrs();
            _this.calculateInputsInSpecificPage(attrs.page);
        });

        // Label Touch Start
        label.on('touchstart', function() {
            const attrs = this.getAttrs();
            _this.layer = _this.stageArray[attrs.page-1].getLayers()[0];
            _this.setSelected(attrs.identifier);
            _this.layer.find('Transformer').resizeEnabled(false);
            tr.resizeEnabled(true);
            _this.layer.draw();
            document.body.classList.add('grabbing');
            //_this.getSuggestions(identifier);
        });

        // Label Touch End
        /*label.on('touchend', function(e) {
            document.body.classList.remove('grabbing')
            document.body.classList.remove('grab')
        });*/
        //, touchmove, touchend, tap, dbltap, dragstart

        _this.layer.add(label);
        _this.layer.add(tr);
        _this.layer.draw();

        if (parameters.makeSelected) {
            _this.setSelected(parameters.identifier);
        }
        return label;
    },

    getMultiSelectedLabels(){
        let end, attrs;
        for(let i = 0; i < this.multiselect.length; i++){
            end = false;
            for(let j = 0; j < this.layer.children.length && !end; j++){
                if(this.layer.children[j].className == 'Label'){
                    attrs = this.layer.children[j].getAttrs();
                    if(attrs.identifier == this.multiselect[i]){
                        this.multiselectLabelIndex.push(j);
                        end = true;
                    }
                }
            }
        }
    },
    // were can we snap our objects?
    getLineGuideStops(skipShape) {
      // we can snap to stage borders and the center of the stage
      const vertical = [0, this.stage.width() / 2, this.stage.width()]
      const horizontal = [0, this.stage.height() / 2, this.stage.height()]

      // and we snap over edges and center of each object on the canvas
      this.stage.find('.object').forEach(guideItem => {
        if (guideItem === skipShape) {
          return
        }
        let box = guideItem.getClientRect()
        // and we can snap to all edges of shapes
        vertical.push([box.x, box.x + box.width, box.x + box.width / 2])
        horizontal.push([box.y, box.y + box.height, box.y + box.height / 2])
      })

      return {
        vertical: vertical.flat(),
        horizontal: horizontal.flat(),
      }
    },
    // what points of the object will trigger to snapping?
    // it can be just center of the object
    // but we will enable all edges and center
    getObjectSnappingEdges(node) {
      const box = node.getClientRect()
      return {
        vertical: [
          {
            guide: Math.round(box.x),
            offset: Math.round(node.x() - box.x),
            snap: 'start',
          },
          {
            guide: Math.round(box.x + box.width / 2),
            offset: Math.round(node.x() - box.x - box.width / 2),
            snap: 'center',
          },
          {
            guide: Math.round(box.x + box.width),
            offset: Math.round(node.x() - box.x - box.width),
            snap: 'end',
          },
        ],
        horizontal: [
          {
            guide: Math.round(box.y),
            offset: Math.round(node.y() - box.y),
            snap: 'start',
          },
          {
            guide: Math.round(box.y + box.height / 2),
            offset: Math.round(node.y() - box.y - box.height / 2),
            snap: 'center',
          },
          {
            guide: Math.round(box.y + box.height),
            offset: Math.round(node.y() - box.y - box.height),
            snap: 'end',
          },
        ],
      }
    },
    // find all snapping possibilities
    getGuides(lineGuideStops, itemBounds) {
      const resultV = []
      const resultH = []

      lineGuideStops.vertical.forEach(lineGuide => {
        itemBounds.vertical.forEach(itemBound => {
          let diff = Math.abs(lineGuide - itemBound.guide)
          // if the distance between guild line and object snap point is close we can consider this for snapping
          if (diff < this.guideLineOffset) {
            resultV.push({
              lineGuide: lineGuide,
              diff: diff,
              snap: itemBound.snap,
              offset: itemBound.offset,
            })
          }
        })
      })

      lineGuideStops.horizontal.forEach(lineGuide => {
        itemBounds.horizontal.forEach(itemBound => {
          const diff = Math.abs(lineGuide - itemBound.guide)
          if (diff < this.guideLineOffset) {
            resultH.push({
              lineGuide: lineGuide,
              diff: diff,
              snap: itemBound.snap,
              offset: itemBound.offset,
            })
          }
        })
      })

      const guides = []

      // find closest snap
      const minV = resultV.sort((a, b) => a.diff - b.diff)[0]
      const minH = resultH.sort((a, b) => a.diff - b.diff)[0]
      if (minV) {
        guides.push({
          lineGuide: minV.lineGuide,
          offset: minV.offset,
          orientation: 'V',
          snap: minV.snap,
        })
      }
      if (minH) {
        guides.push({
          lineGuide: minH.lineGuide,
          offset: minH.offset,
          orientation: 'H',
          snap: minH.snap,
        })
      }
      return guides
    },
    drawGuides(guides) {
      guides.forEach(lg => {
        if (lg.orientation === 'H') {
          const line = new Konva.Line({
            points: [-6000, lg.lineGuide, 6000, lg.lineGuide],
            stroke: 'rgb(0, 161, 255)',
            strokeWidth: 1,
            name: 'guid-line',
            dash: [4, 6],
          })
          this.layer.add(line)
          this.layer.batchDraw()
        } else if (lg.orientation === 'V') {
          const line = new Konva.Line({
            points: [lg.lineGuide, -6000, lg.lineGuide, 6000],
            stroke: 'rgb(0, 161, 255)',
            strokeWidth: 1,
            name: 'guid-line',
            dash: [4, 6],
          })
          this.layer.add(line)
          this.layer.batchDraw()
        }
      })
    },
    layerDragEndEvent() {
      // clear all previous lines on the screen
      this.layer.find('.guid-line').destroy()
      this.layer.batchDraw()
    },
    renderKonva(from = 0) {
        let self = this;
        let stage, konvaPage, layer;
        const konvaContainer = document.getElementById('fields-container');
        for(let i = from; i < self.pages.length; i++){
            self.orderPagesArray[i] = i;
            konvaPage = document.createElement('div');
            konvaContainer.appendChild(konvaPage);
            stage = new Konva.Stage({
                container: konvaPage,
                width: self.pages[i].width,
                height: self.pages[i].height,
                type: 'stage',
                positionOrder: i
            });

            layer = new Konva.Layer();
            stage.add(layer);

            stage.on('click', (e) => {
                if (e.target.getAttrs().type != 'stage'){
                    return;
                }
                self.releaseSelected()
            });

            stage.on('mousedown', (e) => {
                if(e.evt.which == 3){
                    self.selectMode = true;
                    const stageActive = e.currentTarget.getAttrs().positionOrder;
                    self.stage = self.stageArray[stageActive];
                    self.layer = self.stageArray[stageActive].getLayers()[0];
                    self.selectRegion();
                }
            });

            stage.on('mouseup', (e) => {
                if(e.evt.which == 3 && self.selectMode){
                    self.selectRegion();
                }
            });

            stage.on('mousemove', evt => {
                if((self.selectMode && self.selectionArea)){
                    self.getCursorPosition(evt);
                }
            });

            layer.draw();
            self.stageArray.push(stage);
        }
    },
    selectRegion(){
        const pointerPos = this.stage.getPointerPosition();
        const mouseCoord = [Math.ceil(pointerPos.x), Math.ceil(pointerPos.y)];
        if(!this.selectionArea){
            this.selectionArea = new Konva.Rect({
                identifier: new Date().getTime(),
                type: 'selectionarea',
                x: mouseCoord[0],
                y: mouseCoord[1],
                width: 1,
                height: 1,
                stroke: 'black',
                strokeWidth: 2,
                fill: null,
                dash: [2,2],
                scaleX: 1,
                scaleY: 1,
                page: this.stage.getAttrs().positionOrder + 1
            });
            this.layer.add(this.selectionArea);
            let self = this;
            self.layer.draw();
        } else {
            this.selectElementInsideTheArea();
            this.selectionArea.destroy();
            this.selectionArea = null;
            this.layer.draw();
            this.selectMode = false;
        }
    },
    drag() {},
    dragstart(item, e) {
        this.draggingItem = item;
        this.releaseSelected();
        var crt = document.querySelector('#cloneNode');
        e.dataTransfer.setDragImage(crt, 0, 0);
      // Firefox http://stackoverflow.com/questions/21507189/dragenter-dragover-and-drop-events-not-working-in-firefox
    },
    dragend(item, e, index) {
        e.target.style.opacity = 1
        let scrollTop = document.querySelector('.main-container').scrollTop;
        let scrollLeft = document.querySelector('.main-container').scrollLeft;
        let offsetLeft = document.querySelector('#pdf-container').offsetLeft;
        let page = 0;
        let initOffset = 0;
        let endOffset = 0;
        let yPosition = scrollTop + e.pageY;
        while(endOffset < yPosition){
            initOffset = endOffset;
            endOffset += this.pages[page].height;
            page++;
        }
        const params = {
            x: scrollLeft + e.pageX - 300 - offsetLeft,
            y: scrollTop + e.pageY - initOffset - 64 - (7 * (page-1)),
            width: item.clipboard && item.width ? item.width : 120,
            height: item.clipboard && item.height ? item.height : 25
        };
        if(params.x >= 0 && params.y >= 0){
            this.propertySelection(item, params, page);
        }
        if(item.clipboard){
            this.clipboard.splice(index, 1);
        }
    },
    addFullCategoryToClipboard(category){
        for(let i = 0; i < category.length; i++){
            this.addToClipboardStr(category[i]);
        }
        this.menuTabs = 'menu-clipboard';
    },
    addFullCategory(category){
        let createdId = [];
        let newId;
        for(let i = 0; i < category.length; i++){
            let  params = this.getInputPositionParams(this.page);
            newId = new Date().getTime();
            this.createField({
                identifier: newId,
                makeSelected: true,
                isFieldNameSelected: true,
                isRequired: false,
                isReadonly: false,
                id: category[i].name,
                name: category[i].name,
                type: category[i].type,
                option: null,
                withoutIcon: true,
                isCustom: false,
                x: params.x,
                y: params.y,
                width: params.width,
                height: params.height,
                numpage: this.page
            });
            createdId.push(newId);
        }
        if(createdId.length > 0){
            this.addToStack('add', createdId);
        }
        this.calculateInputsInSpecificPage(this.page);
    },
    dragover(e) {
      e.preventDefault()
    },
    dragleave() {},
    dragenter() {},
    listenclick() {},
    goToPageNumber(index) {
        let scrollPos = this.calculateHeightFromToPage(0, index-1);
        document.querySelector('.main-container').scrollTop = scrollPos;
    },
    calculateHeightFromToPage(from, to){
        let height = 0;
        for(let i = from; i < to; i++){
            height += this.pages[i].height + 7;
        }
        return height;
    },
    orderPages(from, to, undo = false){
        this.setNewOrders(from, to);
        const newPosition = from < to ? to+1 : to;
        this.changePagesOrder(from, newPosition);
        let aux = this.pages.splice(from, 1);
        this.pages.splice(to, 0, aux[0]);
        this.moveKonvaElementsToPage(from, to);
        if(!undo){
            this.addToStack('order', {from: from, to: to});
        } else {
            this.orderLeftMenu(from, newPosition);
        }
    },
    orderLeftMenu(from, to){
        this.leftMenuKeys = this.$refs.leftmenucmp.getLeftMenuKeys();
        let movedElement = this.leftMenuKeys.splice(from, 1);
        let newTo = from < to ? to-1 : to;
        this.leftMenuKeys.splice(newTo, 0, movedElement[0]);
    },
    setNewOrders(from, to){
        for(let i = 0; i < this.orderPagesArray.length; i++){
            if(this.orderPagesArray[i] == from) {
                this.orderPagesArray[i] = to;
            }
            else if(to <= this.orderPagesArray[i] && this.orderPagesArray[i] <= from && to < from){
                this.orderPagesArray[i]++;
            } else if(from < to && this.orderPagesArray[i] <= to && from < this.orderPagesArray[i] ) {
                this.orderPagesArray[i]--;
            }
        }
        let aux = [...this.orderPagesArray];
        this.orderPagesArray = [];
        for(let i = 0; i < aux.length; i++){
            this.orderPagesArray.push(aux[i]);
        }
    },
    changePagesOrder(from, newPosition){
        const pdfPages = document.querySelector('#'+ this.pdfContainerId);
        let movedElement = pdfPages.children[from];
        let elemInNewPosition = pdfPages.children[newPosition];
        pdfPages.insertBefore(movedElement, elemInNewPosition);
    },
    moveKonvaElementsToPage(from, to){
        const newPosition = from < to ? to+1 : to;
        const konvaStages = document.querySelector('#fields-container');
        const movedElement = konvaStages.children[from];
        const elemInNewPosition = konvaStages.children[newPosition];
        konvaStages.insertBefore(movedElement, elemInNewPosition);
        let aux = this.stageArray.splice(from, 1);
        this.stageArray.splice(to, 0, aux[0]);
        this.updateKonvaStageOrder();
    },
    updateKonvaStageOrder() {
        let attrs, changes;
        for(let i = 0; i < this.stageArray.length; i++){
            this.stageArray[i].attrs.positionOrder = i;
            this.layer = this.stageArray[i].getLayers()[0];
            changes = false;
            for(let j = 0; j < this.layer.children.length; j++){
                attrs = this.layer.children[j].getAttrs();
                if(attrs.page >= 0 && attrs.page != i+1){
                    this.layer.children[j].setAttrs({page: i+1});
                    changes = true;
                }
            }
            if(changes){
                this.stageArray[i].draw();
            }
        }
    },
    removeSelectedPage(index, lmkeys){
        const page = index ? index-1 : this.page-1;
        const pdfPages = document.querySelector('#'+this.pdfContainerId);
        const pdfPage = pdfPages.children[page];
        pdfPages.children[page].remove();
        const konvaStages = document.querySelector('#fields-container');
        const konvaST = konvaStages.children[page];
        konvaStages.children[page].remove();
        const stage = this.stageArray.splice(page, 1);
        const pageSettings = this.pages.splice(page, 1);
        const origPage = this.getOriginalPageInPosition(page);
        const orderPagesArr = [...this.orderPagesArray];
        for(let i = 0; i < this.orderPagesArray.length;i++){
            if(this.orderPagesArray[i] > page){
                this.orderPagesArray[i]--;
            }
        }
        this.orderPagesArray.splice(origPage, 1, -1);
        this.numPages -= 1;
        this.leftMenuKeys = [...lmkeys];
        const deletedKey = this.leftMenuKeys[page];
        const aux = this.leftMenuKeys.splice(page, 1);
        this.leftMenuKeys.push(aux[0]);
        this.updateKonvaStageOrder();
        this.page = this.pages.length > page ? page+1 : this.pages.length;

        const backup = {
            pdfPage: pdfPage,
            page: page,
            konvaST: konvaST,
            stage: stage[0],
            pageSettings: pageSettings[0],
            orderPagesArray: orderPagesArr,
            lmkey: deletedKey
        }
        this.trashcan.push(backup);
        //this.numPages--;
        this.addToStack('deletePage', {order: index-1, docpage: page, trashIdx: this.trashcan.length-1});
        if (this.numPages === 0) {
            this.deleteForms();
        }
    },
    getOriginalPageInPosition(search){
        let page = null;
        for(let i = 0; i < this.orderPagesArray.length && page == null; i++){
            if(this.orderPagesArray[i] == search){
                page = i;
            }
        }
        return page;
    },
    addMarkedPage(markedPages){
        this.markedPages = [...markedPages];
    },
    deleteMarkedPages(){
        const lmkeys = this.$refs.leftmenucmp.getLeftMenuKeys();
        this.leftMenuKeys = [...lmkeys];
        let pages = [];
        for(let i = 0; i < this.markedPages.length; i++){
            pages[i] = this.markedPages[i].index+1;
        }
        if(pages.length > 1){
            pages = pages.sort((a, b) => {
                if (a > b){
                    return -1;
                } else {
                    return 1;
                }
            });
        }
        for(let i = 0; i < pages.length; i++){
            this.removeSelectedPage(pages[i], this.leftMenuKeys);
        }
    },
    docdragover(event){
        event.preventDefault();
        if(!event.currentTarget.classList.contains('dragactive')){
            event.currentTarget.classList.add('dragactive');
        }
    },
    docdragleave(event){
        if(!this.isLoading && event.currentTarget.classList.contains('dragactive')){
            event.currentTarget.classList.remove('dragactive');
        }
    },
    docdrop(event){
        event.preventDefault();
        if(!this.isLoading){
            this.uploadNewFile(event.dataTransfer.files);
            event.currentTarget.classList.remove('dragactive');
        }
    },
    uploadNewFile(files) {
        this.releaseSelected();
        try {
            let filesAdded = [];
            for(let i = 0; i < files.length; i++) {
                let file = files[i];
                if (file.type.indexOf('pdf') > -1) {
                    filesAdded.push(file);
                }
            }
            if(filesAdded.length > 0){
                this.uploadFiles(filesAdded, true);
            }
        } catch (e) {
            console.error("Error catch appending file");
            console.error(e);
        }
    },
    appendDocument(fileInfo){
        const self = this;
        let loadingTask;
        try {
            self.isLoading = true;
            const binaryData = {
                data: atob(fileInfo.data)
            };
            loadingTask = pdfjsLib.getDocument(binaryData);
        } catch (e) {
            console.error("Error catched while get pageswerw");
            console.error(e);
        }
        loadingTask.promise.then((pdf) => {
                let renderPromises;
                let getPromises;
            try {
                getPromises = new Array(pdf._pdfInfo.numPages);
                renderPromises = new Array(pdf._pdfInfo.numPages);
                const annotationsPromises = new Array(pdf._pdfInfo.numPages);
                self.annotationLayer.push(...annotationsPromises);
                for (let i = 1, j = 0; i <= pdf._pdfInfo.numPages; i++, j++) {
                    getPromises[j] = self.getPageData(pdf, i);
                }
            } catch (e) {
                console.error("Error catched while get pages");
                console.error(e);
            }
            Promise.all(getPromises).then((response) => {
                for(let k = 0; k < response.length; k++){
                    try {
                        renderPromises[k] = self.renderPage(response[k].page, response[k].rotation);
                    } catch (e) {
                        console.error("An error rendering page in the API happened, page number: " + response[k].number);
                        console.error(e);
                    }
                }
                Promise.all(renderPromises).then(() => {
                    try {
                        const initialIndex = self.numPages;
                        self.numPages += pdf._pdfInfo.numPages;
                        self.renderKonva(initialIndex);
                        self.renderAnnotations(initialIndex);
                        self.updateDocumentStatus(fileInfo.name, 'completed');
                        if (self.documentQueue.length == 0) {
                            self.isLoading = false;
                            self.documentKey = new Date().getTime();
                            //if (self.$refs.leftmenucmp) {
                            //    self.$refs.leftmenucmp.updatePages();
                            //}
                        } else {
                            const next = self.documentQueue.splice(0, 1);
                            self.updateDocumentStatus(next[0].name, 'processing');
                            self.appendDocument(next[0]);
                        }
                    } catch (e) {
                        console.error("Error catched when upload pages");
                        console.error(e);
                    }
                });
            });
        },
        function(reason) {
            self.updateDocumentStatus(fileInfo.name, 'error');
            console.error(reason)
        });
    },
    updateDocumentStatus(name, newStatus){
        let idx = this.uploadedFileList.findIndex(x => x.name == name);
        if(idx >= 0) {
            let uploadedFile = {...this.uploadedFileList[idx]};
            uploadedFile.status = newStatus;
            this.uploadedFileList.splice(idx, 1, uploadedFile);
        }
    },
    changeFontSelected(option){
        this.settings.fontSize = option;
    },
    convertLabelType(oldAttrs, newAttrs){
        const newParams = {
            identifier: oldAttrs.identifier,
            isFieldNameSelected: true,
            isRequired: oldAttrs.required ? oldAttrs.required : false,
            isReadonly: oldAttrs.readOnly ? oldAttrs.readOnly : false,
            id: newAttrs.name,
            name: newAttrs.name,
            type: newAttrs.type,
            option: newAttrs.option,
            withoutIcon: true,
            isCustom: newAttrs.custom,
            numpage: oldAttrs.page,
            x: oldAttrs.x,
            y: oldAttrs.y,
            height: oldAttrs.height,
            width: oldAttrs.width
        };
        this.layer.children.splice(this.selectedIndex, 2);
        this.layer.draw();
        this.createField(newParams);
    },
    isAnyMenuOpened(focusedElem){
        let isOpened = false;
        if(this.showDialog || this.transferLabelsDialog || (focusedElem && focusedElem.id == 'field-max-lenght')){
            isOpened = true;
        }
        return isOpened;
    },
    displayTransferDialog(){
        this.transferLabel.from = this.selectedLabel.getAttrs().page;
        this.isValidTransferLabelForm = false;
        this.transferLabelsDialog = true;
    },
    addToClipboardStr(item){
        this.clipboard.push({...item, clipboard: true});
    },
    uploadFiles(files, concat = false){
        let self = this;
        let endpoint, fileData, name, parts;
        name = '';
        if(concat) {
            endpoint = 'concatenateDocuments';
            fileData = new FormData();
            fileData.append("id", self.pdfData.id);
            fileData.append("isoriginal", false);
            files.forEach(file => {
                fileData.append("files", file);
            });
        } else {
            if (files.length == 1) {
                endpoint = 'uploadDocument';
                const file = files[0];
                parts = files[0].name.split(".");
                for (let i = 0; i < parts.length - 1; i++) {
                    name += parts[i];
                }
                name += "cc" + new Date().getTime() + '.pdf';
                fileData = new FormData();
                fileData.append("documentName", name);
                fileData.append("file", file);
            } else {
                endpoint = 'appendDocuments';
                fileData = new FormData();
                parts = files[0].name.split(".");
                for (let i = 0; i < parts.length - 1; i++) {
                    name += parts[i];
                }
                name += "cc" + new Date().getTime() + '.pdf';
                fileData.append("documentName", name);
                fileData.append("isoriginal", true);
                files.forEach(file => {
                    fileData.append("files", file);
                });

            }
        }
        const headers = {
            headers: {
                Authorization: 'Bearer ' + localStorage.token
            }
        };
        axios.post(self.apiBaseUrl + 'codingtool/' + endpoint, fileData, headers).then((response) => {
            if(response.data.url){
              const propertyItemBak = {
                name: self.selectedPropertyName,
                id: self.selectedPropertyId,
                type: self.selectedType
              }
              self.closeSavedPdf();
              self.SET_PDF_DATA(response.data);
              self.SET_PDF_URL(response.data.url);
              self.isLoading = true;
              self.showStartModule = false;
              self.renderPDF(response.data.url);
              self.selectedPropertyName = propertyItemBak.name;
              self.selectedPropertyId = propertyItemBak.id;
              self.selectedType = propertyItemBak.type;
            }
        }).catch(() => {
            self.displayError('Document could not be uploaded');
            if(self.showStartModule){
                self.stopLoadingDialog.splice(0, 1, new Date().getTime());
            }

        });
    },
    getPendingDocumentDetails(item){
        let self = this;
        const headers = {
            headers: {
                Authorization: 'Bearer ' + localStorage.token
            }
        };
        axios.get(self.apiBaseUrl + 'codingtool/getPendingDocument?id=' + item.id, headers).then((response) => {
            if(response.data){
                self.closeSavedPdf();
                self.SET_PDF_DATA(response.data);
                self.SET_PDF_URL(response.data.url);
                self.showSearchDocumentsModule = false;
                self.isLoading = true;
                self.renderPDF(response.data.url);
            }
        }).catch(() => {
            self.displayError('Pending document could not be loaded');
        });
    },
    importFileFromProperty(item){
        let self = this;
        const headers = {
            headers: {
                Authorization: 'Bearer ' + localStorage.token
            }
        };
        const itemData = new FormData();
        for(let key in item){
            itemData.append(key, item[key]);
        }

        axios.post(self.apiBaseUrl + 'codingtool/importDocument', itemData, headers).then((response) => {
            if(response.data){
                self.closeSavedPdf();
                self.SET_PDF_DATA(response.data);
                self.SET_PDF_URL(response.data.url);
                self.closeStartDialog();
                self.isLoading = true;
                self.renderPDF(response.data.url);
                self.selectedPropertyName = item.nameProperty;
                self.selectedPropertyId = item.idProperty;
                self.selectedType = item.type;
            }
        }).catch(() => {
            self.displayError('Document could not be imported');
            self.stopLoadingDialog.splice(0, 1, new Date().getTime());
            self.selectedPropertyName = null;
            self.selectedPropertyId = null;
            self.selectedType = null;
        });
    },
    deleteForms() {
      let self = this;
      this.removeFormPdfDialog = false;
      const headers = {
        headers: {
          Authorization: 'Bearer ' + localStorage.token
        }
      };
      axios.delete(self.apiBaseUrl + 'codingtool/forms/' + self.pdfData.id, headers).then((response) => {
        if (response.data) {
          const propertyItemBak = {
            name: self.selectedPropertyName,
            id: self.selectedPropertyId,
            type: self.selectedType
          }
          self.SET_PDF_DATA(response.data);
          self.SET_PDF_URL(response.data.url);
          self.displaySuccess('Forms removed correctly');
          self.isLoading = true;
          this.submitData = {
            idProperty: propertyItemBak.id,
            type: propertyItemBak.type,
          };
          this.compleSubmitDocument(confirm);
          self.closeSavedPdf();
          self.isLoading = false;
        }
      }).catch(() => {
        self.displayError('Form pages could not be removed');
      });
      },
      saveDocument() {
        this.storeChanges();
        this.$nextTick(() => {
            this.saveDocumentChanges();
        });
      },
    saveDocumentChanges() {

        let self = this;
        const headers = {
            headers: {
                Authorization: 'Bearer ' + localStorage.token
            }
        };
        const itemData = {
            fileData: self.pdfData.id,
            coding: self.pdfChanges
        };

        axios.post(self.apiBaseUrl + 'codingtool/saveDocument', itemData, headers).then((response) => {
            if (response.data) {
                self.SET_PDF_DATA(response.data);
                self.SET_PDF_URL(response.data.url);
                self.displaySuccess('Document saved correctly');
                self.actionsStack = [];
                if(self.pdfUrl) {
                    self.submitDisabled = self.isOriginalDocument(self.pdfUrl);
                }
            }
        }).catch(() => {
            self.displayError('Document could not be saved');
        });
    },
    displaySuccess(message){
        const self = this;
        self.successMessage = message;
        self.displayAlert = true;
        setTimeout(() => {
            self.displayAlert = false;
            self.successMessage = '';
        }, 4000);
    },
    displayError(message){
        const self = this;
        self.errorMessage = message;
        self.displayErrorAlert = true;
        setTimeout(() => {
          self.displayErrorAlert = false;
          self.errorMessage = '';
        }, 4000);
    },
    resetDocument(){
      let self = this;
      self.displayResetDialog = false;
      const headers = {
          headers: {
              Authorization: 'Bearer ' + localStorage.token
          }
      };
      const itemData = {
          id: self.pdfData.id
      };

      axios.post(self.apiBaseUrl + 'codingtool/resetDocument', itemData, headers).then((response) => {
          if(response.data && response.data.url){
              const propertyItemBak = {
                name: self.selectedPropertyName,
                id: self.selectedPropertyId,
                type: self.selectedType
              }
              self.closeSavedPdf();
              self.SET_PDF_URL(response.data.url);
              self.SET_PDF_DATA(response.data);
              self.isLoading = true;
              self.renderPDF(response.data.url);
              self.selectedPropertyName = propertyItemBak.name;
              self.selectedPropertyId = propertyItemBak.id;
              self.selectedType = propertyItemBak.type;
          }
      }).catch(() => {
          self.displayError('Document could not be restored');
      });
    },
    compleSubmitDocument(confirm){
        if(!confirm){
          this.showConfirmSubmitDialog = false;
          return;
        }
        let self = this;
        self.showConfirmSubmitDialog = false;
        const headers = {
          headers: {
              Authorization: 'Bearer ' + localStorage.token
          }
        };
        const itemData = new FormData();
        itemData.append('idPendingDocument', self.pdfData.id);
        for(let key in self.submitData){
            itemData.append(key, self.submitData[key]);
        }

        axios.post(self.apiBaseUrl + 'codingtool/submitDocument', itemData, headers).then((response) => {
          if(response.data ){
              self.submitData = null;
              self.customMessage = {
                  message: 'The document has been assigned and submitted successfully'
              };
              self.showInfoMessage = true;
              self.showSubmitDialog = false;
              self.showConfirmDialog = false;
          }
        }).catch(() => {
            self.displayError('Document could not be submitted');
        });
    },
    downloadWorkingDocument(docName){
        let self = this;
        const headers = {
            headers: {
                Authorization: 'Bearer ' + localStorage.token
            }
        };
        const itemData = new FormData();
        itemData.append('id', self.pdfData.id);
        axios.post(self.apiBaseUrl + 'codingtool/downloadDocument', itemData, headers).then((response) => {
            if(response.data && response.data.result){
                self.stopLoadingDialog.splice(0, 1, new Date().getTime());
                self.collectDownloadedFile(docName, response.data.result);
                self.downloadConfirmed();
            }
        }).catch(() => {
            self.displayError('Document could not be downloaded');
            self.stopLoadingDialog.splice(0, 1, new Date().getTime());
        });

    }
  },
  mounted() {
    if (this.pdfUrl != null) {
      this.uploadPrevPdfDialog = true;
    }

    const self = this;
    document.getElementsByClassName("main-container")[0].addEventListener("scroll", (e) => {
        let end = false;
        let i = 1;
        let pageHeight;
        do {
            pageHeight = 0;
            for(let j = 0; j < i; j++){
                pageHeight += self.pages[j].height;
            }
            if(e.target.scrollTop < (pageHeight - 250)){
                self.page = i;
                end = true;
            }
            i++;
        } while (i <= self.numPages && !end);
    });

  },
}
</script>
<style>
body {
  background: #e2e1e0;
}

.coding-toolbar {
  z-index: 5;
  background-color: #373041 !important;
  color: #fff !important;
}

.main-container {
  margin-left: 0;
  position: relative;
  overflow-y: auto;
  overflow-x: auto;
  display: flex !important;
  justify-content: center !important;
}

.pdf-canvas {
  margin-bottom: 0;
  clear: both;
  display: block;
  outline: 1px solid #ececec;
}

#fields-container {
  position: absolute;
  top: 0;
}

#fields-container > div:not(:last-child) {
    margin-bottom: 7px;
}

.p-fixed {
  position: fixed;
}

.p-absolute {
  position: absolute;
}

.preview-bg {
    background-color: #f8f8f8;
}

.upload-left-menu {
    height: 55vh;
    flex: 1;
    flex-flow: column;
    background-color: #E8EAF6;
    padding: 1em 0;
}

.dropzone.dragactive {
    background-color: #FFE082;
}

.dropzone-info {
    display: flex;
    padding: 2em 1em 0 1em;
    height: 100%;
    justify-content: space-around;
    border: 2px dashed orange;
    margin: 0 1em;
    text-align: center;
    flex-direction: column;
}

.grab {
  cursor: -webkit-grab;
  cursor: grab;
}

.grabbing {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.property-color {
  background-color: #ebebeb;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.right-side-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
  padding-left: 10px;
}
.v-treeview-node__label {
  font-size: 14px;
}
.suggestions-holder {
  max-height: 200px;
  overflow-y: auto;
  padding-bottom: 5px;
}

[draggable] {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  /* Required to make elements draggable in old WebKit */
  -khtml-user-drag: element;
  -webkit-user-drag: element;
}

#cloneNode {
  min-width: 120px;
  /*padding: 4px;*/
  padding-left: 30px;
  position: absolute;
  top: 0;
  right: -1000px;
  z-index: 2;
  background-color: #6cc070;
  color: #000;
  height: 25px;
}

#cloneNode.dragable-custom {
    background-color: #2196F3;
}

.grabbable {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.pdf-canvas {
  display: inline-block;
}
#pdf-container {
  width: min-content;
}

.v-content.coding-wrap {
  padding: 0px 300px 0px !important;
}

.coding-wrap .v-content__wrap {
  margin: 0 0;
  max-width: fit-content;
  min-width: 930px;
}

.coding-left-drawer {
  width: 300px!important;
}
.coding-left-drawer .v-label {
  font-size: 14px!important;
}
.coding-left-drawer .v-list__tile{
  font-size: 14px!important;
  padding: 0 0 0 16px;
}
.coding-left-drawer .v-list__tile__action {
  min-width: 20px!important;
}
.coding-left-drawer .v-tabs__wrapper {
  margin-left: 0px!important;
  margin-right: 0px!important;
}

.coding-left-drawer .v-expansion-panel__header {
    padding: 0 24px;
}

.coding-left-drawer .v-expansion-panel__header > div {
    font-size: 14px;
}

.coding-left-drawer .v-expansion-panel__header > i {
    max-width: 40px;
}

.coding-right-drawer {
  width: 240px!important;
}

.coding-right-drawer .v-card__text {
  font-size: 14px!important;
  padding: 12px 8px !important;
}

.coding-right-drawer .v-input--selection-controls {
  margin-top: 0px;
}

.coding-right-drawer .v-toolbar__content {
  height: 40px!important;
}

.loading-container {
    z-index: 1;
}

.page-numeration {
    display: flex;
    position: absolute;
    width: 97%;
    justify-content: center;
    pointer-events: none;
}

.v-tabs__div {
    flex-grow: 1 !important;
}

.submenu-tabs > a {
    justify-content: space-between;
}

.rotate-y-180 {
    transform: rotateY(180deg);
}

.rotate-90 {
    transform: rotate(90deg);
}

.upload-tab {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: calc(100vh - 120px);
}

.uploaded-section {
    background-color: beige;
    flex: 1;
}

.uploaded-file-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 1em;

}

.font-icon {
    font-size: 24px !important;
}

.fab-action-button {
    width: 40px;
    height: 40px;
}

.flex-column {
    flex-direction: column;
}

.v-alert {
    position: fixed !important;
    z-index: 10;
    top: 1em;
    right: 1em;
}
</style>
