<template>
    <div class="right-side-scroll">
        <v-card class="my-2">
            <v-card-text>
                <v-layout row>
                    <v-flex xs4 class="type-container">
                        <div v-for="(color,index) of selectedLabelColor"
                             :key="index+1"
                             class="property-color"
                             :style="{backgroundColor: color}"
                        ></div>
                    </v-flex>
                    <v-flex xs8>
                        <div>
                            <span>
                                {{selectedElementsArray.length}} selected elements
                            </span>
                        </div>
                    </v-flex>
                </v-layout>
                <v-layout row pt-1>
                    <v-flex xs12>
                        <v-checkbox
                            color="primary"
                            v-model="defaultAttributes.required"
                            label="Required"
                        />
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex xs12>
                        <v-checkbox
                            color="primary"
                            v-model="defaultAttributes.readOnly"
                            label="Read-only"
                        />
                    </v-flex>
                </v-layout>
                <v-layout row v-if="!allSelectedAreStatic">
                    <v-flex xs12>
                        <v-select
                            :items="inputTypes"
                            v-model="defaultAttributes.type"
                            label="Input type"
                        />
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex xs12>
                        <v-select
                            :items="fontSizeValues"
                            v-model="defaultAttributes.fontSize"
                            label="Font size"
                        />
                    </v-flex>
                </v-layout>
                <v-layout v-if="allowExtraFeatures" row>
                    <v-flex xs12>
                        <v-select
                            :items="textFieldTypes"
                            v-model="defaultAttributes.textType"
                            label="Text Type"
                        />
                    </v-flex>
                </v-layout>
                <v-layout v-show="allowExtraFeatures" row>
                    <v-flex xs12>
                        <v-text-field
                            v-model="defaultAttributes.maxLength"
                            id="field-max-lenght"
                            label="Max Length"
                            mask="###"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout v-if="!allowExtraFeatures || allSelectedAreStatic" mb-2>
                    <v-flex xs12>
                        <div>
                            <span>Some features are not available for your selected elements</span>
                        </div>
                    </v-flex>
                </v-layout>
                <!--<v-layout v-if="forbiddenElements.length > 0 ">
                    <v-flex xs12>
                        <div>
                            <span>Some settings can not be applied for some elements</span>
                        </div>
                    </v-flex>
                </v-layout>-->

            </v-card-text>

            <div>
                <v-layout row justify-space-around class="mb-2">
                    <v-btn
                        dark
                        color="success"
                        @click="applyChanges"
                        fab
                        class="fab-action-button">
                        <v-icon class="font-icon">done</v-icon>
                    </v-btn>
                    <v-btn dark @click="deleteInput" fab class="orange lighten-1 fab-action-button">
                        <v-icon class="font-icon">delete_outline</v-icon>
                    </v-btn>
                    <v-btn dark @click="displayTransferDialog" fab class="teal lighten-1 fab-action-button">
                        <v-icon class="font-icon">low_priority</v-icon>
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="white--text fab-action-button"
                        outline
                        fab
                        depressed
                        @click="duplicateInput">
                        <v-icon class="font-icon">content_copy</v-icon>
                    </v-btn>
                </v-layout>
            </div>
            <v-tabs
                centered
                color="indigo"
                dark
                icons-and-text
            >
                <v-tabs-slider color="yellow"></v-tabs-slider>
                <v-tab href="#tab-1">
                    Align
                    <v-icon>align_vertical_top</v-icon>
                </v-tab>
                <v-tab href="#tab-2">
                    Match
                    <v-icon>format_line_spacing</v-icon>
                </v-tab>
                <v-tab-item
                    v-for="(actionGroup,i) in positionAlternatives"
                    :key="i"
                    :value="'tab-' + (i+1)"
                >
                    <v-list-tile v-for="(action, idx) in actionGroup" :key="idx" ripple @click="positionAction(action.name)">
                        <v-list-tile-action>
                            <v-icon color="teal">{{ action.icon }}</v-icon>
                        </v-list-tile-action>
                        <v-list-tile-content>
                            <v-list-tile-title>{{ action.name }}</v-list-tile-title>
                        </v-list-tile-content>
                    </v-list-tile>
                    <!--<v-card flat>
                        <v-card-text>{{ 'Hola' }}</v-card-text>
                    </v-card>-->
                </v-tab-item>
            </v-tabs>

            <!--<v-card class="mb-2">
                <v-card-title class="px-2">
                    <span>Suggestions</span>
                </v-card-title>
                <v-card-text class="suggestions-holder">
                    <v-chip
                        @click="propertySelection(sugProp)"
                        v-for="(sugProp,propIndex) in propertiesMatches"
                        :key="propIndex"
                    >{{sugProp.name}}</v-chip>
                </v-card-text>
            </v-card>-->
        </v-card>
    </div>
</template>

<script>

import { ALIGN_ACTIONS, MATCH_ACTIONS } from '@/libs/constants'

export default {
    name: 'MultiElementEditor',
    data() {
        return {
            defaultAttributes: {
                required: false,
                readOnly: false,
                fontSize: null,
                type: null,
                textType: null,
                maxLength: null
            },
            selectedlabelsAttributes: {},
            selectedLabelColor: [],
            forbiddenElements: [],
            inputTypes: ['Text', 'Signature', 'Checkbox', 'Radio', 'Initials', 'Date'],
            positionAlternatives: [
                [
                    {
                        name: ALIGN_ACTIONS.ALIGN_TOP,
                        icon: 'align_vertical_top'
                    },
                    {
                        name: ALIGN_ACTIONS.ALIGN_BOTTOM,
                        icon: 'align_vertical_bottom'
                    },
                    {
                        name: ALIGN_ACTIONS.ALIGN_CENTER_VERTICALLY,
                        icon: 'align_horizontal_center'
                    },
                    {
                        name: ALIGN_ACTIONS.ALIGN_CENTER_HORIZONTALLY,
                        icon: 'align_vertical_center'
                    }
                ],
                [
                    {
                        name: MATCH_ACTIONS.MATCH_BOX,
                        icon: 'format_line_spacing'
                    },
                    {
                        name: MATCH_ACTIONS.MATCH_WIDTH,
                        icon: 'swap_horiz'
                    },
                    {
                        name: MATCH_ACTIONS.MATCH_HEIGHT,
                        icon: 'swap_vert'
                    },
                ]
            ],
            staticElements: []
        }
    },
    props: {
        layer: {
            required: true
        },
        selectedElementsArray: {
            required: true
        },
        fontSizeValues: {
            required: true
        },
        textFieldTypes: {
            required: true
        },
        forbiddenTypes: {
            required: true
        }
    },
    created() {
        this.initialize();
    },
    computed: {
        allowExtraFeatures(){
            let allow = false;
            if(this.forbiddenElements.length == 0 || (this.defaultAttributes.type && this.forbiddenTypes.indexOf(this.defaultAttributes.type) == -1)){
                allow = true;
            }
            return allow;
        },
        allSelectedAreStatic(){
            return this.staticElements.length == this.selectedElementsArray.length;
        }
    },
    methods: {
        initialize(){
            this.selectedElementsArray.forEach((identifier) => {
                if(!this.selectedlabelsAttributes[identifier]) {
                    let label = this.getSelectedLabel(identifier);
                    this.selectedlabelsAttributes[identifier] = label;
                    if (this.selectedLabelColor.indexOf(label.children[0].getAttrs().fill) == -1) {
                        this.selectedLabelColor.push(label.children[0].getAttrs().fill);
                    }
                    if (this.forbiddenTypes.indexOf(label.getAttrs().type) > -1) {
                        this.forbiddenElements.push(identifier);
                    }
                    if(label.getAttrs().custom == false){
                        this.staticElements.push(identifier);
                    }
                }
            });
        },
        getSelectedLabel(identifier) {
            let matched = this.layer.getChildren((node) => {
                return node.getAttrs().identifier == identifier;
            });
            return matched != null && matched.length > 0 ? matched[0] : null
        },
        deleteInput(){
            this.$emit('deleteInput');
        },
        duplicateInput(){
            this.$emit('duplicateInput');
        },
        applyChanges(){
            const filtered = {};
            Object.keys(this.defaultAttributes).forEach((key) => {
                if (this.defaultAttributes[key] != null) {
                    filtered[key] = this.defaultAttributes[key];
                }
            });
            this.$emit('onAttributeChanged', filtered);
        },
        displayTransferDialog() {
            this.$emit('displayTransferDialog');
        },
        positionAction(name) {
            this.$emit('positionAction', name);
        }
    },
    watch: {
        selectedElementsArray: function(){
            this.initialize();
        }
    },
}

</script>

<style scoped>

.type-container {
    position: relative;
    height: 50px;
}

.property-color {
    position: absolute;
}

.property-color:nth-child(2){
    left: 20px
}

.v-tabs__container--icons-and-text .v-tabs__div {
    min-width: 70px;
}

.v-tabs__container--icons-and-text {
    height: 55px;
}

</style>
