<template>
    <ul ref="PreviewList">
        <li v-for="(page,index) of pageList" :key="index+1" :data-id="index+1">
            <PreviewPage  
                :idx="index+1" 
                :selectedPage="selectedPage"
                :page="page"
                :pagesMap="pagesMap"
                @pageClicked="pageClicked"
                @pageDeleted="pageDeleted"
                >
            </PreviewPage>
        </li>
    </ul>
</template>

<script>

    import PreviewPage from './PreviewPage';
    import {Sortable, MultiDrag} from 'sortablejs';

    export default {
        name: 'PreviewMenu',
        components: {
            PreviewPage
        },
        data() {
            return {
                pageList: [],
                orderPages: []
            }
        },
        props: {
            selectedPage: {
              type: Number,
              default: 1
            },
            sortableKeys: {
              type: Array
            },
            pagesMap: {
              type: Array,
            }
        },
        created() {
            this.pageList = [...document.querySelector('#pdf-container').children];
            Sortable.mount(new MultiDrag());
        },
        mounted(){

            this.sortingTable = new Sortable(this.$refs.PreviewList, {
                animation: 150,
                multiDrag: true,
                selectedClass: 'sortable-selected',
                multiDragKey: 'CTRL',
                onUpdate: (evt) => {
                    if(evt.items.length > 0){
                        const oldPosArray = evt.oldIndicies[0].index > evt.newIndicies[0].index ? [...evt.oldIndicies] : evt.oldIndicies.reverse();
                        const newPosArray = evt.oldIndicies[0].index > evt.newIndicies[0].index ? [...evt.newIndicies] : evt.newIndicies.reverse();
                        for(let i = 0; i < newPosArray.length; i++){
                            this.$emit('pageNumberUpdated', oldPosArray[i].index, newPosArray[i].index);
                        }
                    } else {
                        this.$emit('pageNumberUpdated', evt.oldIndex, evt.newIndex);
                    }
                },
                onSelect: (evt) => {
                    this.changePageSelection(evt.newIndicies);
                },
                onDeselect: (evt) => {
                    this.changePageSelection(evt.newIndicies);
                }
            });
        },
        watch: {
            sortableKeys: function(){
                this.sortingTable.sort(this.sortableKeys);
            },
        },
        methods: {
            pageClicked(index){
                this.$emit('pageClicked', index);
            },
            pageDeleted(index){
                this.$emit('pageDeleted', index, this.sortingTable.toArray());
            },
            changePageSelection(selectedPages){
                this.$emit('pageSelected', selectedPages);
            },
            getLeftMenuKeys(){
                return this.sortingTable.toArray();
            },
            updatePages(){
                console.log("Updating here!");
                this.pageList = [...document.querySelector('#pdf-container').children];
            }
        }
    }
</script>

<style scoped>
  ul {
    padding: 0;
    list-style-type: none;
    width: fit-content;
    margin: 0 auto;
  }
</style>