<template>
    <div class="preview-slide" :class="{'d-none': deleted}" @mouseover="hover = true" @mouseleave="hover = false">
        <v-icon class="close-icon" :class="{'hover': hover}" @click="deleteSlide">close</v-icon>
        <img :src="content" :key="idx" :class="{'selected': pageNumber == selectedPage, 'landscape': landscapePage}" @click="pageClicked($event)"/>
        <div>
            <span>{{ pageNumber }}</span>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'PreviewPage',
        props: {
            selectedPage: {
                required: true
            },
            idx: {
                required: true
            },
            page: {
                required: true 
            },
            pagesMap: {
              type: Array,
            }
        },
        data() {
            return {
                content: null,
                pageNumber: null,
                landscapePage: false, 
                hover: false,
                deleted: false
            }
        },
        watch: {
            pagesMap: function(){
                if(!this.deleted && this.pagesMap[this.idx-1] == -1){
                    this.deleted = true;
                } else {
                    this.pageNumber = this.pagesMap[this.idx-1]+1;
                    if(this.deleted && this.pagesMap[this.idx-1] >= 0){
                        this.deleted = false;
                    }
                }
            }
        },
        created() {
            this.content = this.page.toDataURL();
            this.pageNumber = this.idx;
            /*if(this.pageSettings.width > this.pageSettings.height){
                this.landscapePage = true;
            }*/
        },
        methods: {
            pageClicked: function(evt){
                if(!evt.ctrlKey && !evt.metaKey){
                    this.$emit('pageClicked', this.pageNumber);
                }
                
            },
            /*setLandscape(){
                this.landscapePage = false;
                if(this.pageSettings.width > this.pageSettings.height || [90,270].indexOf(this.pageSettings.rotation) > -1){
                    this.landscapePage = true;
                }
            },*/
            deleteSlide(){
                this.$emit('pageDeleted', this.pageNumber);
            }
        }

    }

</script>

<style scoped>
    .preview-slide {
        cursor: pointer;
        margin: 1em auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        width: 100%;
    }

    img {
        width: 116px;
        height: 149px;
        border: 5px solid transparent;
        border-radius: 5px;
    }

    img.landscape {
        width: 149px;
        height: 116px;
    }

    img.selected {
        border-color: #ffa500;
    }

    .sortable-selected img {
        border-color: #3949AB !important;
    }

    span {
        font-size: 0.8em;
    }

    .close-icon {
        position: absolute;
        top: -0.2em;
        right: -0.2em;
        cursor: pointer;
        width: 15px;
        color: red;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s linear;
    }

    .close-icon.hover {
        visibility: visible;
        opacity: 1;
    }

</style>