<template>
    <div>
        <v-data-table
            :headers="columns"
            :items="elements"
            class="elevation-1 my-3"
            hide-actions
        >
            <template v-slot:items="props">
                <td v-for="(header, index) of getColumns" :key="'cell'+index" class="text-xs-center">
                    {{ header.type == 'date' ? parseDate(props.item[header.value]) : props.item[header.value] }}
                </td>
                <td class="justify-center layout px-0">
                    <v-icon class="pointer" @click="selectItem(props.item)">arrow_forward</v-icon>
                </td>
            </template>
            <template v-slot:no-data>
                <div class="text-xs-center">No results found</div>
            </template>
        </v-data-table>
        <div class="text-xs-center pt-2">
            <v-pagination circle v-model="pageSelected" :length="pages" total-visible="5" @input="pageChanged"></v-pagination>
        </div>
    </div>
</template>


<script>
    export default {
        name: 'DataTable',
        data() {
            return {
                step: 1,
                propertyId: '',
                isLoading: false,
                fileAdded: {},
                docType: null,
                types: ["Lease", "Purchase", "Guest"],
                searchResults: {},
                pageSelected: null,
                pages: null
            }
        },
        props: {
            columns: {
                type: Array
            },
            elements: {
                type: Array
            },
            state: {
                type: Object
            }
        },
        created() {
            this.pageSelected = this.state.currentPage;
            this.pages = this.state.totalPages;
        },
        computed: {
            getColumns() {
                return this.columns.filter(col => col.text != "");
            }
        },
        watch: {
            pagination: function(){
                if(this.pagination.page != this.state.currentPage){
                    this.$emit('changePage', this.pagination);
                }
            }
        },
        methods: {
            selectItem(item){
                this.$emit('itemSelected', item);
            },
            pageChanged(){
                this.$emit('changePage', this.pageSelected);
            },
            parseDate(value){
                const parts = value.split(/T|\./);
                return parts[0] + " " + parts[1];
            }
        }
    }
</script>