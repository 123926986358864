var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "elevation-1 my-3",
        attrs: {
          headers: _vm.columns,
          items: _vm.elements,
          "hide-actions": "",
        },
        scopedSlots: _vm._u([
          {
            key: "items",
            fn: function (props) {
              return [
                _vm._l(_vm.getColumns, function (header, index) {
                  return _c(
                    "td",
                    { key: "cell" + index, staticClass: "text-xs-center" },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            header.type == "date"
                              ? _vm.parseDate(props.item[header.value])
                              : props.item[header.value]
                          ) +
                          "\n            "
                      ),
                    ]
                  )
                }),
                _c(
                  "td",
                  { staticClass: "justify-center layout px-0" },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "pointer",
                        on: {
                          click: function ($event) {
                            return _vm.selectItem(props.item)
                          },
                        },
                      },
                      [_vm._v("arrow_forward")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "no-data",
            fn: function () {
              return [
                _c("div", { staticClass: "text-xs-center" }, [
                  _vm._v("No results found"),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "text-xs-center pt-2" },
        [
          _c("v-pagination", {
            attrs: { circle: "", length: _vm.pages, "total-visible": "5" },
            on: { input: _vm.pageChanged },
            model: {
              value: _vm.pageSelected,
              callback: function ($$v) {
                _vm.pageSelected = $$v
              },
              expression: "pageSelected",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }