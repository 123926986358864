var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { ref: "PreviewList" },
    _vm._l(_vm.pageList, function (page, index) {
      return _c(
        "li",
        { key: index + 1, attrs: { "data-id": index + 1 } },
        [
          _c("PreviewPage", {
            attrs: {
              idx: index + 1,
              selectedPage: _vm.selectedPage,
              page: page,
              pagesMap: _vm.pagesMap,
            },
            on: { pageClicked: _vm.pageClicked, pageDeleted: _vm.pageDeleted },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }