<template>
    <div>
        <v-dialog persistent v-model="displayDialog" max-width="550">
            <v-card class="pa-3 relative">
                <div class="close-icon pr-3">
                    <v-icon v-if="!isConnecting" class="pointer" @click="closeDialog()">close</v-icon>
                </div>
                <v-card-title class="headline pt-5 pb-0 flex-column">
                    <div v-if="step == 1" class="d-flex flex-column full-width">
                        <div class="d-flex justify-center full-width">
                            <v-icon color="primary" class="header-icon">upload_file</v-icon>
                        </div>
                        <div v-if="!displayResults" class="d-flex justify-center full-width my-3">
                            <span class="header-title">Upload a new PDF File</span>
                        </div>
                        <div v-if="!displayResults" class="d-flex justify-center full-width my-3 dropzone" @dragover="filedragover($event)" @dragleave="filedragleave($event)" @drop="filedrop($event)">
                            <div class="dropzone-info-area">
                                <div class="selected-files-to-upload">
                                    <div v-for="(file, index) of filesAdded" :key="'file'+index" class="dropzone-content">
                                        <v-icon color="primary" class="material-icons-outlined font-size-34">description</v-icon>
                                        <span>{{file.name}}</span>
                                        <v-icon color="error" class="material-icons-outlined delete-file pointer" @click="removeFile(index)">delete</v-icon>
                                    </div>
                                </div>
                                <div  class="dropzone-content">
                                    <v-btn color="primary" fab small @click="browseFile()">
                                        <v-icon>backup</v-icon>
                                    </v-btn>
                                    <span>Drag a file here or </span>
                                    <a href="@" class="ml-1" @click.stop.prevent="browseFile()"> browse</a>
                                </div>
                            </div>
                            <input type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle"
                                   class="d-none" ref="uplfile" accept=".pdf" :disabled="isLoading" @change="fileSelected"/>
                        </div>
                        <div class="d-flex justify-center full-width my-3 relative">
                            <v-icon v-if="displayResults" @click="cleanSearch()" class="float-left-abs">undo</v-icon>
                            <span class="header-title">Or import from existing property</span>
                        </div>
                        <div class="d-flex justify-center full-width my-3">
                            <v-text-field
                                v-model="propertyId"
                                label="Search by Property ID"
                                outline
                                append-icon="search"
                                @keyup.enter="searchProperties()"
                                @click:append="searchProperties()"
                            ></v-text-field>
                        </div>
                        <div v-if="displayResults" class="d-flex justify-center full-width mb-3">
                            <DataTable :columns="headers"
                                       :elements="searchResults.properties"
                                       :state="tableState"
                                       @changePage="changePage"
                                       @itemSelected="propertyChosen">
                            </DataTable>
                        </div>
                    </div>
                    <div v-if="step == 2" class="d-flex flex-column full-width">
                        <div class="d-flex justify-center full-width">
                            <v-icon color="primary" class="header-icon">upload_file</v-icon>
                        </div>
                        <div class="d-flex justify-center full-width my-3 relative">
                            <v-icon @click="goBack()" class="float-left-abs">undo</v-icon>
                            <span class="header-title">Import from existing property</span>
                        </div>
                        <div class="d-flex justify-center full-width my-3">
                            <span class="header-title font-weight-medium">{{propertySelected.name}}</span>
                        </div>
                        <div class="d-flex justify-center full-width">
                            <span class="import-instructions-text">Now choose from the following list one of the available documents to be imported.</span>
                        </div>
                        <div class="d-flex justify-start full-width my-3">
                            <v-radio-group v-if="types.length" v-model="docType">
                                <v-radio
                                    v-for="(typ, index) in types"
                                    :key="index"
                                    :label="typ"
                                    :value="index"
                                ></v-radio>
                            </v-radio-group>
                            <span v-if="types.length == 0" class="import-instructions-text red--text">The selected property has no documents to import.</span>
                        </div>
                    </div>
                </v-card-title>
                <v-card-actions class="pb-3 pr-3 pt-0 justify-center">
                    <v-btn v-if="step == 1" depressed class="full-width" color="primary darken-1" dark :disabled="isDisabled()" @click="uploadFile()">
                        <span v-if="!isConnecting">Continue</span>
                        <v-progress-circular v-if="isConnecting" :size="25" :width="3" color="white accent-4" indeterminate></v-progress-circular>
                    </v-btn>
                    <v-btn v-if="step == 2" depressed class="full-width" color="primary darken-1" dark :disabled="isDisabled()" @click="importDoc()">
                        <span v-if="!isConnecting">Import</span>
                        <v-progress-circular v-if="isConnecting" :size="25" :width="3" color="white accent-4" indeterminate></v-progress-circular>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

    import DataTable from "./DataTable";
    import axios from "axios";

    export default {
        name: 'StartModule',
        components: {
            DataTable
        },
        data() {
            return {
                step: 1,
                propertyId: '',
                propertySelected: null,
                isLoading: false,
                isConnecting: false,
                filesAdded: [],
                docType: null,
                types: [],
                headers: [
                    {
                        text: "Property Id",
                        align: 'center',
                        sortable: false,
                        value: 'id'
                    },
                    {
                        text: "Property Name",
                        align: 'center',
                        sortable: false,
                        value: 'name'
                    },
                    {
                        text: "",
                        align: 'center',
                        sortable: false,
                        value: 'actions'
                    },
                ],
                searchResults: {},
                displayResults: false,
                displayDialog: false,
                tableState: {},
            }
        },
        props: {
            displayModule: {
                type: Boolean,
                default: false
            },
            stopLoading: {
                required: false
            },
            apiBaseUrl: {
                required: true
            }
        },
        watch: {
            displayModule: function(){
                if(this.displayModule){
                    this.displayDialog = true;
                } else {
                    this.clearData();
                }
            },
            stopLoading: function (){
                this.isConnecting = false;
            }
        },
        methods: {
            closeDialog() {
                this.$emit('closeDialog');
            },
            clearData() {
                this.propertyId = '';
                this.filesAdded = [];
                this.isLoading = false;
                this.propertySelected = null;
                this.searchResults = {};
                this.displayResults = false;
                this.displayDialog = false;
            },
            filedragover(event) {
                event.preventDefault();
                if(!event.currentTarget.classList.contains('dragactive')){
                    event.currentTarget.classList.add('dragactive');
                }
            },
            filedragleave(event) {
                if(!this.isLoading && event.currentTarget.classList.contains('dragactive')){
                    event.currentTarget.classList.remove('dragactive');
                }
            },
            filedrop(event) {
                event.preventDefault();
                if(!this.isLoading){
                    this.addFile(event.dataTransfer.files);
                    event.currentTarget.classList.remove('dragactive');
                }
            },
            browseFile() {
                this.$refs.uplfile.click();
            },
            fileSelected(e){
                if (e && e.target.files.length > 0) {
                    this.addFile(e.target.files);
                }
            },
            addFile(files) {
                for(let i = 0; i < files.length; i++) {
                    let file = files[i];
                    if (file.type.indexOf('pdf') > -1) {
                        this.filesAdded.push(file);
                    }
                }
            },
            removeFile(index) {
                this.filesAdded.splice(index, 1);
            },
            isDisabled(){
                let disabled;
                if(this.step == 1){
                    disabled = this.isConnecting || this.filesAdded.length == 0;
                } else {
                    disabled = this.isConnecting || this.docType == null;
                }
                return disabled;
            },
            isAnyOptionSelected(){
                return this.docType != null;
            },
            changePage(page){
                this.searchProperties(page);
            },
            searchProperties(page = 1, size = 5){
                let self = this;
                self.displayResults = false;
                const headers = {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.token
                    }
                };
                let params = '?page=' + (page-1) + '&size=' + size;
                if(this.propertyId) {
                    if (this.propertyId == parseInt(this.propertyId, 10)) {
                        params += '&id=' + this.propertyId;
                    } else {
                        params += '&name=' + this.propertyId;
                    }
                }
                axios.get(self.apiBaseUrl + 'codingtool/properties' + params, headers).then((response) => {
                    if(response.data){
                        self.searchResults = response.data;
                        self.tableState = {
                            totalItems: self.searchResults.totalItems,
                            currentPage: self.searchResults.currentPage+1,
                            pageSize: self.searchResults.totalPage,
                            totalPages: self.searchResults.totalPage
                        }
                        self.displayResults = true;
                    }
                }).catch(e => {

                });
            },
            propertyChosen(item){
                this.propertySelected = item;
                for (let key in item) {
                    if(key.indexOf('allow') > -1 && item[key] === true) {
                        let parts = key.match(/[A-Z][a-z]+/g);
                        if(parts && parts.length > 0) {
                            this.types.push(parts[0]);
                        }
                    }
                }
                this.step = 2;
            },
            cleanSearch(){
                this.propertyId = '';
                this.searchResults = {};
                this.propertySelected = null;
                this.displayResults = false;
            },
            goBack(){
                this.cleanSearch();
                this.step = 1;
            },
            uploadFile(){
                this.isConnecting = true;
                this.$emit('uploadFiles', this.filesAdded);
            },
            importDoc(){
                const params = {
                    nameProperty: this.propertySelected.name,
                    idProperty: this.propertySelected.id,
                    type: this.types[this.docType].toLowerCase(),
                }
                this.isConnecting = true;
                this.$emit('importFile', params);
            }
        }
    }

</script>

<style>
    .full-width {
        width: 100%;
    }
    .header-icon {
        font-size: 50px;
    }

    .header-title {
        font-size: 16px;
        text-align: center;
    }

    .relative {
        position: relative;
    }

    .close-icon {
        position: absolute;
        right: 0;
    }

    .pointer {
        cursor: pointer;
    }

    .font-size-34 {
        font-size: 34px;
    }

    .theme--dark.v-btn.v-btn--disabled:not(.v-btn--icon):not(.v-btn--flat):not(.v-btn--outline) {
        background-color: #2196f3 !important;
        border-color: #2196f3;
        color:white !important;
        opacity: 0.35;
    }

    .dropzone-info-area {
        display: flex;
        min-height: 90px;
        justify-content: center;
        align-items: center;
        border: 2px dashed #2196f3;
        position: relative;
        flex-direction: column;
        padding: 0.5em 0;
    }

    .selected-files-to-upload {
        align-self: flex-start;
        display: flex;
        flex-direction: column;
        padding-left: 27%;
    }

    .selected-files-to-upload .dropzone-content {
        align-self: flex-start
    }

    .dropzone-content {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        align-self: center;
    }

    .dropzone-content > span {
        max-width: 270px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .dropzone.dragactive{
        background-color: lightgoldenrodyellow;
    }

    .delete-file {
        position: absolute;
        right: 1em;
    }

    .theme--light.v-input--is-disabled.selected-property-search input {
        color: black;
    }

    .import-instructions-text {
        line-height: 1.3;
        font-size: 16px;
        text-align: center;
    }

    .float-left-abs {
        position: absolute;
        left: 0;
    }

</style>
