var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: "450" },
      model: {
        value: _vm.displayDialog,
        callback: function ($$v) {
          _vm.displayDialog = $$v
        },
        expression: "displayDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-text", { staticClass: "pa-5" }, [
            _c("h2", { staticClass: "text-xs-center" }, [
              _vm._v("Are you sure?"),
            ]),
            _c("h4", { staticClass: "text-xs-center" }, [
              _vm._v("Do you want to reset the document?"),
            ]),
          ]),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "error", flat: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [_vm._v("No")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "success", flat: "" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmAction()
                    },
                  },
                },
                [_vm._v("Yes")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }