var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "right-side-scroll" },
    [
      _c(
        "v-card",
        { staticClass: "my-2" },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "type-container", attrs: { xs4: "" } },
                    _vm._l(_vm.selectedLabelColor, function (color, index) {
                      return _c("div", {
                        key: index + 1,
                        staticClass: "property-color",
                        style: { backgroundColor: color },
                      })
                    }),
                    0
                  ),
                  _c("v-flex", { attrs: { xs8: "" } }, [
                    _c("div", [
                      _c("span", [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.selectedElementsArray.length) +
                            " selected elements\n                        "
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { row: "", "pt-1": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-checkbox", {
                        attrs: { color: "primary", label: "Required" },
                        model: {
                          value: _vm.defaultAttributes.required,
                          callback: function ($$v) {
                            _vm.$set(_vm.defaultAttributes, "required", $$v)
                          },
                          expression: "defaultAttributes.required",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-checkbox", {
                        attrs: { color: "primary", label: "Read-only" },
                        model: {
                          value: _vm.defaultAttributes.readOnly,
                          callback: function ($$v) {
                            _vm.$set(_vm.defaultAttributes, "readOnly", $$v)
                          },
                          expression: "defaultAttributes.readOnly",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.allSelectedAreStatic
                ? _c(
                    "v-layout",
                    { attrs: { row: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.inputTypes,
                              label: "Input type",
                            },
                            model: {
                              value: _vm.defaultAttributes.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.defaultAttributes, "type", $$v)
                              },
                              expression: "defaultAttributes.type",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.fontSizeValues,
                          label: "Font size",
                        },
                        model: {
                          value: _vm.defaultAttributes.fontSize,
                          callback: function ($$v) {
                            _vm.$set(_vm.defaultAttributes, "fontSize", $$v)
                          },
                          expression: "defaultAttributes.fontSize",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.allowExtraFeatures
                ? _c(
                    "v-layout",
                    { attrs: { row: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.textFieldTypes,
                              label: "Text Type",
                            },
                            model: {
                              value: _vm.defaultAttributes.textType,
                              callback: function ($$v) {
                                _vm.$set(_vm.defaultAttributes, "textType", $$v)
                              },
                              expression: "defaultAttributes.textType",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-layout",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.allowExtraFeatures,
                      expression: "allowExtraFeatures",
                    },
                  ],
                  attrs: { row: "" },
                },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          id: "field-max-lenght",
                          label: "Max Length",
                          mask: "###",
                        },
                        model: {
                          value: _vm.defaultAttributes.maxLength,
                          callback: function ($$v) {
                            _vm.$set(_vm.defaultAttributes, "maxLength", $$v)
                          },
                          expression: "defaultAttributes.maxLength",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.allowExtraFeatures || _vm.allSelectedAreStatic
                ? _c(
                    "v-layout",
                    { attrs: { "mb-2": "" } },
                    [
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("div", [
                          _c("span", [
                            _vm._v(
                              "Some features are not available for your selected elements"
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "v-layout",
                {
                  staticClass: "mb-2",
                  attrs: { row: "", "justify-space-around": "" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "fab-action-button",
                      attrs: { dark: "", color: "success", fab: "" },
                      on: { click: _vm.applyChanges },
                    },
                    [
                      _c("v-icon", { staticClass: "font-icon" }, [
                        _vm._v("done"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "orange lighten-1 fab-action-button",
                      attrs: { dark: "", fab: "" },
                      on: { click: _vm.deleteInput },
                    },
                    [
                      _c("v-icon", { staticClass: "font-icon" }, [
                        _vm._v("delete_outline"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "teal lighten-1 fab-action-button",
                      attrs: { dark: "", fab: "" },
                      on: { click: _vm.displayTransferDialog },
                    },
                    [
                      _c("v-icon", { staticClass: "font-icon" }, [
                        _vm._v("low_priority"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text fab-action-button",
                      attrs: {
                        color: "primary",
                        outline: "",
                        fab: "",
                        depressed: "",
                      },
                      on: { click: _vm.duplicateInput },
                    },
                    [
                      _c("v-icon", { staticClass: "font-icon" }, [
                        _vm._v("content_copy"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-tabs",
            {
              attrs: {
                centered: "",
                color: "indigo",
                dark: "",
                "icons-and-text": "",
              },
            },
            [
              _c("v-tabs-slider", { attrs: { color: "yellow" } }),
              _c(
                "v-tab",
                { attrs: { href: "#tab-1" } },
                [
                  _vm._v("\n                Align\n                "),
                  _c("v-icon", [_vm._v("align_vertical_top")]),
                ],
                1
              ),
              _c(
                "v-tab",
                { attrs: { href: "#tab-2" } },
                [
                  _vm._v("\n                Match\n                "),
                  _c("v-icon", [_vm._v("format_line_spacing")]),
                ],
                1
              ),
              _vm._l(_vm.positionAlternatives, function (actionGroup, i) {
                return _c(
                  "v-tab-item",
                  { key: i, attrs: { value: "tab-" + (i + 1) } },
                  _vm._l(actionGroup, function (action, idx) {
                    return _c(
                      "v-list-tile",
                      {
                        key: idx,
                        attrs: { ripple: "" },
                        on: {
                          click: function ($event) {
                            return _vm.positionAction(action.name)
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-tile-action",
                          [
                            _c("v-icon", { attrs: { color: "teal" } }, [
                              _vm._v(_vm._s(action.icon)),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-list-tile-content",
                          [
                            _c("v-list-tile-title", [
                              _vm._v(_vm._s(action.name)),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }