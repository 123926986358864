<template>
    <div>
        <v-dialog persistent v-model="displayModule" max-width="450">
            <v-card class="pa-3 relative">
                <div class="close-icon pr-3">
                    <v-icon v-if="!isConnecting" class="pointer" @click="closeDialog()">close</v-icon>
                </div>
                <v-card-title class="headline pt-5 pb-0 flex-column">
                    <div class="d-flex justify-center full-width">
                        <img src="../assets/building.svg" class="property-icon" />
                    </div>
                    <div class="d-flex justify-center full-width px-5 my-3">
                        <span class="import-instructions-text">Please name your document before download it</span>
                    </div>
                    <div class="d-flex justify-center full-width my-1">
                        <v-text-field
                            v-model="filename"
                            label="Filename"
                            outline
                        ></v-text-field>
                    </div>
                </v-card-title>
                <v-card-actions class="mt-3 pb-3 pr-3 pt-0 justify-center">
                    <v-btn depressed class="full-width" color="primary darken-1" dark :disabled="isDisabled()" @click="confirmDownload()">
                        <span v-if="!isConnecting">Download Document</span>
                        <v-progress-circular v-if="isConnecting" :size="25" :width="3" color="white accent-4" indeterminate></v-progress-circular>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: 'DownloadFile',
        data() {
            return {
                filename: '',
                isConnecting: false,
            }
        },
        props: {
            displayModule: {
                type: Boolean,
                default: false
            },
        },
        watch: {
            stopLoading: function (){
                this.isConnecting = false;
            },
            displayModule: function(){
                if(!this.displayModule){
                    this.clearData();
                }
            },
        },
        methods: {
            closeDialog() {
                this.$emit('closeDialog');
                this.clearData();
            },
            isDisabled(){
                return this.filename == '' || this.isConnecting;
            },
            confirmDownload() {
                this.isConnecting = true;
                this.$emit('confirmDownload', this.filename);
            },
            clearData() {
                this.filename = '';
                this.isConnecting = false;
            }
        }
    }
</script>

<style>
    .property-icon {
        height: 50px;
    }
</style>