<template>
    <div>
        <v-dialog persistent v-model="displayModule" max-width="750">
            <v-card class="pa-3 relative search-document-dialog">
                <div class="close-icon pr-3">
                    <v-icon class="pointer" @click="closeDialog()">close</v-icon>
                </div>
                <v-card-title class="headline pt-5 pb-0 flex-column">
                    <div class="d-flex flex-column full-width">
                        <div class="d-flex justify-center full-width">
                            <v-icon color="primary" class="header-icon">search</v-icon>
                        </div>
                        <div v-if="!displayResults" class="d-flex justify-center full-width mb-3 mt-5">
                            <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
                        </div>
                        <div v-if="displayResults" class="d-flex justify-center full-width mb-3">
                            <DataTable :columns="headers"
                                       :elements="searchResults.pendingDocuments"
                                       :state="tableState"
                                       @itemSelected="propertyChosen"
                                       @changePage="changePage">

                            </DataTable>
                        </div>
                    </div>
                </v-card-title>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import DataTable from "./DataTable";
import axios from "axios";

export default {
    name: 'SearchDocument',
    data() {
        return {
            headers: [
                {
                    text: "Id",
                    align: 'center',
                    sortable: false,
                    type: 'integer',
                    value: 'id'
                },
                {
                    text: "Document Name",
                    align: 'center',
                    sortable: false,
                    type: 'string',
                    value: 'documentName'
                },
                {
                    text: "Modified",
                    align: 'center',
                    sortable: false,
                    type: 'date',
                    value: 'lastUsed'
                },
                {
                    text: "",
                    align: 'center',
                    sortable: false,
                    value: 'actions'
                },
            ],
            displayResults: false,
            searchResults: {},
            tableState: {}
        }
    },
    components: {
        DataTable
    },
    props: {
        displayModule: {
            type: Boolean,
            default: false
        },
        apiBaseUrl: {
            required: true
        }
    },
    watch: {
        displayModule: function(){
            const self = this;
            if(self.displayModule){
                self.searchProperties();
            }
        }
    },
    created() {

    },
    methods: {
        propertyChosen(item){
            this.displayResults = false;
            this.$emit('itemSelected', item);
        },
        closeDialog() {
            this.$emit('closeDialog');
        },
        changePage(page){
            this.searchProperties(page);
        },
        searchProperties(page = 1, size = 5){
            let self = this;
            self.displayResults = false;
            const headers = {
                headers: {
                    Authorization: 'Bearer ' + localStorage.token
                }
            };
            let params = '?page=' + (page-1) + '&size=' + size;
            axios.get(self.apiBaseUrl + 'codingtool/getListPendingDocuments' + params, headers).then((response) => {

                if(response.data){
                    self.searchResults = response.data;
                    self.tableState = {
                        totalItems: self.searchResults.totalItems,
                        currentPage: self.searchResults.currentPage+1,
                        pageSize: self.searchResults.totalPage,
                        totalPages: self.searchResults.totalPage
                    }
                    self.displayResults = true;
                }
            }).catch(e => {

            });
        }
    }
}
</script>

<style>
    .search-document-dialog {
        min-height: 550px;
    }
</style>