<template>
    <v-menu offset-y offset-x full-width>
        <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
                <v-icon>more_vert</v-icon>
            </v-btn>
        </template>
        <v-list class="pa-0">
            <v-list-tile avatar to="/account">
                <v-list-tile-avatar>
                    <Jazzicon :address="currentUser.email" :size="40" />
                </v-list-tile-avatar>
                <v-list-tile-content>
                    <v-list-tile-title>{{currentUser.fullname}}</v-list-tile-title>
                    <v-list-tile-sub-title>{{currentUser.email}}</v-list-tile-sub-title>
                </v-list-tile-content>
            </v-list-tile>
            <v-divider></v-divider>
            <v-list-tile to="/account">
                <v-list-tile-title>Your Account</v-list-tile-title>
            </v-list-tile>
            <v-list-tile to="/logout">
                <v-list-tile-title>Logout</v-list-tile-title>
            </v-list-tile>
        </v-list>
    </v-menu>
</template>

<script>

    import Jazzicon from "./Jazzicon";

    export default {
        name: 'LogoutMenu',
        props: {
            currentUser: {
                required: true
            }
        },
        components: {
            Jazzicon
        }
    }

</script>